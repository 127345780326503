import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import moment from 'moment';
import { DatePicker } from 'antd';
import Tabs from 'components/TabsV2';
import { withRouter } from 'react-router';

const limitTimeStr = "01:00:00";

let tabs = [
    {
        name: '日',
        cn_text: '天',
        time_type: 'DAY',
        picker: 'date',
        range: 'day',//startOf
        calc_unit: 'days',
        period: [6, 'days'],//subtract
        disabledDate: (current) => {
            if (!!current) {
                if (current > moment().endOf('day')) {
                    return true;
                } else {
                    const currMoment = moment();
                    if (moment(current).isSame(moment(currMoment), 'day')) {
                        if (moment(currMoment).isBefore(moment(moment(currMoment).startOf('date').format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                            return true;
                        };
                    };
                };
            };
            return false;
        },
        formatter: (time) => {
            return moment(time).format("YYYY年MM月DD日");
        },
    },
    {
        name: '周',
        cn_text: '周',
        time_type: 'WEEK',
        picker: 'week',
        range: 'week',//startOf
        calc_unit: 'weeks',
        period: [6, 'weeks'],//subtract
        disabledDate: (current) => {
            if (!!current) {
                if (current > moment().endOf('week')) {
                    return true;
                } else {
                    const currMoment = moment();
                    if (moment(current).isSame(moment(currMoment), 'week')) {
                        if (moment(currMoment).isBefore(moment(moment(currMoment).startOf('week').format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                            return true;
                        };
                    };
                };
            };
            return false;
        },
        formatter: (time) => {
            return moment(time).format("YYYY年MM月DD日");
        },
    },
    {
        name: '月',
        cn_text: '月',
        time_type: 'MONTH',
        picker: 'month',
        range: 'month',//startOf
        calc_unit: 'months',// add, subtract
        period: [6, 'months'],//subtract
        disabledDate: (current) => {
            if (!!current) {
                if (current > moment().endOf('month')) {
                    return true;
                } else {
                    const currMoment = moment();
                    if (moment(current).isSame(moment(currMoment), 'month')) {
                        if (moment(currMoment).isBefore(moment(moment(currMoment).startOf('month').format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                            return true;
                        };
                    };
                };
            };
            return false;
        },
        formatter: (time) => {
            return moment(time).format("YYYY年MM月");
        },
    },
]
    .map((item, index) => {
        return Object.assign({}, item, { key: index + "" });
    });
// 一定要写在最后
tabs = tabs
    .map((item) => Object.assign({}, item, { all_infos: tabs }));

const getLatestTime = (timeInfo) => {
    const { range, calc_unit } = timeInfo;
    let currMoment = moment();
    if (moment(currMoment).isBefore(moment(moment(currMoment).startOf(range).format(`YYYY-MM-DD ${limitTimeStr}`)))) {
        currMoment = moment(currMoment).subtract(1, calc_unit).endOf(range);
    };
    return moment(currMoment).format("YYYY-MM-DD 00:00:00");
};

const Comp = (props) => {
    const { timeInfo } = props;
    const [default_time_obj] = useState(() => {
        const time = new URLSearchParams(props.location.search).get('time');
        const timeKey = new URLSearchParams(props.location.search).get('timeKey');
        const default_time = (typeof time === 'string' && time !== "") ? time : (props?.defaultTime ?? moment().format("YYYY-MM-DD 00:00:00"));
        let default_time_info = (tabs ?? [])[0] ?? null;
        if (typeof timeKey === 'string' && timeKey !== "") {
            const cus_time_info = (tabs ?? []).filter(({ key }) => key === timeKey)[0] ?? null;
            if (!!cus_time_info) {
                default_time_info = cus_time_info;
            };
        };
        if (!!default_time_info) {
            default_time_info = Object.assign({}, default_time_info, {
                time: default_time,
            });
        };
        return default_time_info;
    });
    const default_time_info = useRef(default_time_obj);

    const setTimeInfo = useRef(null);
    useEffect(() => {
        if (typeof props.setTimeInfo === 'function') {
            setTimeInfo.current = props.setTimeInfo;
            return;
        };
        setTimeInfo.current = () => { };
    }, [props]);
    useEffect(() => {
        if (!!default_time_info.current) {
            const latestTime = getLatestTime(default_time_info.current);
            const defaultTime = default_time_info.current.time;
            const nextTime = moment(latestTime).isBefore(moment(defaultTime)) ? latestTime : defaultTime;
            const init_time_info = Object.assign({}, default_time_info.current, {
                time: nextTime,
            });
            setTimeInfo.current(init_time_info);
        };
    }, []);

    const [visible, setVisible] = useState(false);
    useEffect(() => {
        if (visible) {
            const cb = () => {
                setVisible(false);
            };
            window.addEventListener('click', cb);
            return () => {
                window.removeEventListener('click', cb);
            };
        };
    }, [visible]);
    const datePickerOnChange = (v) => {
        const nextTimeInfo = Object.assign({}, timeInfo ?? {}, { time: moment(v).format("YYYY-MM-DD HH:mm:ss") });
        setTimeInfo.current(nextTimeInfo);
        setVisible(false);
    };

    const setNewPicker = (item) => {
        // tab切换也会影响到time，在一个周期内可以，在另一个周期内就不一定了
        const prevTime = timeInfo?.time ?? moment().format("YYYY-MM-DD 00:00:00");
        const currLatestTime = getLatestTime(item);
        const nextTime = moment(currLatestTime).isBefore(moment(prevTime)) ? currLatestTime : prevTime;
        const nextTimeInfo = Object.assign({}, item, {
            time: nextTime,
        });
        setTimeInfo.current(nextTimeInfo);
    };

    return (
        <div className={style['wrapper']}>
            {
                visible && !!timeInfo && (
                    <div className="picker_wrapper">
                        <div
                            onClick={(e) => {
                                // 当用range选择器时，需要在此处阻止冒泡避免弹窗消失
                                // 后续的挂载在内部的意义似乎只剩下隐藏ant-picker-range-arrow
                                e.stopPropagation();//此处不明白，日历组件默认挂载body下，但是这里的stopPropagation缺起效了，放在外部反而不起效
                            }}
                        >
                            <DatePicker
                                style={{ width: "100%" }}
                                value={moment(timeInfo.time)}
                                open={true}
                                picker={timeInfo.picker}
                                onChange={datePickerOnChange}
                                disabledDate={timeInfo.disabledDate}
                            />
                        </div>
                        {/* 遮挡内部点击事件 */}
                        {/* 因为高度可能还是会超出的 */}
                        <div className="hover_block"></div>
                    </div>
                )
            }
            <div className="content">
                {
                    !!timeInfo && (
                        <div className="date" onClick={(e) => { setVisible(!visible); e.stopPropagation(); }} >【 {timeInfo.formatter(timeInfo.time)}】</div>
                    )
                }
                {
                    !!tabs && tabs.length > 0 && (
                        <Tabs tabs={tabs} selectedTab={timeInfo} onChange={setNewPicker} />
                    )
                }
            </div>
        </div>
    );
};

export default withRouter(Comp);
