import React, { useEffect, useMemo, useRef, useState } from 'react';
import style from './index.module.scss';
import moment from 'moment';
import { DatePicker } from 'antd';
import ICON from './res/icon.svg';
import ICON_ARROW_LEFT from './res/arrow_left.svg';

const limitTimeStr = "01:00:00";

let tabs = [
    {
        name: '日',
        cn_text: '天',
        time_type: 'DAY',
        picker: 'date',
        range: 'day',//startOf
        calc_unit: 'days',// add subtract
        disabledDate: (current) => {
            if (!!current) {
                if (current > moment().endOf('day')) {
                    return true;
                } else {
                    const currMoment = moment();
                    if (moment(current).isSame(moment(currMoment), 'day')) {
                        if (moment(currMoment).isBefore(moment(moment(currMoment).startOf('date').format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                            return true;
                        };
                    };
                };
            };
            return false;
        },
    },
]
    .map((item, index) => {
        return Object.assign({}, item, { key: index + "" });
    });
// 一定要写在最后
tabs = tabs
    .map((item) => Object.assign({}, item, { all_infos: tabs }));

const getLatestTime = (timeInfo) => {
    const { range, calc_unit } = timeInfo;
    let currMoment = moment();
    if (moment(currMoment).isBefore(moment(moment(currMoment).startOf(range).format(`YYYY-MM-DD ${limitTimeStr}`)))) {
        currMoment = moment(currMoment).subtract(1, calc_unit).endOf(range);
    };
    return moment(currMoment).format("YYYY-MM-DD 00:00:00");
};

const blankFunc = () => { };

const Comp = (props) => {
    const { timeInfo, setTimeInfo } = props;
    const [default_time] = useState(() => {
        return props?.defaultTime ?? moment().format("YYYY-MM-DD 00:00:00");
    });

    const setInfoFunc = useRef(blankFunc);
    useEffect(() => {
        setInfoFunc.current = typeof setTimeInfo === 'function' ? setTimeInfo : blankFunc;
    }, [setTimeInfo]);

    useEffect(() => {
        if (!!tabs && tabs.length > 0) {
            const latestTime = getLatestTime(tabs[0]);
            const defaultTime = default_time;
            const nextTime = moment(latestTime).isBefore(moment(defaultTime)) ? latestTime : defaultTime;
            const init_time_info = Object.assign({}, tabs[0], {
                time: nextTime,
            });
            setInfoFunc.current(init_time_info);
        };
    }, []);

    const [visible, setVisible] = useState(false);
    useEffect(() => {
        if (visible) {
            const cb = () => {
                setVisible(false);
            };
            window.addEventListener('click', cb);
            return () => {
                window.removeEventListener('click', cb);
            };
        };
    }, [visible]);
    const datePickerOnChange = (v) => {
        const nextTimeInfo = Object.assign({}, timeInfo ?? {}, { time: moment(v).format("YYYY-MM-DD HH:mm:ss") });
        setInfoFunc.current(nextTimeInfo);
        setVisible(false);
    };

    const pickerBtnOnClick = (e) => {
        setVisible((visible) => !visible);
        e.stopPropagation();
    };

    const gotoTime = (delta) => {
        if (!!timeInfo) {
            const { time, calc_unit } = timeInfo;
            const latestTime = getLatestTime(timeInfo);
            const tmpTime = moment(time).add(delta, calc_unit).format("YYYY-MM-DD HH:mm:ss");
            const nextTime = moment(latestTime).isBefore(moment(tmpTime)) ? latestTime : tmpTime;

            const nextTimeInfo = Object.assign({}, timeInfo ?? {}, { time: nextTime });
            setInfoFunc.current(nextTimeInfo);
        };
    };

    const time_range_text = useMemo(() => {
        if (!!timeInfo) {
            const { time, range } = timeInfo;
            const text = moment(time).startOf(range).format("YYYY/MM/DD 00:00") + " - " + moment(time).endOf(range).format("YYYY/MM/DD 23:59");
            return text;
        };
        return "-- - --";
    }, [timeInfo]);

    return (
        <div className={style['wrapper']}>
            {
                visible && !!timeInfo && (
                    <div className="picker_wrapper">
                        <div
                            onClick={(e) => {
                                // 当用range选择器时，需要在此处阻止冒泡避免弹窗消失
                                // 后续的挂载在内部的意义似乎只剩下隐藏ant-picker-range-arrow
                                e.stopPropagation();//此处不明白，日历组件默认挂载body下，但是这里的stopPropagation缺起效了，放在外部反而不起效
                            }}
                        >
                            <DatePicker
                                style={{ width: "100%" }}
                                value={moment(timeInfo.time)}
                                open={true}
                                picker={timeInfo.picker}
                                onChange={datePickerOnChange}
                                disabledDate={timeInfo.disabledDate}
                            />
                        </div>
                        {/* 遮挡内部点击事件 */}
                        {/* 因为高度可能还是会超出的 */}
                        <div className="hover_block"></div>
                    </div>
                )
            }
            <div className="content">
                <img alt="" src={ICON} className="icon" onClick={pickerBtnOnClick} />
                <div className="time_wrapper">
                    <div className="left" onClick={() => gotoTime(-1)}>
                        <div className="wrapper">
                            <img alt="" src={ICON_ARROW_LEFT} className="icon" />
                        </div>
                    </div>
                    <div className="mid" onClick={pickerBtnOnClick}>{time_range_text}</div>
                    <div className="right" onClick={() => gotoTime(1)}>
                        <div className="wrapper">
                            <img alt="" src={ICON_ARROW_LEFT} className="icon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comp;
