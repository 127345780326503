import React from 'react';
// import RemoteControl from '../DataItem/RemoteControl.js';
import './DisplayCard.scss';
export default class DisplayCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDataConfig: false,
            remoteControlTagAlias: "",
            remoteControlDevice_id: ""
        };
    }
    remoteControlOnClick = (item, deviceId) => {
        console.log("leiqiao", item, deviceId);
        if (parseInt(item.type) !== 3) return;
        this.setState({
            showDataConfig: true,
            remoteControlTagAlias: item.tagAlias,
            remoteControlDevice_id: deviceId
        })
    }
    render() {
        let self = this,
            propData = self.props.data,
            type = propData.type,
            getWidth = _ => {
                if (propData) {
                    return propData.width + "px";
                }
                return "10px"
            };

        return (
            <div className="DisplayCardWrapper" style={{ width: getWidth() }}>
                {
                    parseInt(type) === 0 ?
                        <div className="type0">
                            <div className="title">{propData.title}</div>
                            {
                                propData.subtitle ?
                                    <div className="subtitle">{propData.subtitle}</div>
                                    :
                                    null
                            }
                            {
                                propData.items.map((item, index) =>
                                    <div className="item" key={index}>
                                        <div className="name">{item.name}</div>
                                        <div className="value" style={{ backgroundColor: item.bckColor ? item.bckColor : "#000000" }} onClick={_ => self.remoteControlOnClick(item, propData.deviceId)}>{item.value ? item.value : "0"}</div>
                                        {
                                            propData.subtitle ?
                                                null
                                                :
                                                <div className="unit">{item.unit}</div>
                                        }
                                    </div>
                                )
                            }
                        </div>
                        : parseInt(type) === 1 ?
                            <div className="type1">
                                <div className="title">{propData.title}</div>
                                {
                                    propData.items.map((item, index) =>
                                        <div className="item" key={index}>
                                            <div className="nameAndBtn">
                                                <div className="name">{item.name}</div>
                                                {/* {
                                                    item.btn ?
                                                        <div className="btn">{item.btn.name}</div>
                                                        : null
                                                } */}
                                            </div>
                                            <div className="itemData">
                                                <div className="value" style={{ backgroundColor: item.bckColor ? item.bckColor : "#000000" }} onClick={_ => self.remoteControlOnClick(item, propData.deviceId)}>{item.value ? item.value : "0"}</div>
                                                <div className="unit">{item.unit}</div>
                                            </div>
                                            <div className="itemData">
                                                <div className="value" style={{ backgroundColor: item.bckColor ? item.bckColor : "#000000" }} onClick={_ => self.remoteControlOnClick(item, propData.deviceId)}>{"0"}</div>
                                                <div className="unit">&nbsp;&nbsp;&nbsp;{"h"}</div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            : null
                }
                {/* <RemoteControl
                    deviceId={self.state.remoteControlDevice_id}
                    visible={self.state.showDataConfig}
                    onChangeVisible={visible => self.setState({ showDataConfig: visible })}
                    // dataMetaId={this.state.dataConfigMetaId}
                    tagAlias={self.state.remoteControlTagAlias}
                    dataMetaName={self.state.remoteControlTagAlias}
                /> */}
            </div>
        );
    }
}
