import React from 'react';
import { useEffect, useRef } from 'react';
import echarts from 'echarts';

const SurvivePieChart = (props) => {
    const {
        gasNumber,
        electricNumber,
        nitrogenNumber
    } = props;
    useEffect(() => {
        renderChart();
    }, [gasNumber, electricNumber, nitrogenNumber]);
    const chartNode = useRef(null);
    const renderChart = () => {
        if(!!chartNode.current) {
            let instance = echarts.getInstanceByDom(chartNode.current);
            if (!instance) {
                instance = echarts.init(chartNode.current);
            };
            instance.setOption({
                grid: {
                    left: 40,
                    top: 100,
                    right: 0,
                    bottom: 0,
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ["60%", "80%"],
                        avoidLabelOverlap: false,
                        hoverAnimation: false,
                        label: {
                            normal: {
                                show: false,
                                position: 'center',
                                formatter: "{d}%", //提示文本内容 d代表百分比，b代表name文本
                            }

                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '14',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            {value: gasNumber, itemStyle: {color: '#5BA3ED'}},
                            {value: electricNumber, itemStyle: {color: '#40B99B'}},
                            {value: nitrogenNumber, itemStyle: {color: '#FE8569'}}
                        ]
                    }
                ]
            })
        }
    };
    return (
        <div style={{marginLeft: 20}}>
            <div ref={chartNode} style={{ width: 200,height: 200 }} />
        </div>
    );
}
export default SurvivePieChart;
