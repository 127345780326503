import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import { Button, Space, Table, Modal, message } from 'antd';
import { get, reqDelete } from '../../common/utils';
import CreateScreenModal from './CreateScreenModal.js';
const Page = (props) => {
    const [screens, setScreens] = useState(null);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        fetchScreens();
    }, []);
    const fetchScreens = () => {
        setLoading(true);
        get(process.env.REACT_APP_SERVER_PATH + "api/grid/screens", {})
            .then((retData) => {
                setLoading(false);
                if (!!retData.data && !!retData.data.results) {
                    setScreens(retData.data.results);
                } else {
                    message.error("获取信息失败,请刷新重试!");
                };
            });
    };
    const createScreenOnClick = () => {
        setVisible(true);
    };
    const editOnClick = (id, mode = 'edit') => {
        props.history.push(`/DnDPage/${id}?mode=${mode}`);
    };
    const deleteItem = (id) => {
        Modal.confirm({
            content: `确认删除该布局吗?`,
            onOk: () => {
                setLoading(true);
                reqDelete(process.env.REACT_APP_SERVER_PATH + `api/grid/screens/${id}`)
                    .then((retData) => {
                        setLoading(false);
                        if (!!retData.data && retData.data.errcode === 0) {
                            message.success("删除成功!");
                            fetchScreens();
                        } else {
                            message.error("删除失败!");
                        };
                    });
            },
        });
    };
    return (
        <div className={style['wrapper']}>
            <div className="header">
                <Button type="primary" onClick={createScreenOnClick} disabled={loading}>创建</Button>
            </div>
            {
                screens !== null && (
                    <Table
                        dataSource={screens}
                        columns={[
                            {
                                title: '名称',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: '创建人',
                                dataIndex: 'user_id',
                                key: 'user_id',
                            },
                            {
                                title: '创建时间',
                                dataIndex: 'create_time',
                                key: 'create_time',
                            },
                            {
                                title: '操作',
                                key: 'action',
                                width: 230,
                                render: (text, record, index) => {
                                    const { id } = record;
                                    return (
                                        <Space>
                                            <Button type="primary" size="small" onClick={() => editOnClick(id)}>编辑</Button>
                                            <Button type="default" size="small" onClick={() => editOnClick(id, 'view')}>预览</Button>
                                            <Button danger size="small" onClick={() => deleteItem(id)}>删除</Button>
                                        </Space>
                                    );
                                },
                            },
                        ]}
                        rowKey="id"
                        loading={loading}
                    />
                )
            }
            {
                visible && (
                    <CreateScreenModal onCancel={() => setVisible(false)} />
                )
            }
        </div>
    );
}

export default Page;
