import React from 'react';
import style from './index.module.scss';

const Comp = (props) => {

    return (
        <div className={style['wrapper']}>
            <div className="scroll_wrapper scroll_bar_restyle">
                <table>
                    <tbody>
                        {
                            new Array(20)
                                .fill(null)
                                .map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>2021/02/23  14:42:23</td>
                                            <td>事件事件事件</td>
                                        </tr>
                                    );
                                })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Comp;
