import React, { useEffect, useMemo, useRef, useContext } from 'react';
import echarts from 'echarts';
import style from './HourlyChart.module.scss';
import HorizLine from './HorizLine.js';
import CommonContext from '../../context';
import moment from 'moment';

const initChart = (refNode, datas, timeStr) => {
    if (!!refNode) {
        const dateStr = moment(timeStr).format("M-D");
        const timeKeys = new Array(24).fill(0).map((v, index) => moment(timeStr).format(`YYYY-MM-DD ${index < 10 ? ('0' + index) : index}:00:00`))
        const [barData, lineData_1, lineData_2] = datas
            .map((data) => {
                if (!data) return [];
                const ret = new Array(24).fill(null);
                return data.reduce((pV, cItem) => {
                    const [key, value] = cItem;
                    const arrIndex = timeKeys.indexOf(key);
                    if (arrIndex !== -1) {
                        let nextPV = [].concat(pV);
                        nextPV[arrIndex] = value;
                        return nextPV;
                    };
                    return pV;
                }, ret);
            });
        let parsedBarData = barData;
        const allVs = barData.filter((v) => typeof v === 'number');
        if (allVs.length > 1) {
            // const minV = Math.min(...allVs);
            // const maxV = Math.max(...allVs);
            // const barChartDelta = maxV - 11 / 10 * (maxV - minV);
            parsedBarData = barData.map((v) => {
                return {
                    value: v,
                    realValue: v,
                };
            });
        };

        // console.log(timeKeys, datas, barData, lineData_1, lineData_2);
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        instance.setOption({
            grid: {
                left: 101,
                right: 75,
                top: 10,
                bottom: 30,
            },
            tooltip: {
                trigger: 'axis',
                formatter: (params) => {
                    const axisValueLabel = params[0].axisValueLabel;
                    return axisValueLabel + params
                        .reduce((pV, cV, index) => {
                            const { marker, data } = cV;
                            const displayData = index === 0 ? data?.realValue : data;
                            return pV + `<br/>${marker}${displayData}`;
                        }, "");
                },
            },
            xAxis: [
                {
                    type: 'category',
                    data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(v => {
                        return dateStr + ' ' + v + ':00~' + (v + 1) + ':00'
                    }),
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: {
                        show: true,
                         textStyle: {
                           color: '#3a3a3a',  //更改坐标轴文字颜色
                           fontSize : 24      //更改坐标轴文字大小
                         }
                     },
                    
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    show: false,
                    min: (value) => {
                        return 0.7 * value.min;
                    },
                    max: (value) => {
                        const { min, max } = value;
                        return max + (max - min) / 10;
                    },
                },
                {
                    type: 'value',
                    show: false,
                    min: (value) => {
                        return 0.7 * value.min;
                    },
                    max: (value) => {
                        const { min, max } = value;
                        return max + (max - min) / 10;
                    },
                },
                {
                    type: 'value',
                    show: false,
                }
            ],
            series: [
                {
                    name: '电量',
                    type: 'bar',
                    itemStyle: {
                        color: '#5BA3ED'
                    },
                    data: parsedBarData,
                },
                {
                    name: '电费',
                    type: 'line',
                    yAxisIndex: 1,
                    itemStyle: {
                        color: '#727272'
                    },
                    data: lineData_1,
                },
                {
                    name: '电价指数',
                    type: 'line',
                    yAxisIndex: 2,
                    itemStyle: {
                        color: 'rgba(254,139,139,1)',
                    },
                    data: lineData_2,
                }
            ]
        });
    }
};

const Comp = (props) => {
    const { hisDatas } = props;
    const chartNode = useRef(null);
    const commonInfo = useContext(CommonContext);
    const timeStr = useMemo(() => {
        if (!!commonInfo) {
            const { state: { time: [, timeStr] } } = commonInfo;
            return timeStr;
        };
        return null;
    }, [commonInfo]);
    useEffect(() => {
        if (!!hisDatas && typeof timeStr === 'string') {
            initChart(chartNode.current, hisDatas, timeStr);
        };
    }, [hisDatas, timeStr]);
    return (
        <div className={style['HourlyChart']}>
            <div className='chartNode' ref={chartNode}></div>
            <HorizLine />
        </div>
    );
};

export default Comp;
