import React, {Component} from 'react'
import {Route, HashRouter, Switch, Redirect} from 'react-router-dom'
import FastClick from 'fastclick'

import System from './containers/System/System.js'
import MainPage from './components/System/MainPage/index.js'
import ProduceTrend from './components/System/ProduceTrend/ProduceTrend.js'
import ImproveChance from './components/System/ImproveChance/ImproveChance.js'
import ProductElectricalAnalysis from './components/System/ProductElectricalAnalysis/ProductElectricalAnalysis.js'
import MainElectricalParams from './components/System/MainElectricalParams/MainElectricalParams.js'
import FactorSummary from './components/System/FactorSummaryV2/index.js'
import ReferenceFactors from './components/System/ReferenceFactors/index.js'
import FactorStatistics from './components/System/FactorStatistics/FactorStatistics.js'
import ProduceAnalysis from './components/System/ProduceAnalysis/ProduceAnalysis.js'
import SystemElectricalAnalysis from './components/System/SystemElectricalAnalysis/SystemElectricalAnalysis.js'
import AlarmSetting from './components/System/AlarmSetting/AlarmSetting.js'
import AlarmList from './components/System/AlarmList'
import HistoryData from './components/System/HistoryData/HistoryData'
import StatisticalReport from './components/System/StatisticalReport/StatisticalReport.js'
import EditReport from './components/System/StatisticalReport/EditReport'
import FactorList from './components/Tools/FactorManager/FactorList.js'
import Login from './containers/Login/Login.js'
import PersonMng from './components/System/PersonMng'
import RoleMngSetting from './components/System/RoleMngSetting'
import PersonalInfo from './components/System/PersonalInfo'
import DnDPage from './containers/DnD'
import ScreenList from './containers/ScreenList'
import BoardEdit from './containers/BoardEdit'
import SchedulingManagement from './containers/SchedulingManagement'

import AuthPage from './containers/Auth/index.js'
import AppIdPage from './containers/Auth/AppId.js'

// diagram related
import DiagramList from './containers/DiagramList/DiagramList.js'
import DiagramDisplay from './containers/DiagramDisplay/index.js'
import DiagramGen from './containers/DiagramGen/DiagramGen.js'
import LnOilfield from './containers/LnOilfield/index'
import NewScreen from './containers/NewScreenWrapper'

// elec using analysis
import ElecAnalysis from './containers/ElecAnalysis'

import Monitor from './containers/Monitor'
import CapsuleBoard from './containers/CapsuleBoard'
import CapsuleSummary from './containers/CapsuleSummary'
import CapsuleTemplate from './components/CapsuleBoard/CapsuleTemplate'
import ManagementSystem from 'containers/ManagementSystem'
import BigManagementSystem from 'containers/BigManagementSystem'
import SystemSetting from 'containers/SystemSetting'

import ExcelDemo from 'containers/ExcelDemo/ExcelDemo.js'
import ExcelWindow from 'containers/OnlineDisk/ExcelWindow.js'
import OnlineDisk from 'containers/OnlineDisk/OnlineDisk.js'

// PEIMS system for yxsn merged in demo system
// demo system
import DemoSystem from 'containers/DemoSystem'
import Cockpit from 'containers/Cockpit'
import LoginV2 from 'containers/LoginV2'
import Exam from 'containers/Exam'
import OnlineDiskWrapper from 'containers/OnlineDiskWrapper'
import ElecManagement from 'containers/DemoSystem/ElecManagement'
import AnalysisBoard from 'containers/DemoSystem/AnalysisBoard'
import Screen from 'containers/DemoSystem/Screen'

// demo system ver.2
import DemoSystemV2 from 'containers/DemoSystemV2'
import Overview from 'containers/FactorRelated/Overview'
import FactorAnalysis from 'containers/DemoSystemV2/Analysis'
import ProductCost from 'containers/DemoSystemV2/ProductCost'
import FactorStatisticsV2 from 'containers/DemoSystemV2/FactorStatistics'
import FactorPredict from 'containers/DemoSystemV2/FactorPredict'
import EventBoard from 'containers/DemoSystemV2/EventCapsules/EventBoard'
import EventSummary from 'containers/DemoSystemV2/EventCapsules/EventSummary'
import AutoReport from 'containers/DemoSystemV2/AutoReport'
import AlarmPage from 'containers/Cockpit/AlarmPage/AlarmPage.js'
import User from 'containers/PersonMng'
import Role from 'containers/RoleMngSetting'
import Department from 'containers/SystemSetting/OrgMng'
import {EliminationPeriod, ExamRules, RaceList} from 'containers/DemoSystemV2/Assess'
import {
    Warning,
    Energy,
    ExamAnalysis,
    SampleMaintenance
} from 'containers/DemoSystemV2/OperateBoards'
import HistoryDataV2 from 'containers/DemoSystemV2/HistoryData/HistoryData'
import ProcessPage from 'containers/DemoSystemV2/Process'
import DeviceStatus from 'containers/DemoSystemV2/DeviceStatus'
import AlarmSettings from 'containers/DemoSystemV2/AlarmSetting/Content'
import PersonalCenter from 'containers/DemoSystemV2/PersonalCenter'
import OperatingBoard from 'containers/FactorRelated/OperatingBoard'
import RuleList from 'containers/ExamV2/ExamRule/RuleList.js'
import ScreenShow from 'containers/DemoSystemV2/ScreenShow';
import ControllerBoard from 'containers/DemoSystemV2/ControllerBoard';

import BlankLayout from 'containers/BlankLayout';

// 辽河大屏
import BigDayPanel from './containers/BigPage/DayPanel/index'
import BigElecAnalysis from 'containers/BigPage/ElecAnalysis'
import 'moment/locale/zh-cn'

import './App.scss'

const needSuposAuth = process.env.REACT_APP_SUPOS_AUTH === 'true'
const isYXSN = ['yxsn'].includes(process.env.REACT_APP_PROJECT_TAG)
const isDemo = ['xinchen', 'demo', 'demo-yxsn'].includes(process.env.REACT_APP_PROJECT_TAG)

class App extends Component {
    constructor(props) {
        super(props)
        let zoom = null
        const screenWidth = window.screen.availWidth
        if (screenWidth < 1920) {
            zoom = Math.floor((screenWidth / 1920) * 1000) / 1000
        }
        this.state = {
            zoom
        }
    }

    componentDidMount() {
        FastClick.attach(document.body, {})
    }

    render() {
        const {zoom} = this.state
        return (
            <div
                style={Object.assign(
                    {height: '100%'},
                    !!zoom
                        ? {
                            // zoom
                        }
                        : {}
                )}
            >
                <HashRouter>
                    <Switch>
                        <Redirect
                            exact
                            from="/"
                            to={
                                needSuposAuth
                                    ? '/AppAuth'
                                    : isYXSN
                                    ? '/PEIMSV2/EventBoard'
                                    : isDemo
                                        ? '/PEIMSV2/Overview'
                                        : '/System/MainPage'
                            }
                        />
                        <Route exact path="/Login" component={isDemo ? LoginV2 : Login}/>
                        <Route exact path="/auth" component={AuthPage}/>
                        <Route exact path="/AppAuth" component={AppIdPage}/>
                        <Route exact path="/FactorList" component={FactorList}/>
                        <Route exact path="/DnDPage/:id" component={DnDPage}/>
                        <Route exact path="/BoardEdit/:id" component={BoardEdit}/>
                        <Route exact path="/ScreenList" component={ScreenList}/>
                        <Route exact path="/DiagramGen/:id" component={DiagramGen}/>
                        <Route exact path="/DiagramDisplay/:id" component={DiagramDisplay}/>
                        <Route exact path="/DiagramList" component={DiagramList}/>
                        <System path="/System" component={System}>
                            <Switch>
                                <Route exact path="/System/MainPage" component={MainPage}/>
                                <Route exact path="/System/ProduceTrend" component={ProduceTrend}/>
                                <Route exact path="/System/ImproveChance" component={ImproveChance}/>
                                <Route
                                    exact
                                    path="/System/ProductElectricalAnalysis"
                                    component={ProductElectricalAnalysis}
                                />
                                <Route exact path="/System/MainElectricalParams" component={MainElectricalParams}/>
                                <Route exact path="/System/FactorSummary" component={FactorSummary}/>
                                <Route exact path="/System/ReferenceFactors/:id" component={ReferenceFactors}/>
                                <Route exact path="/System/FactorStatistics" component={FactorStatistics}/>
                                <Route exact path="/System/ProduceAnalysis" component={ProduceAnalysis}/>
                                <Route
                                    exact
                                    path="/System/SystemElectricalAnalysis"
                                    component={SystemElectricalAnalysis}
                                />
                                <Route exact path="/System/HistoryData" component={HistoryData}/>
                                <Route exact path="/System/AlarmSetting" component={AlarmSetting}/>
                                <Route exact path="/System/AlarmList" component={AlarmList}/>
                                <Route exact path="/System/StatisticalReport" component={StatisticalReport}/>
                                <Route exact path="/System/EditReport/:id" component={EditReport}/>
                                <Route exact path="/System/PersonMng" component={PersonMng}/>
                                <Route exact path="/System/RoleMngSetting" component={RoleMngSetting}/>
                                <Route exact path="/System/SchedulingManagement" component={SchedulingManagement}/>
                                <Route exact path="/System/PersonalInfo" component={PersonalInfo}/>
                            </Switch>
                        </System>
                        <ManagementSystem path="/LH" component={ManagementSystem}>
                            <Switch>
                                <Route exact path="/LH/Monitor" component={Monitor}/>
                                <Route exact path="/LH/ElecAnalysis" component={ElecAnalysis}/>
                                <Route exact path="/LH/LnOilfield" component={LnOilfield}/>
                                <Route exact path="/LH/DayPanel" component={BigDayPanel}/>
                            </Switch>
                        </ManagementSystem>
                        <BigManagementSystem path="/Big" component={BigManagementSystem}>
                            <Switch>
                                <Route exact path="/Big/DayPanel" component={BigDayPanel}/>
                                <Route exact path="/Big/ElecAnalysis" component={BigElecAnalysis} />
                            </Switch>
                        </BigManagementSystem>
                        <DemoSystem path="/PEIMS" component={DemoSystem}>
                            <Switch>
                                <Route exact path="/PEIMS/Cockpit" component={Cockpit}/>
                                <Route exact path="/PEIMS/SystemSetting" component={SystemSetting}/>
                                <Route exact path="/PEIMS/CapsuleBoard" component={CapsuleBoard}/>
                                <Route exact path="/PEIMS/CapsuleSummary" component={CapsuleSummary}/>
                                <Route exact path="/PEIMS/Exam" component={Exam}/>
                                <Route exact path="/PEIMS/OnlineDisk" component={OnlineDiskWrapper}/>
                                <Route exact path="/PEIMS/ElecManagement" component={ElecManagement}/>
                                <Route exact path="/PEIMS/AnalysisBoard" component={AnalysisBoard}/>
                                <Route exact path="/PEIMS/Screen" component={Screen}/>
                            </Switch>
                        </DemoSystem>
                        <DemoSystemV2 path="/PEIMSV2" component={DemoSystemV2}>
                            <Switch>
                                <Route exact path="/PEIMSV2/Overview" component={Overview}/>
                                <Route exact path="/PEIMSV2/OperatingBoard" component={OperatingBoard}/>
                                <Route exact path="/PEIMSV2/Analysis" component={FactorAnalysis}/>
                                <Route exact path="/PEIMSV2/ProductCost" component={ProductCost}/>
                                <Route exact path="/PEIMSV2/FactorStatistics" component={FactorStatisticsV2}/>
                                <Route exact path="/PEIMSV2/FactorPredict" component={FactorPredict}/>
                                <Route exact path="/PEIMSV2/EventBoard" component={EventBoard}/>
                                <Route exact path="/PEIMSV2/EventSummary" component={EventSummary}/>
                                <Route exact path="/PEIMSV2/AutoReport" component={AutoReport}/>
                                <Route exact path="/PEIMSV2/EliminationPeriod" component={EliminationPeriod}/>
                                <Route exact path="/PEIMSV2/ExamRules" component={ExamRules}/>
                                <Route exact path="/PEIMSV2/RaceList" component={RaceList}/>
                                <Route exact path="/PEIMSV2/OnlineDisk" component={OnlineDiskWrapper}/>
                                <Route
                                    exact
                                    path="/PEIMSV2/SchedulingManagement"
                                    component={SchedulingManagement}
                                />
                                <Route exact path="/PEIMSV2/ProcessPage" component={ProcessPage}/>
                                <Route exact path="/PEIMSV2/DeviceStatus" component={DeviceStatus}/>
                                <Route exact path="/PEIMSV2/AlarmPage" component={AlarmPage}/>
                                <Route exact path="/PEIMSV2/ElecManagement" component={ElecManagement}/>
                                <Route exact path="/PEIMSV2/AlarmSettings" component={AlarmSettings}/>
                                <Route exact path="/PEIMSV2/Board/Warning" component={Warning}/>
                                <Route exact path="/PEIMSV2/Board/Energy" component={Energy}/>
                                <Route exact path="/PEIMSV2/Board/ExamAnalysis" component={ExamAnalysis}/>
                                <Route exact path="/PEIMSV2/Exam/RuleList" component={RuleList}/>

                                <Route exact path="/PEIMSV2/Board/HistoryData" component={HistoryDataV2}/>
                                <Route
                                    exact
                                    path="/PEIMSV2/Board/SampleMaintenance"
                                    component={SampleMaintenance}
                                />
                                <Route exact path="/PEIMSV2/user" component={User}/>
                                <Route exact path="/PEIMSV2/role" component={Role}/>
                                <Route exact path="/PEIMSV2/department" component={Department}/>
                                <Route exact path="/PEIMSV2/PersonalCenter" component={PersonalCenter}/>
                                <Route exact path="/PEIMSV2/ScreenShow" component={ScreenShow} />
                                <Route exact path="/PEIMSV2/ControllerBoard" component={ControllerBoard} />
                            </Switch>
                        </DemoSystemV2>
                        <BlankLayout path="/BlankLayout" component={BlankLayout}>
                            <Switch>
                                <Route exact path="/BlankLayout/Overview" component={Overview} />
                                <Route exact path="/BlankLayout/OperatingBoard" component={OperatingBoard} />
                                <Route exact path="/BlankLayout/ScreenShow" component={ScreenShow} />
                                <Route exact path="/BlankLayout/ProcessPage" component={ProcessPage} />
                                <Route exact path="/BlankLayout/DeviceStatus" component={DeviceStatus}/>
                                <Route exact path="/BlankLayout/FactorStatistics" component={FactorStatisticsV2}/>
                                <Route exact path="/BlankLayout/FactorPredict" component={FactorPredict}/>
                                <Route exact path="/BlankLayout/Analysis" component={FactorAnalysis}/>
                                <Route exact path="/BlankLayout/Exam/RuleList" component={RuleList}/>
                                <Route exact path="/BlankLayout/ProductCost" component={ProductCost}/>
                            </Switch>
                        </BlankLayout>
                        <Route exact path="/NewScreen" component={NewScreen}/>
                        {/* // TODO 临时调试 胶囊模版路由 */}
                        <Route exact path="/CapsuleTemplate" component={CapsuleTemplate}/>
                        <Route exact path="/ExcelDemo" component={ExcelDemo}/>
                        <Route exact path="/OnlineDisk" component={OnlineDisk}/>
                        <Route exact path="/ExcelWindow" component={ExcelWindow}/>
                    </Switch>
                </HashRouter>
            </div>
        )
    }
}

export default App
