import React, { useEffect, useRef, useContext, useState, useMemo } from 'react';
import style from './index.module.scss';
import echarts from 'echarts';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { getSuffix } from './utils';
import { Extd_Tag as extdTag } from './constants';
import CommonContext from '../../context';
import { Spin } from 'antd';
import { post } from 'common/utils';
import moment from 'moment';

const renderChart = (refNode, datas, time = moment()) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        const parsedDatas = datas.map(([t, v]) => [moment(t).format("YYYY-MM-DD"), v]);
        const allVals = parsedDatas.map(([, v]) => v);
        let maxVal = 0;
        let minVal = 0;
        if (allVals.length > 0) {
            maxVal = Math.max(...allVals);
            minVal = Math.min(...allVals);
            //认为都是正数
        };
        const maxSize = Math.floor(refNode.offsetWidth / 7 / 2);
        let multiple = 0;
        if (maxVal - minVal > 0) {
            multiple = maxSize * 2 / 3 / (maxVal - minVal);
        };

        const option = {
            tooltip: {
                position: 'top',
                formatter: (params) => {
                    const { data: [time, value] } = params;
                    return `<span>${time}</span><br/><span>${value}</span>`;
                },
            },
            textStyle:{
                fontSize:24
            },
            calendar: [
                {
                    orient: 'vertical',
                    yearLabel: {
                        show: false,
                    },
                    monthLabel: {
                        show: false,
                    },
                    dayLabel: {
                        firstDay: 1,
                        nameMap: 'cn',
                        margin: 8,
                    },
                    top: 34,
                    right: 5,
                    bottom: 5,
                    left: 5,
                    // cellSize: 30,
                    // cellSize: 40,
                    range: moment(time).format("YYYY-MM"),
                },
            ],

            series: [{
                type: 'effectScatter',
                coordinateSystem: 'calendar',
                symbolSize: function (val) {
                    const v = (val[1] - minVal) * multiple + maxSize / 3;
                    return v;
                },
                data: parsedDatas,
            }]
        };


        instance.setOption(option);
    };
};

const Comp = (props) => {
    // const { } = props;
    const chartNode = useRef(null);
    const [topLoading, setTopLoading] = useState(false);
    const [chartLoading, setChartLoading] = useState(false);
    const [value, setValue] = useState(null);
    const [rate, setRate] = useState(null);
    const commonInfo = useContext(CommonContext);
    const [chartInfo, setChartInfo] = useState(null);
    useEffect(() => {
        const { state: { selectedDevice, time } } = commonInfo;
        if (!selectedDevice || !time) {
            setChartInfo(null);
            return;
        };
        const [timeTab, timeStr] = time;
        const his_tag = selectedDevice.tag_name + extdTag + getSuffix('chart', timeTab.tag, timeStr);
        setChartInfo({ his_tag, timeStr });
    }, [commonInfo]);
    useEffect(() => {
        if (!!chartInfo) {
            const { his_tag, timeStr } = chartInfo;
            setChartLoading(true);
            post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data', {
                tag_list: [his_tag]
                    .map((tag_name) => {
                        return {
                            tag_name,
                            time_begin: moment(timeStr).startOf('month').format("YYYY-MM-DD 00:00:00"),
                            time_end: moment(timeStr).endOf('month').format("YYYY-MM-DD 00:05:00"),
                        };
                    }),
            })
                .then((retData) => {
                    setChartLoading(false);
                    // console.log(retData);
                    if (retData.data.results.length > 0) {
                        renderChart(chartNode.current, retData.data.results[0].data_list, moment(timeStr));
                    };
                });
        };
    }, [chartInfo]);
    useEffect(() => {
        if (!!commonInfo) {
            const { state: { selectedDevice, time } } = commonInfo;
            if (!selectedDevice || !time) return;
            const [timeTab, timeStr] = time;
            const [curr_tag_1, curr_tag_2] = ['a', 'b'].map((key) => selectedDevice.tag_name + extdTag + getSuffix(key, timeTab.tag, timeStr));

            // 历史create_time取周期末的23:30:00
            // 当前周期的create_time取当前时间前1小时10分
            let create_time = moment(timeStr).endOf(timeTab.momentUnit).format("YYYY-MM-DD 23:30:00");
            if (moment(timeStr).isSame(moment(), timeTab.isAfterKey)) {
                // 当前周期内
                create_time = moment().subtract(1, 'hours').format("YYYY-MM-DD HH:10:00");
            };

            setTopLoading(true);
            post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time', {
                tag_name_list: [curr_tag_1, curr_tag_2],
                create_time,
            })
                .then((retData) => {
                    setTopLoading(false);
                    // console.log(retData);
                    if (!!retData.data && retData.data.errcode === 0) {
                        const tag2Value = retData.data.results;
                        let nextValue = null;
                        if (tag2Value[curr_tag_1] !== undefined) {
                            nextValue = tag2Value[curr_tag_1];
                        };
                        let nextRate = null;
                        if (tag2Value[curr_tag_2] !== undefined) {
                            nextRate = tag2Value[curr_tag_2];
                        };
                        setValue(nextValue);
                        setRate(nextRate);
                    };
                });
        };
    }, [commonInfo]);
    const chartTimeText = useMemo(() => {
        if (!!chartInfo) {
            const { timeStr } = chartInfo;
            return moment(timeStr).format("YYYY-MM");
        } else {
            return '--';
        };
    }, [chartInfo]);
    const valueText = useMemo(() => {
        if (typeof value === 'number') {
            return value >= 1000 ? Math.floor(value) : (value >= 100 ? Math.floor(value * 10) / 10 : (value >= 10 ? Math.floor(value * 100) / 100 : (Math.floor(value * 1000) / 1000)));
        } else {
            return '--';
        };
    }, [value]);
    const rateText = useMemo(() => {
        if (typeof rate === 'number') {
            return Math.floor(rate * 100) / 100;
        } else {
            return '--';
        };
    }, [rate]);
    const chartTimeOnChange = (delta) => {
        const { timeStr } = chartInfo;
        setChartInfo(Object.assign({}, chartInfo, {
            timeStr: moment(timeStr).add(delta, 'months').format("YYYY-MM"),
        }));
    };
    const loading = useMemo(() => { return topLoading && chartLoading }, [topLoading, chartLoading]);
    return (
        <div className={style['wrapper']}>
            <div className="title_row">
                <div className="title">有功电量</div>
                <div className="arrow down_arrow" />
                <div className="percent_text">{rateText}%</div>
            </div>
            <div className="value_row">
                <div className="value">{valueText}</div>
                <div className="unit">万kWh</div>
            </div>
            <div className="divide_line_row" />
            <div className="switch_time_row">
                <LeftOutlined onClick={() => chartTimeOnChange(-1)} />
                <div className="time_text">{chartTimeText}</div>
                <RightOutlined onClick={() => chartTimeOnChange(1)} />
            </div>
            <div className="chart_block">
                <div style={{ height: 380,marginTop:10 }} ref={chartNode} />
            </div>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
