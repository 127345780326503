import React, { useContext, useMemo, useEffect, useState } from 'react';
import style from './index.module.scss';
import ICON_CALENDAR from '../../../../assets/time.png';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import CommonContext from '../context';
import Tabs from 'components/Tabs';
import moment from 'moment';
import { time_key, timeRange_key } from '../constants';
import { Space, DatePicker, message } from 'antd';

const { RangePicker } = DatePicker;
const limitTimeStr = "01:00:00";

const tabs = [
    {
        name: '日',
        tag: 'date',
        calcKey: 'd',
        picker: 'date',
        queryStr: 'DAY',
        momentUnit: 'date',
        simpleCard_delta: [2, 'weeks'],
        isAfterKey: 'day',
        disabledDate: (current) => {
            if (!!current) {
                if (current > moment().endOf('day')) {
                    return true;
                } else {
                    const currMoment = moment();
                    if (moment(current).isSame(moment(currMoment), 'day')) {
                        if (moment(currMoment).isBefore(moment(moment(currMoment).startOf('date').format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                            return true;
                        };
                    };
                };
            };
            return false;
        },
    },
    {
        name: '周',
        tag: 'week',
        calcKey: 'w',
        picker: 'week',
        queryStr: 'WEEK',
        momentUnit: 'week',
        simpleCard_delta: [9, 'weeks'],
        isAfterKey: 'week',
        disabledDate: (current) => {
            if (!!current) {
                if (current > moment().endOf('week')) {
                    return true;
                } else {
                    const currMoment = moment();
                    if (moment(current).isSame(moment(currMoment), 'week')) {
                        if (moment(currMoment).isBefore(moment(moment(currMoment).startOf('week').format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                            return true;
                        };
                    };
                };
            };
            return false;
        },
    },
    {
        name: '月',
        tag: 'month',
        calcKey: 'M',
        picker: 'month',
        queryStr: 'MONTH',
        momentUnit: 'month',
        simpleCard_delta: [11, 'months'],
        isAfterKey: 'month',
        disabledDate: (current) => {
            if (!!current) {
                if (current > moment().endOf('month')) {
                    return true;
                } else {
                    const currMoment = moment();
                    if (moment(current).isSame(moment(currMoment), 'month')) {
                        if (moment(currMoment).isBefore(moment(moment(currMoment).startOf('month').format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                            return true;
                        };
                    };
                };
            };
            return false;
        },
    },
];
const defaultTab = tabs[0];

const Comp = (props) => {
    const commonInfo = useContext(CommonContext);
    const { state: { time, timeRange, selectedTab } } = commonInfo;
    const [visible, setVisible] = useState(false);

    const selectedIndex = useMemo(() => {
        const { state } = commonInfo;
        const { selectedTab } = state;
        if (!!selectedTab) {
            const time_obj = state[selectedTab.timeType];
            if (!!time_obj) {
                const [currTab] = time_obj;
                const retIndex = tabs.indexOf(currTab);
                if (retIndex !== -1) {
                    return retIndex;
                };
            };
        };
        return null;
    }, [commonInfo]);

    useEffect(() => {
        const { state: { timeRange, time, selectedTab }, dispatch } = commonInfo;
        if (!!selectedTab) {
            // 如果在当前天的 0:00 ~ 1:00，取上一天
            let momentTime = moment();
            if (moment(momentTime).isBefore(moment(moment(momentTime).format(`YYYY-MM-DD ${limitTimeStr}`)))) {
                momentTime = moment(momentTime).subtract(1, 'days');
            };
            if (selectedTab.timeType === time_key) {
                if (!time) {
                    // console.log('dispatch time');
                    // dispatch({ type: 'time', time: [defaultTab, '2020-09-20'] });// for 02/08 meeting
                    dispatch({ type: 'time', time: [defaultTab, momentTime.format("YYYY-MM-DD")] });
                };
            } else if (selectedTab.timeType === timeRange_key) {
                if (!timeRange) {
                    // console.log('dispatch timeRange');
                    // for 02/08 meeting
                    // dispatch({ type: 'timeRange', timeRange: [defaultTab, moment('2020-09-20').subtract(5, 'days').format("YYYY-MM-DD"), moment('2020-09-20').format("YYYY-MM-DD")] });
                    dispatch({ type: 'timeRange', timeRange: [defaultTab, moment(momentTime).subtract(3, 'days').format("YYYY-MM-DD"), moment(momentTime).format("YYYY-MM-DD")] });
                };
            };
        };
    }, [commonInfo]);

    const onChange = (index) => {
        const { state: { selectedTab }, dispatch } = commonInfo;
        if (!!selectedTab) {
            const nextTimeStr = moment().format("YYYY-MM-DD");
            if (selectedTab.timeType === time_key) {
                dispatch({ type: 'time', time: [tabs[index], nextTimeStr] });
            } else if (selectedTab.timeType === timeRange_key) {
                dispatch({ type: 'time', time: [tabs[index], moment().subtract(3, 'days').format("YYYY-MM-DD")] });
                dispatch({ type: 'timeRange', timeRange: [tabs[index], moment().subtract(3, 'days').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")] });
            };
        };
    };

    const quickTimeChange = (delta) => {
        const [currTab, timeStr] = time;
        const { calcKey, tag, name } = tabs[selectedIndex];
        const nextTimeStr = moment(timeStr).add(delta, calcKey).startOf(tag).format(`YYYY-MM-DD`);
        // 如果是向后的，需要判断当前是否是在下个周期第一天的limitTimeStr之后，否则不能跳下个周期${limitTimeStr}
        const limitMomentTime = moment(moment(nextTimeStr).startOf(tag).format(`YYYY-MM-DD ${limitTimeStr}`));
        if (moment().isBefore(moment(limitMomentTime))) {
            message.warning("无法晚于当" + name);
        } else {
            const { dispatch } = commonInfo;
            dispatch({ type: 'time', time: [currTab, nextTimeStr] });
        };
    };

    useEffect(() => {
        if (visible) {
            const cb = () => {
                setVisible(false);
            };
            window.addEventListener('click', cb);
            return () => {
                window.removeEventListener('click', cb);
            };
        };
    }, [visible]);

    const datePickerOnChange = (v) => {
        const { dispatch } = commonInfo;
        dispatch({ type: 'time', time: [tabs[selectedIndex], moment(v).format("YYYY-MM-DD")] });
        setVisible(false);
    };

    const rangePickerOnChange = (v) => {
        const [start_time, end_time] = v;
        const { dispatch } = commonInfo;
        dispatch({ type: 'time', time: [tabs[selectedIndex], moment(start_time).format("YYYY-MM-DD")] });
        dispatch({ type: 'timeRange', timeRange: [tabs[selectedIndex], moment(start_time).format("YYYY-MM-DD"), moment(end_time).format("YYYY-MM-DD")] });
        setVisible(false);
    };

    if (!selectedTab) return null;

    return (
        <div className={style['wrapper']}>
            <Tabs tabs={tabs} selectedIndex={selectedIndex} height={58} onChange={onChange} />
            <div className="time_selector_wrapper">
                {
                    visible && selectedIndex !== null && (
                        <div className="picker_wrapper">
                            <div
                                onClick={(e) => {
                                    // 当用range选择器时，需要在此处阻止冒泡避免弹窗消失
                                    // 后续的挂载在内部的意义似乎只剩下隐藏ant-picker-range-arrow
                                    e.stopPropagation();//此处不明白，日历组件默认挂载body下，但是这里的stopPropagation缺起效了，放在外部反而不起效
                                }}
                            >
                                {
                                    selectedTab.timeType === time_key ? (
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            dropdownClassName={style["cus_picker"]}
                                            // defaultValue={timeStamp}
                                            open={true}
                                            picker={tabs[selectedIndex].picker}
                                            onChange={datePickerOnChange}
                                            disabledDate={tabs[selectedIndex].disabledDate}
                                        />
                                    ) : (
                                        selectedTab.timeType === timeRange_key ? (
                                            <RangePicker
                                                style={{ width: "100%" }}
                                                dropdownClassName={style["cus_picker"]}
                                                // defaultValue={timeStamp}
                                                open={true}
                                                picker={tabs[selectedIndex].picker}
                                                onChange={rangePickerOnChange}
                                                disabledDate={tabs[selectedIndex].disabledDate}
                                            />
                                        ) : (null)
                                    )
                                }
                            </div>
                            {/* 遮挡内部点击事件 */}
                            <div className="hover_block"></div>
                        </div>
                    )
                }
                <div className="content">
                    <div className="icon_wrapper">
                        <img alt="" src={ICON_CALENDAR} width="35" height="35" className="icon" onClick={(e) => { setVisible(!visible); e.stopPropagation(); }} />
                    </div>
                    <div className="block_wrapper">
                        {
                            selectedTab.timeType === time_key && !!time ? (
                                <div className="date_picker_wrapper">
                                    <LeftOutlined style={{ fontSize: 20 }} onClick={() => quickTimeChange(-1)} />
                            <div className="text" style={{ margin: '0 5px' }}>{time[1]}</div>
                                    <RightOutlined style={{ fontSize: 20 }} onClick={() => quickTimeChange(1)} />
                                </div>
                            ) : (
                                selectedTab.timeType === timeRange_key && !!timeRange ? (
                                    <div className="date_picker_wrapper">
                                        <Space>
                                            <div className="text">{timeRange[1]}</div>
                                            <div className="text">~</div>
                                            <div className="text">{timeRange[2]}</div>
                                        </Space>
                                    </div>
                                ) : (null)
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comp;
