import React from 'react';
import Config from '../../common/config.js';
import { post } from '../../common/utils.js';
import DisplayCard from './DisplayCard.js';
import DisplayCardConf from './config/DisplayCardConf.js';
import './DisplayCardGroup.scss';
// const PIC_SERVER_PATH = 'https://ly-static-dev.ycim.tech/';
export default class DisplayCardGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayCardList: [],
            requestParams: [],
            tagAlias2Objects: {}
        };
    }
    componentWillMount() {
        this.setDisplayCardList(this.props.pageId);
    }
    componentWillUnmount() {
        clearInterval(this.state.setIntervalId);
    }
    componentWillReceiveProps(nextProps) {
        clearInterval(this.state.setIntervalId);
        this.setDisplayCardList(nextProps.pageId);
    }
    setDisplayCardList = pageId => {
        let self = this;
        if (!DisplayCardConf[pageId]) {
            self.setState({ displayCardList: [] });
            return;
        }
        let displayCardList = DisplayCardConf[pageId], requestParams = [], tagAlias2Objects = {};
        displayCardList.forEach(item => {
            item.deviceId && requestParams.push({
                device_id: item.deviceId,
                tag_alias_list: []
            })
            item.items.forEach(it => {
                if (it.tagAlias) {
                    requestParams[requestParams.length - 1].tag_alias_list.push(it.tagAlias);
                    tagAlias2Objects[it.tagAlias] = it;
                }
            })
        });
        // console.log("leiqiao", tagAlias2Objects);
        self.setState({ tagAlias2Objects, requestParams, displayCardList }, _ => {
            self.getLatestData(requestParams);
            self.state.setIntervalId = setInterval(_ => {
                self.getLatestData(requestParams);
            }, 5000);
        });
    }
    getLatestData = requestParams => {
        if (requestParams.length === 0) return;
        let self = this, displayCardList = self.state.displayCardList, tagAlias2Objects = self.state.tagAlias2Objects;
        post(Config.deviceApi.API_GET_BATCH_LATEST_DATA_ITEMS, requestParams).then(retData => {
            console.log("leiqiao", retData);
            if (retData.data && retData.data.result) {
                retData.data.result.forEach(item => {
                    item.data_list.forEach(it => {
                        tagAlias2Objects[it.tag_alias] && (tagAlias2Objects[it.tag_alias].value = it.value);
                    })
                })
                // console.log("leiqiao", displayCardList);
                self.setState({ displayCardList });
                // let dataList = self.state.dataList;
                // retData.data.result.data_list.forEach(item => { dataList[item.tag_alias] = roundNum(item.value, 1) });
                // self.setState({ dataList });
            }
        });
    }
    render() {
        let self = this;

        return (
            <div className="DisplayCardGroupWrapper">
                {
                    self.state.displayCardList.map((item, index) =>
                        <DisplayCard data={item} key={index} />
                    )
                }
                {/* <DisplayCard type={0} />
                <DisplayCard type={13} />
                <DisplayCard type={1} /> */}
            </div>
        );
    }
}
