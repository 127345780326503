import React, { useMemo, useContext, useState, useEffect } from 'react';
import SepTimePie from './SepTimePie';
import DailyChart from './DailyChart';
import HourlyChart from './HourlyChart';
import style from './SepTimeBoard.module.scss';
import CommonContext from '../../context';
import { getSuffixIndexByTime } from '../../utils';
import {
    tableSuffixList,
    chartSuffixList,
    tableExtdList,
    chartExtdList,
} from './constants';
import { post } from 'common/utils';
import moment from 'moment';
import { Spin } from 'antd';
import { getTimeRange } from './utils';

const charts = [DailyChart, HourlyChart];

const Comp = (props) => {
    const [tag2Val, setTag2Val] = useState({});
    const [hisTag2Val, setHisTag2Val] = useState({});
    const [loading, setLoading] = useState(false);
    const commonInfo = useContext(CommonContext);
    const [table_tags, chart_tags] = useMemo(() => {
        if (!!commonInfo) {
            const { state: { selectedDevice, time } } = commonInfo;
            if (!!selectedDevice && !!time) {
                const [timeTab, timeStr] = time;
                const suffixIndex = getSuffixIndexByTime(timeTab.tag, timeStr);
                const tableSuffix = tableSuffixList[suffixIndex];
                const chartSuffix = chartSuffixList[suffixIndex];
                const tags = tableExtdList
                    .map((extdTag) => {
                        return selectedDevice.tag_name + extdTag + tableSuffix;
                    });
                const hisTags = chartExtdList
                    .map((extdTag) => {
                        return selectedDevice.tag_name + extdTag + chartSuffix;
                    });
                return [tags, hisTags];
            };
        };
        return [null, null];
    }, [commonInfo]);

    // 外部变化后重新请求数据
    useEffect(() => {
        if (!!commonInfo) {
            const { state: { selectedDevice, time } } = commonInfo;
            if (!selectedDevice || !time) return;
            const [timeTab, timeStr] = time;
            const suffixIndex = getSuffixIndexByTime(timeTab.tag, timeStr);
            const tableSuffix = tableSuffixList[suffixIndex];
            const chartSuffix = chartSuffixList[suffixIndex];
            const tags = tableExtdList
                .map((extdTag) => {
                    return selectedDevice.tag_name + extdTag + tableSuffix;
                });
            const hisTags = chartExtdList
                .map((extdTag) => {
                    return selectedDevice.tag_name + extdTag + chartSuffix;
                });
            // console.log(tags, hisTags);
            const trimedTags = [...new Set(tags)];
            const trimedHisTags = [...new Set(hisTags)];

            const [his_time_begin, his_time_end] = getTimeRange(timeTab.tag, timeStr) || [];

            // 历史create_time取周期末的23:30:00
            // 当前周期的create_time取当前时间前1小时10分
            let create_time = moment(timeStr).endOf(timeTab.momentUnit).format("YYYY-MM-DD 23:30:00");
            if (moment(timeStr).isSame(moment(), timeTab.isAfterKey)) {
                // 当前周期内
                create_time = moment().subtract(1, 'hours').format("YYYY-MM-DD HH:10:00");
            };

            setLoading(true);
            Promise.all([
                post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time', {
                    tag_name_list: trimedTags,
                    create_time,
                }),
                post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data', {
                    tag_list: trimedHisTags
                        .map((tag_name) => {
                            return {
                                tag_name,
                                time_begin: his_time_begin,
                                time_end: his_time_end,
                            };
                        }),
                }),
            ])
                .then(([retData1, retData2]) => {
                    setLoading(false);
                    // console.log(retData1, retData2);
                    if (!!retData1.data && retData1.data.errcode === 0) {
                        const nextTag2Val = retData1.data.results;
                        setTag2Val(nextTag2Val);
                    };
                    if (!!retData2.data && retData2.data.errcode === 0) {
                        if (retData2.data.results.length > 0) {
                            const nextHisTag2Val = retData2.data.results
                                .reduce((pV, cItem) => {
                                    const { tag_name, data_list } = cItem;
                                    if (!!data_list && data_list.length > 0) {
                                        return Object.assign({}, pV, { [tag_name]: data_list })
                                    };
                                    return pV;
                                }, {});
                            setHisTag2Val(nextHisTag2Val);
                        };
                    };
                });
        };
    }, [commonInfo]);
    // 外部变化后重新请求数据

    // 用于根据日周月切换组件
    const timeType = useMemo(() => {
        if (!!commonInfo) {
            const { state: { time: [timeTab] } } = commonInfo;
            return timeTab.tag;
        };
        return null;
    }, [commonInfo]);
    const CusComp = useMemo(() => {
        if (typeof timeType === 'string') {
            if (timeType === 'date') {
                return charts[1];
            } else {
                return charts[0];
            };
        };
        return null;
    }, [timeType]);
    // 用于根据日周月切换组件

    const pieDatas = useMemo(() => {
        if (!table_tags) {
            return null;
        };
        return table_tags
            .map((tag) => {
                if (typeof tag2Val[tag] === 'number') {
                    return tag2Val[tag];
                } else {
                    return null;
                };
            });
    }, [tag2Val, table_tags]);

    const hisDatas = useMemo(() => {
        if (!chart_tags) {
            return null;
        };
        return chart_tags
            .map((tag) => {
                if (!!hisTag2Val[tag]) {
                    return hisTag2Val[tag];
                } else {
                    return null;
                };
            });
    }, [hisTag2Val, chart_tags]);

    return (
        <div className={style['SepTimeBoard']}>            
            <div className='BoardContent'>
                <div className="left">
                    <div className='BoardTitle'>峰谷平用电分析</div>
                    <SepTimePie pieDatas={pieDatas} />
                </div>
                <div className="right">
                    {
                        CusComp !== null && (
                            <CusComp hisDatas={hisDatas} />
                        )
                    }
                </div>
            </div>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
