import React from 'react';
import { message, Spin, Button, Upload, Modal } from 'antd';
import { DownloadOutlined, UploadOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { get, post, patch, postDownloadFile } from '../../common/utils.js';
import ExcelEditor from '../../components/ExcelEditor/ExcelEditor.js';
import FileItem from './FileItem.js';
import './FolderContainer.scss';
// import 'antd/dist/antd.less';

const checkOverlapped = (r1_x1, r1_y1, r1_x2, r1_y2,  r2_x1, r2_y1, r2_x2, r2_y2)=>{
    //求中心点距离
    let verticalDistance = Math.abs(Math.abs(r1_x1 + r1_x2)/2 - Math.abs(r2_x1 + r2_x2)/2);
    let horizontalDistance = Math.abs(Math.abs(r1_y1 + r1_y2)/2 - Math.abs(r2_y1 + r2_y2)/2);
 
    let verticalThreshold = (Math.abs(r1_x1 - r1_x2) + Math.abs(r2_x1 - r2_x2))/2   //两矩形分离的垂直临界值
    let horizontalThreshold = (Math.abs(r1_y1 - r1_y2) + Math.abs(r2_y1 - r2_y2))/2; //两矩形分离的水平临界值
 
    if(verticalDistance > verticalThreshold || horizontalDistance > horizontalThreshold){
        return false;
    }else{
        return true;
    }
};

const getElPosi = ( _element ) => {
    let rect = _element.getBoundingClientRect();
    return {
        x: rect.left,
        y: rect.top
    };
}

const zoomVal = 1;

export default class FolderContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            // selectedFileId: null,
            selectedFileIds: [],
            onOpenFileId: null,
            modalLoading: false,
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
            // boxOffsetTop: 0,
            // boxOffsetLeft: 0,
        };
    }

    componentDidMount() {
        this.fetchFileList();
    }

    fetchFileList = ()=>{
        let self = this;
        get(process.env.REACT_APP_SERVER_PATH + 'api/pan/files', {
            folder_id: this.props.folder_id,
            is_folder: 0
        }).then(retData => {
            if (retData.data && retData.data.results) {
                self.setState({dataList: retData.data.results, selectedFileIds: []});
            } else {
            };
        });
    }

    delFile = ()=>{
        let self = this;
        const execDel = () => {
            post(process.env.REACT_APP_SERVER_PATH + 'api/pan/delete_files', {
                file_ids: this.state.selectedFileIds,
            }).then((retData) => {
                if (retData.data && retData.data.errcode == 0) {
                    message.success("删除成功");
                    self.fetchFileList();
                } else {
                    message.error("删除失败");
                };
            });
        };

        Modal.confirm({
            title: '确认删除吗？',
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            cancelText: '取消',
            onOk: () => execDel(),
        });
    }

    downloadFile = ()=>{
        if(this.state.selectedFileIds.length > 0){
            // 此处id无实际意义，目的在于避免浏览器出现同一地址下载多文件的提示。
            postDownloadFile(process.env.REACT_APP_SERVER_PATH + "api/pan/download_file?id="+parseInt(new Date().getTime()), {
                "file_ids": this.state.selectedFileIds.join(','),
            });
        }
    }

    onClickModalOK = ()=>{
        if(this.tableLoading === true){
            return;
        }
        this.saveExcel();
    }

    openExcel = (fileId)=>{
        let url = window.location.href.split('#')[0];
        window.open(url+'#/ExcelWindow?file_id='+fileId);
    }

    saveExcel = ()=>{
        let self = this;
        if(this.excel_inst_save){
            this.setState({modalLoading: true});
            this.excel_inst_save().then(()=>{
                console.log('excel saved');
                post(process.env.REACT_APP_SERVER_PATH + 'api/pan/save_doc', {
                    file_key: self.state.fileKey,
                    file_id: self.state.onOpenFileId
                }).then(retData => {
                    // self.setState({modalLoading: false});
                    if (retData.data && retData.data.errcode == 0) {
                        message.success('已保存');
                        self.setState({editingExcel: false}, ()=>self.fetchFileList());
                    } else {
                        message.error('保存失败');
                    };
                });
            });
        }
    }

    editExcel = (file_id)=>{
        let self = this;
        this.setState({loading: true});
        post(process.env.REACT_APP_SERVER_PATH + 'api/pan/copy_doc_to_tmp', {
            file_id: file_id
        }).then(retData => {
            this.setState({loading: false, modalLoading: false});
            let d = retData.data;
            if (d && d.errcode === 0) {
                self.setState({
                    modalKey: 'modal-' + parseInt(new Date().getTime()),
                    editingExcel: true,
                    fileKey: d.file_key,
                    onOpenFileId: file_id,
                })
            } else {
            };
        });
    }

    bindFolderBox = node=>{
        this.boxNode=node;

    }

    updateSelection = (x1, y1, x2, y2, nodes, scrollTop)=>{
        let x_min = Math.min(x1, x2);
        let x_max = Math.max(x1, x2);
        let y_min = Math.min(y1, y2);
        let y_max = Math.max(y1, y2);
        let num = 0;
        let selectedIds = [];
        for(let i = 1; i < nodes.length; i++){
            let n = nodes[i]
            let _x1 = n.offsetLeft;
            let _y1 = n.offsetTop;
            let _x2 = n.offsetLeft + n.offsetWidth;
            let _y2 = n.offsetTop + n.offsetHeight;
            let _x3 = n.offsetLeft + n.offsetWidth;
            if(checkOverlapped(x1, y1, x2, y2,  _x1, _y1, _x2, _y2)){
                num ++;
                selectedIds.push(this.state.dataList[i-1].id);
            }
        }
        // console.log(num);
        this.setState({selectedFileIds: selectedIds});
    }

    render() {
        let self = this;
        const { dataList, selectedFileIds, editingExcel, fileKey, selectedFileName, modalLoading,
                x1, y1, x2, y2
        } = this.state;
        const {folder_id} = this.props;
        // console.log(x1, y1, x2, y2);
        // console.log(scrollTopBegin, scrollTopCurr);
        // console.log('height: ', Math.abs(y2-y1) + (scrollTopCurr - scrollTopBegin), ', y1: ', y1, ', y2: ', y2);

        return (
            <div className="FolderContainer">
                <div className="toolBar">
                    <div className="leftTools">
                        <Upload
                            name='file'
                            action={process.env.REACT_APP_SERVER_PATH + "api/pan/upload_file"}
                            data={{
                                folder_id: folder_id
                            }}
                            accept=".xlsx,.py,.xls,.pdf,.txt"
                            // headers={{
                            //     authorization: '***',
                            // }}
                            showUploadList={false}
                            onChange = {(info)=>{
                                const { status } = info.file;
                                if (status !== 'uploading') {
                                    console.log(info.file, info.fileList);
                                }
                                if (status === 'done') {
                                    message.success("文件已上传");
                                    console.log(info.file.response);
                                    self.fetchFileList();
                                } else if (status === 'error') {
                                    message.error("上传失败");
                                }
                            }}
                        >
                            <Button className='uploadBtn' style={{marginLeft: 0}} icon={<UploadOutlined />} type="primary" ghost>上传文件</Button>
                        </Upload>
                        {
                            selectedFileIds.length == 1 &&
                            <Button className="editBtn" type="default" icon={<EditOutlined />} onClick={()=>this.openExcel(selectedFileIds[0])} type="primary">编辑</Button>
                        }
                    </div>
                    <div className="rightTools">
                    {
                        selectedFileIds.length == 1 &&
                        <Button className="downloadBtn" type="default" icon={<DownloadOutlined />} onClick={()=>this.downloadFile()} >下载文件</Button>
                    }
                    {
                        selectedFileIds.length > 1 &&
                        <Button className="downloadBtn" type="default" icon={<DownloadOutlined />} onClick={()=>this.downloadFile()} >打包下载{selectedFileIds.length}个文件</Button>
                    }
                    {
                        selectedFileIds.length > 0 &&
                        <Button className="delBtn" danger onClick={()=>this.delFile()}>删除</Button>
                    }
                    </div>
                </div>
                <div
                    className="downContent"
                    ref={node=>this.boxNode=node}
                    onMouseDown={e=>{
                        let boxNode = this.boxNode;
                        // console.log('boxNode posi x/y ', getElPosi(boxNode).x + '/' + getElPosi(boxNode).y);
                        // console.log('boxNodex offset left/top', boxNode.offsetLeft + "/" + boxNode.offsetTop);
                        // console.log('[onMouseDown] clientX: ', e.clientX, ', clientY: ', e.clientY);
                        // console.log('clientX - offsetLeft: ', e.clientX - boxNode.offsetLeft, ', clientY - offsetTop: ', e.clientY - boxNode.offsetTop);
                        // console.log('pageX/Y: ', e.pageX + '/' +e.pageY);
                        // console.log('x1/y1: ', (e.pageX/zoomVal+boxNode.scrollLeft-boxNode.offsetLeft) + '/' + (e.pageY/zoomVal+boxNode.scrollTop-boxNode.offsetTop));
                        // console.log('x: ', (e.pageX/zoomVal+boxNode.scrollLeft-boxNode.offsetLeft), ', y: ' + (e.pageY/zoomVal+boxNode.scrollTop-boxNode.offsetTop));
                        let boxPosi = getElPosi(boxNode);
                        self.setState({
                            // x1: e.pageX/zoomVal+boxNode.scrollLeft-boxNode.offsetLeft,
                            // y1: e.pageY/zoomVal+boxNode.scrollTop-boxNode.offsetTop,
                            x1: e.pageX/zoomVal+boxNode.scrollLeft-boxPosi.x,
                            y1: e.pageY/zoomVal+boxNode.scrollTop-boxPosi.y,
                            // boxOffsetTop: boxNode.offsetTop,
                            // boxOffsetLeft: boxNode.offsetLeft,
                            // selectedFileIds: [],
                        });
                    }}

                    onMouseMove={e=>{
                        // console.log('scrollTop: ', self.boxNode.scrollTop);
                        // console.log('box clientY: ', self.boxNode.offsetTop);
                        // console.log('[onMouseMove] clientX: ', e.clientX, ', clientY: ', e.clientY);
                        if(x1 != 0 && y1 != 0){
                            let boxNode = this.boxNode;
                            let boxPosi = getElPosi(boxNode);

                            // 鼠标在边缘时滚动框框
                            if(e.clientY < boxPosi.y + 30){
                                self.boxNode.scrollTop = self.boxNode.scrollTop - 10;
                            }else if(e.clientY > boxPosi.y + self.boxNode.offsetHeight - 30){
                                self.boxNode.scrollTop = self.boxNode.scrollTop + 10;
                            }

                            let x2 = e.pageX/zoomVal+boxNode.scrollLeft-boxPosi.x;
                            let y2 = e.pageY/zoomVal+boxNode.scrollTop-boxPosi.y;

                            let currTime = new Date().getTime();
                            if(self.lastCheckTime && (currTime - self.lastCheckTime) > 30){
                                self.setState({x2: x2, y2: y2},
                                    ()=>{
                                        if(x2 != 0 && Math.abs(x2-x1) > 3 || Math.abs(y2-y1) > 3){
                                            self.updateSelection(x1,y1,x2,y2, self.boxNode.childNodes, self.boxNode.scrollTop);
                                        }
                                    });
                                self.lastCheckTime = currTime;
                            }else if(!self.lastCheckTime){
                                self.lastCheckTime = currTime;
                            }
                        }
                    }}

                    onMouseUp={e=>{
                        let boxNode = this.boxNode;
                        let boxPosi = getElPosi(boxNode);
                        let x2 = e.pageX/zoomVal+boxNode.scrollLeft-boxPosi.x;
                        let y2 = e.pageY/zoomVal+boxNode.scrollTop-boxPosi.y;
                        // let x2 = e.pageX/zoomVal+boxNode.scrollLeft-boxNode.offsetLeft;
                        // let y2 = e.pageY/zoomVal+boxNode.scrollTop-boxNode.offsetTop;
                        console.log(x1, y1, x2, y2);
                        console.log('[onMouseUp] clientX: ', e.clientX, ', clientY: ', e.clientY);
                        if((Math.abs(x2-x1) < 3 || Math.abs(y2-y1) < 3) && selectedFileIds.length > 0){
                            console.log('clear selection');
                            self.setState({selectedFileIds: []});
                            self.clearSelectTime = new Date().getTime();
                        }
                        self.setState({x1: 0, y1: 0, x2: 0, y2: 0});
                    }}

                    // onClick={e=>{
                    //     self.setState({selectedFileIds: []})
                    // }}
                >
                    <div
                        className="selectRect"
                        style={{
                            // 以下为了避免在轻点鼠标时显示框框，使得子div的onclick因框框阻挡而不被触发
                            display: ((x1==0||x2==0||(Math.abs(x2-x1) <= 3 || Math.abs(y2-y1) <= 3))?'none':'unset'),
                            left: Math.min(x1, x2),
                            top: Math.min(y1, y2),
                            width: Math.abs(x2-x1),
                            height: Math.abs(y2-y1)
                        }}
                    ></div>
                    {
                        dataList.map((item, index)=>
                            <FileItem
                                key={'file-'+item.id}
                                name={item.name}
                                folder_id={folder_id}
                                selected={selectedFileIds.indexOf(item.id) >= 0}
                                onClick={(e)=>{
                                    if(selectedFileIds.length == 0){
                                        // 如果未处于刚取消选择的时刻，则打开编辑页面
                                        if((self.clearSelectTime != null && (new Date().getTime() - self.clearSelectTime) > 500)
                                            || self.clearSelectTime == null){
                                            self.openExcel(item.id);
                                            // self.editExcel(item.id); //当前页弹框编辑
                                        }
                                    }else{
                                        self.setState({selectedFileIds: []});
                                    }
                                }}
                                onSelect={selected=>{
                                    if(selected){
                                        if((self.clearSelectTime != null && (new Date().getTime() - self.clearSelectTime) > 500)
                                            || self.clearSelectTime == null){
                                            // console.log('offsetTop: ', self.boxNode.childNodes[index+1].offsetTop);
                                            self.setState({selectedFileIds: [item.id]});
                                            self.clearSelectTime = null;
                                        }
                                    }else{
                                        self.setState({selectedFileIds: []});
                                    }
                                }}
                            />
                        )
                    }
                </div>
                {
                    editingExcel &&
                    <Modal
                        key={this.state.modalKey}
                        className="excel_modal"
                        title="EXCEL 编辑器"
                        visible={this.state.editingExcel}
                        width={1000}
                        style={{
                            minWidth: 1200,
                            height: 550,
                            top: 10
                        }}
                        onOk={()=>this.onClickModalOK()}
                        onCancel={()=>this.setState({editingExcel: false})}
                        okText="保存"
                    >
                        <Spin tip="请稍等..." spinning={modalLoading}>
                            <div className="modalEditContent">
                                <ExcelEditor
                                    key={fileKey}
                                    file_key={fileKey}
                                    title={selectedFileName}
                                    width={1200}
                                    height={550}
                                    getSaveFunc={_func=>this.excel_inst_save=_func}
                                />
                            </div>
                        </Spin>
                    </Modal>
                }
            </div>
        );
    }
}
