import React from 'react';
import './DeviceDynamicPic.scss';
import { Spin } from 'antd';
import DomResize from 'components/DomResize';

const PIC_SERVER_PATH = process.env.REACT_APP_SERVER_PATH + 'api/img/download/';
export default class DeviceDynamicPic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDataConfig: false,
            remoteControlTagAlias: "",
            workingWidth: 0,
            bckImgLoading: false,
        };
        this.imgRect = null;
        this.wrapperRect = null;
    }
    componentDidMount() {
        this.setState({
            workingWidth: window.screen.width,
            bckImgLoading: true,
        });
    }

    //getWidthZoom
    gWZoom = num => num * this.state.workingWidth / 100;
    //getHeightZoom
    gHZoom = num => num * this.props.diagramData.HWRatio * this.state.workingWidth / 100;

    getTextStyle(item) {
        var tmpFontSize = this.gWZoom(item.fontSize);
        var tmpStyle = {
            position: "absolute"
            , top: this.gHZoom(item.coordinate[0]) + "px"
            , right: this.gWZoom(item.coordinate[1]) + "px"
            , fontSize: tmpFontSize + "px"
            , lineHeight: (item.lineHeight ? item.lineHeight : 100) + "%"
        };
        tmpStyle.color = item.color ? item.color : '#000000';
        if (tmpFontSize < 12) {
            tmpStyle.transform = "scale(" + tmpFontSize / 12 + ")";
            tmpStyle.transformOrigin = "right top";
            tmpStyle.fontSize = "12px";
        }
        return tmpStyle;
    }
    ifImgShow = item => {
        let index = false, tmpVal = this.props.dataList[item.tag_alias];
        if (item.conditionType === 3) { index = true; }
        else if (item.conditionType === 2) {
            // let x = tmpVal;
            // eslint-disable-next-line
            if (eval(item.condition)) index = true;
        } else if (item.conditionType === 1) {
            if ((tmpVal + '') === (item.condition + '')) index = true;
        }
        return index;
    }
    getImgStyle(item, index) {
        return {
            display: "block"
            , width: this.gWZoom(item.width) + "px"
            , position: index === 0 ? 'relative' : "absolute"
            , top: this.gHZoom(item.coordinate[0]) + "px"
            , right: this.gWZoom(item.coordinate[1]) + "px"
        };
    }
    getImgProps = (item, index) => {
        let retProps = {
            style: this.getImgStyle(item, index),
            src: PIC_SERVER_PATH + item.srcImg[0],
            alt: ""
        };
        if (index === 0) {
            /**
             * 这里是在展示图形的大背景图上加一个onload事件，在图片加载之后，重新计算展示图的宽度
             * 如果不加的话，在原来的情况下，如果图片本身比较“长”，那么会产生上y轴的scroll，导致显示问题
             */
            retProps.onLoad = e => {
                this.imgRect = {
                    width: e.target.width,
                    height: e.target.height,
                };
                this.imgWrapperResize();
                this.setState({ bckImgLoading: false });
            }
        }
        return retProps;
    }

    imgWrapperResize = () => {
        if (!!this.imgRect && !!this.wrapperRect) {
            const { width, height } = this.imgRect;
            const { width: clientWidth, height: clientHeight } = this.wrapperRect;
            let nextWorkingWidth = 0;
            if (![width, height, clientWidth, clientHeight].includes(0)) {
                if (width * clientHeight > clientWidth * height) {
                    // 竖条内嵌图
                    nextWorkingWidth = clientWidth;
                } else {
                    // 横条内嵌图
                    nextWorkingWidth = clientHeight / height * width;
                };
            };
            this.setState({ workingWidth: nextWorkingWidth });
        };
    }

    buttonOnClick = Px => {
        console.log(Px);
        //跳转为空不跳转
        if (!Px) return;
        let devideId = parseInt(Px);
        this.props.deviceRouter(devideId);
    }
    remoteControlOnClick = tagAlias => {
        console.log(tagAlias);
        this.setState({
            showDataConfig: true,
            remoteControlTagAlias: tagAlias
        })
    }
    onResize = (conf) => {
        this.wrapperRect = conf;
        if (this.state.bckImgLoading) return;
        this.imgWrapperResize();
    };
    render() {
        const { workingWidth, bckImgLoading } = this.state;
        return (
            <div className="DeviceDynamicPicWrapper">
                <div className="resize_wrapper">
                    <DomResize mountedCall={true} onResize={this.onResize} />
                </div>
                <div className="picRoot" style={Object.assign({ width: workingWidth }, bckImgLoading ? { opacity: 0 } : {})}>
                    {
                        this.props.diagramData.fontData.map((item, index) => {
                            if (parseInt(item.type) === 0) {
                                let precision = 1;
                                if (typeof item.precision === 'number') {
                                    const tmp_precision = Math.floor(item.precision);
                                    if (tmp_precision >= 0) {
                                        precision = tmp_precision;
                                    };
                                };
                                const multiple = Math.pow(10, precision);
                                let value = this.props.dataList[item.tag_alias];
                                if (typeof value === 'number') {
                                    value = Math.floor(value * multiple) / multiple;
                                } else {
                                    value = "";
                                };
                                return (
                                    <div key={index} style={this.getTextStyle(item)}>{value}</div>
                                );
                            } else if (parseInt(item.type) === 1) {
                                return this.ifImgShow(item) && <img alt="" key={index} {...this.getImgProps(item, index)} />
                            } else if (parseInt(item.type) === 2) {
                                return <img className={item.condition ? "type2" : ""} alt="" key={index} {...this.getImgProps(item, index)} onClick={_ => this.buttonOnClick(item.condition)} />
                            } else if (parseInt(item.type) === 3) {
                                return <div className="type3" key={index} style={this.getTextStyle(item)} onClick={_ => { this.remoteControlOnClick(item.tag_alias) }}>{this.props.dataList[item.tag_alias]}</div>
                            } else if (parseInt(item.type) === 4) {
                                return <div key={index} style={this.getTextStyle(item)}>{item.text}</div>
                            };
                            return null;
                        })
                    }
                </div>
                {
                    bckImgLoading && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Spin tip="加载中..." />
                        </div>
                    )
                }
            </div>
        );
    }
}
