import React from "react";
import './index.scss'
import AbnormalLineChart from "./LineChart";
const Abnormal = (props) => {
    const {
        // ethaneRate,
        // ethanePercent,
        // ethaneWeek,
        // ethaneMonth,
        // ethaneYear,
        // ethaneReason,
        // propaneRate,
        // propanePercent,
        // propaneWeek,
        // propaneMonth,
        // propaneYear,
        // propaneReason,
        // butaneRate,
        // butanePercent,
        // butaneWeek,
        // butaneMonth,
        // butaneYear,
        // butaneReason
        abnormalData
    } = props
    return (
        <div className="abnormal">
            <div className="abnormal-title">异常指标</div>
            <ul>
                {
                    abnormalData && abnormalData.map((item, index) => {
                        return (
                            <li className="itemList" key={item.tag_name} style={{borderBottom: index === 2? 'none': '1px solid #F1F1F1'}}>
                                <div className="item-left">{item.time_begin}</div>
                                <div className="item-center">
                                    <div className="item-center-top">{item.content}</div>
                                    <div className="item-center-center">
                                        <span>异常次数：</span>
                                        <span>本周{item.week_num}次，本月{item.month_num}次，本年{item.year_num}次</span>
                                    </div>
                                </div>
                                <AbnormalLineChart abnormalChart={item.lineChart} abnormalTime={item.time} basicTop={item.lower_limit}/>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}
export default Abnormal
