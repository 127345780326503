import React from 'react';
import { withRouter } from 'react-router';
import { getMyPermissions } from 'common/AuthPermission.js';
import style from './Header.module.scss';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tabs: ['全部告警', '进行中', '已结束'],
            selectedTab: 0,
            myPermissions: getMyPermissions(),
        }
    }

    tabOnChange = selectedTab => {
        const { statusOnChange = () => { } } = this.props;
        this.setState({ selectedTab });
        statusOnChange([null, false, true][selectedTab]);
    }

    render() {
        const { tabs, selectedTab } = this.state;
        const { unfinishedNum = null } = this.props;

        return (
            <div className={style["alarm_page_header_wrapper"]}>
                {
                    tabs.map((title, index) => (
                        <div key={index} className={'tab_title' + (selectedTab === index ? ' tab_title_selected' : '')} onClick={() => this.tabOnChange(index)}>
                            <span className="text">{title}</span>
                            {
                                index === 1 && unfinishedNum !== null && (
                                    <span className="unfinished_circle">{unfinishedNum}</span>
                                )
                            }
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default withRouter(Header);
