import React from 'react';
import { Tree, message, Spin, Tabs, Button, TreeSelect, Modal, Input } from 'antd';
import { ProfileOutlined, CodeOutlined, FolderOpenOutlined, FolderAddOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { get, post, patch } from '../../common/utils.js';
import FolderContainer from './FolderContainer.js';
import ScriptList from './ScriptList.js';
import './OnlineDisk.scss';
// import 'antd/dist/antd.less';

export default class OnlineDisk extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            folderTree: [],
            selectedFolderId: null,
            activeTabKey: 'files', // 'files' or 'scripts'
            delFolderLoading: false,
            showFolderModal: false,
            modalParentFolderValue: 'null',
            modalFolderName: '',
            modalFolderLoading: false,
            expandedKeys: [],
            folderName: '',
        };
    }

    componentDidMount() {
        this.fetchFolderTree(null);
    }

    fetchFolderTree = (selectedFolderId)=>{
        let self = this;
        get(process.env.REACT_APP_SERVER_PATH+'api/pan/get_folders_tree')
        .then(retData => {
            let d = retData.data;
            if (d && d.results) {
                if(d.results.length == 0){
                    selectedFolderId = null;
                }else if(d.results.length > 0 && !selectedFolderId){
                    selectedFolderId = d.results[0].value
                }
                self.setState({
                    // selectedFolderId: selectedFolderId,
                    folderTree: d.results,
                    // expandedKeys: 
                }, ()=>self.selectFolder(selectedFolderId));
            } else {
            };
        });
    }

    onSaveFolder = ()=>{
        let self = this;
        self.setState({modalFolderLoading: true});
        let {modalParentFolderValue, modalFolderName} = this.state;
        post(process.env.REACT_APP_SERVER_PATH+'api/pan/create_folder',{
            name: modalFolderName,
            parent_folder_id: (modalParentFolderValue == 'null' ? null:parseInt(modalParentFolderValue)),
        }).then(retData => {
            self.setState({modalFolderLoading: false});
            let d = retData.data;
            if (d && d.errcode == 0) {
                message.success('文件夹已创建');
                self.setState({
                    showFolderModal: false,
                },()=>self.fetchFolderTree(d.result.id+""));
            }else if (d && d.errcode && d.errcode > 500 && d.errcode < 600) {
                message.error('创建失败，'+d.msg);
                // self.fetchFolderTree();
            } else {
                message.error('创建失败');
            };
        });
    }

    delFolder = ()=>{
        let self = this;
        const execDel = () => {
            self.setState({delFolderLoading: true});
            let {selectedFolderId} = self.state;
            patch(process.env.REACT_APP_SERVER_PATH+'api/pan/files/'+selectedFolderId,{
                status: -1,
            }).then(retData => {
                self.setState({delFolderLoading: false});
                let d = retData.data;
                if (d && d.id) {
                    message.success('文件夹已删除');
                    self.fetchFolderTree(null);
                }else{
                    message.error('操作失败');
                };
            });
        };

        Modal.confirm({
            title: '确认删除吗？',
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            cancelText: '取消',
            onOk: () => execDel(),
        });
    }

    selectFolder = value=>{
        let folderName = null;
        let findFolderName = (folders)=>{
            for(let i = 0;i<folders.length;i++){
                let item = folders[i];
                if(item.value == value){
                    folderName = item.name;
                    return [item.value];
                }else if(item.children.length > 0){
                    let results = findFolderName(item.children);
                    if(results !== false){
                        return [item.value, ...results];
                    }
                }
            }
            return false;
        };

        let expandedKeys = findFolderName(this.state.folderTree);
        this.setState({selectedFolderId: value, activeTabKey: 'files', folderName: folderName, expandedKeys: expandedKeys})
    }

    render() {
        let self = this;
        const {selectedFolderId, folderName, folderTree, activeTabKey, delFolderLoading, expandedKeys, 
            showFolderModal, modalParentFolderValue, modalFolderName, modalFolderLoading} = this.state;

        return (
            <div className="OnlineDisk">
                <div className="folderTree">
                    <div className="folderTreeBar">
                        <Button
                            type="link"
                            className="createFolderBtn"
                            icon={<FolderAddOutlined />}
                            onClick={_=>this.setState({showFolderModal: true, modalParentFolderValue: 'null', modalFolderName: ''})}
                        >新建文件夹</Button>
                    </div>
                    <Tree
                        key="folder-tree"
                        showIcon={true}
                        // autoExpandParent={true}
                        expandedKeys={expandedKeys}
                        onExpand={(keys,_)=>{
                            self.setState({expandedKeys: keys});
                            console.log(keys);
                        }}
                        icon={<FolderOpenOutlined />}
                        defaultSelectedKeys={selectedFolderId == null ? []:[selectedFolderId]}
                        selectedKeys={selectedFolderId == null ?[]:[selectedFolderId]}
                        selectable={true}
                        treeData={folderTree}
                        onSelect={selectedKeys=>{
                            if(selectedKeys.length == 0){
                                return;
                            }
                            self.selectFolder(selectedKeys[0]);
                        }}
                    />
                </div>
                <div className="folderPanel">
                    <Tabs
                        defaultActiveKey={activeTabKey}
                        className="FolderTabs"
                        activeKey={activeTabKey}
                        onChange={key=>this.setState({activeTabKey: key})}
                        tabBarExtraContent={{
                            right: (
                                <div className="tabBar">
                                    <div className="barText">{folderName}</div>
                                    <Button onClick={_=>this.delFolder()} type="link" loading={delFolderLoading} danger>删除文件夹</Button>
                                </div>
                            )
                        }}
                    >
                        <Tabs.TabPane
                            tab={
                                <span>
                                <ProfileOutlined />
                                文件
                                </span>
                            }
                            key="files"
                            className="FilesTab"
                        >
                        {
                            selectedFolderId !== null &&
                            <FolderContainer
                                key={"folder-"+selectedFolderId}
                                folder_id={selectedFolderId}
                            />
                        }
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={
                                <span>
                                <CodeOutlined />
                                脚本
                                </span>
                            }
                            key="scripts"
                            className="ScriptTab"
                        >
                        {
                            selectedFolderId !== null &&
                            <ScriptList
                                key={"scripts-"+selectedFolderId}
                                folder_id={selectedFolderId}
                            />
                        }
                        </Tabs.TabPane>
                    </Tabs>
                    {
                        // selectedFolderId === null &&
                        // <Spin spinning={true} />
                    }
                    <Modal
                        key="create-folder-modal"
                        className="createFolderModal"
                        title="新建文件夹"
                        visible={showFolderModal}
                        width={500}
                        onOk={()=>this.onSaveFolder()}
                        onCancel={()=>this.setState({showFolderModal: false})}
                        okText="确定"
                        confirmLoading={modalFolderLoading}
                    >
                        <div className="folderModalContent">
                            <TreeSelect
                                style={{ width: '220px', marginRight: '10px' }}
                                value={modalParentFolderValue}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                treeData={[{
                                    'id': null,
                                    'key': 'null',
                                    'value': 'null',
                                    'name': '根目录',
                                    'title': '根目录',
                                    'children': folderTree
                                }]}
                                placeholder="请选择目录"
                                treeDefaultExpandAll
                                onChange={v=>this.setState({modalParentFolderValue: v})}
                            />
                            /
                            <Input
                                placeholder="请填写文件夹名"
                                style={{width: '200px', marginLeft: '10px'}}
                                value={modalFolderName}
                                onChange={e=>this.setState({modalFolderName: e.target.value})}
                            />
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}
