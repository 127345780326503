import React, { useRef, useEffect, useState } from 'react';
import style from './index.module.scss';
import Wrapper from 'components/Wrapper';
import { Table } from 'antd';
import { useData } from './hooks';
import { Spin } from 'antd';
import DomResize from 'components/DomResize';

const Comp = (props) => {
    const { title, icon, timeInfo, columns, path } = props;

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(null);
    useEffect(() => {
        let nextFunc = () => { };
        if (typeof setData === 'function') nextFunc = setData;
        setDataFunc.current = nextFunc;
    }, [setData]);
    useEffect(() => {
        if (!!timeInfo) {
            setDataFunc.current(Object.assign({}, timeInfo, { path }));
        };
    }, [timeInfo, path]);

    const [table_y, set_table_y] = useState(null);
    const onResize = (conf) => {
        set_table_y(conf.height - 12 * 2 - 40);
    };

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={icon}
                title={title}
                watchDetail={false}
            >
                <div className={style['content']}>
                    <div className="resize">
                        <DomResize onResize={onResize} mountedCall={true} debounce_wait={20} />
                    </div>
                    <div className="table_wrapper">
                        {
                            typeof table_y === 'number' && (
                                <Table
                                    columns={!!columns ? columns : []}
                                    dataSource={data ?? []}
                                    pagination={false}
                                    // bordered={true}
                                    size="small"
                                    scroll={{ y: table_y }}
                                />
                            )
                        }
                    </div>
                </div>
            </Wrapper>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
