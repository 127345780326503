import React, { Component } from 'react'
import { Menu, message } from 'antd'
import { withRouter } from 'react-router-dom'
import style from './index.module.scss'
import { MenuList } from './menu'
const { SubMenu } = Menu
/** 左侧内嵌菜单 目前最多支持三级菜单 */
class LeftMenu extends Component {
  constructor(props) {
    super(props)
    const { location } = props
    this.state = {
      collapsed: true,
      menuList: MenuList,
      openKeys: [], // 当前打开的菜单key集合
      selectedKeys: [], // 当前选中的菜单key
      activeMenu: location.pathname, // 当前激活的路由
      rootSubmenuKeys: MenuList.filter(item => item.childrens && item.childrens.length > 0).map(
        sunMenu => sunMenu.key
      ) // 有子菜单的根结点key集合
    }
  }
  componentDidMount = () => {
    // 监听路由变化 重置当前激活的路由
    this.handleSelectedKeys(this.state.activeMenu)

    this.props.history.listen(({ pathname }) => {
      console.log('跳转到', pathname)
      this.handleSelectedKeys(pathname)
      this.setState({ activeMenu: pathname })
    })
  }
  /** 根据当前激活的路由高亮激活的菜单
   * 目前只追踪到三级菜单
   */
  handleSelectedKeys = pathname => {
    const { menuList } = this.state
    let key = ''
    menuList.some(stMenu => {
      if (stMenu.path === pathname) {
        key = stMenu.key
        return true
      } else if (stMenu.childrens && stMenu.childrens.length > 0) {
        stMenu.childrens.some(ndMenu => {
          if (ndMenu.path === pathname) {
            key = ndMenu.key
            return true
          } else if (ndMenu.childrens && ndMenu.childrens.length > 0) {
            ndMenu.childrens.some(rdMenu => {
              if (rdMenu.path === pathname) {
                key = rdMenu.key
                return true
              }
            })
          }
        })
      }
    })
    this.setState({ selectedKeys: key ? [key] : [] })
  }

  /** 展开/收起 菜单 */
  toggleCollapsed = () => {
    this.setState({ collapsed: !this.state.collapsed })
  }
  /** SubMenu 展开/关闭的回调 只展开当前父级菜单
   * @param keys: string[]
   */
  onOpenChange = keys => {
    const { openKeys, rootSubmenuKeys } = this.state
    const latestOpenKey = keys.find(key => !openKeys.includes(key))
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys: keys })
    } else {
      this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] })
    }
  }

  /** 路由跳转 */
  navigateToPage = (key, path) => {
    const { activeMenu } = this.state
    const { history } = this.props
    if (!path) {
      message.info('暂未开启，敬请期待')
      return
    }
    if (path !== activeMenu) {
      this.setState({ selectedKeys: [key] })
      history.push(path)
    }
  }
  render() {
    const { collapsed, menuList, openKeys, selectedKeys } = this.state

    return (
      <div className={`${style['inline-menu']} ${collapsed ? 'close-animate' : 'open-animate'} `}>
        <i className="menu-icon menu-switch" onClick={this.toggleCollapsed} />
        <Menu
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          onOpenChange={this.onOpenChange}
        >
          {menuList &&
            menuList.map(menuItem => {
              if (menuItem && menuItem.childrens && menuItem.childrens.length > 0) {
                return (
                  <SubMenu key={menuItem.key} icon={menuItem.icon} title={menuItem.title}>
                    {menuItem.childrens.map(ndMenuItem => {
                      if (ndMenuItem && ndMenuItem.childrens && ndMenuItem.childrens.length > 0) {
                        return (
                          <SubMenu key={ndMenuItem.key} title={ndMenuItem.title}>
                            {ndMenuItem.childrens.map(rdMenuItem => {
                              return (
                                <Menu.Item
                                  key={rdMenuItem.key}
                                  onClick={() =>
                                    this.navigateToPage(rdMenuItem.key, rdMenuItem.path)
                                  }
                                >
                                  {rdMenuItem.title}
                                </Menu.Item>
                              )
                            })}
                          </SubMenu>
                        )
                      } else {
                        return (
                          <Menu.Item
                            key={ndMenuItem.key}
                            onClick={() => this.navigateToPage(ndMenuItem.key, ndMenuItem.path)}
                          >
                            {ndMenuItem.title}
                          </Menu.Item>
                        )
                      }
                    })}
                  </SubMenu>
                )
              } else {
                return (
                  <Menu.Item
                    key={menuItem.key}
                    icon={menuItem.icon}
                    onClick={() => this.navigateToPage(menuItem.key, menuItem.path)}
                  >
                    {menuItem.title}
                  </Menu.Item>
                )
              }
            })}
        </Menu>
      </div>
    )
  }
}
export default withRouter(LeftMenu)
