import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Input, Modal, Space, Typography, message, Spin, Select } from 'antd';
import { post } from '../../common/utils';
import { UID_CONFIG } from './constants.js';
const { Text } = Typography;
const { Option } = Select;
const Comp = (props) => {
    const { id } = props;
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [blockType, setBlockType] = useState(null);
    useEffect(() => { }, []);
    const onCancel = () => {
        const { onCancel: propOnCancel } = props;
        if (loading) {
            message.info("创建中,请稍后！");
        } else {
            if (typeof propOnCancel === 'function') {
                propOnCancel();
            };
        };
    };
    const onOk = () => {
        if (blockType === null) {
            message.error("请选择模块类型!");
            return;
        };
        if (typeof name === 'string' && name !== "") {
            setLoading(true);
            post(process.env.REACT_APP_SERVER_PATH + `api/grid/boards`, {
                name,
                screen_id: id,
                template_uid: blockType
            })
                .then((retData) => {
                    console.log(retData);
                    setLoading(false);
                    if (!!retData.data && typeof retData.data.id === 'number') {
                        message.success("创建成功!");
                        const { onOk: propOnOk } = props;
                        propOnOk(retData.data);
                    } else {
                        message.error("创建看板失败,请重试!");
                    };
                });
        } else {
            message.error("请输入名称!");
        };
    };
    const handleChange = (v) => {
        setBlockType(v);
    };
    return (
        <Modal title="创建新布局" visible={true} onOk={onOk} onCancel={onCancel} {...loading ? { okButtonProps: { disabled: true }, cancelButtonProps: { disabled: true } } : {}}>
            <Spin spinning={loading}>
                <Space direction="vertical">
                    <Space>
                        <Text>选择模块类型</Text>
                        <Select value={blockType} style={{ width: 120 }} onChange={handleChange}>
                            {
                                UID_CONFIG
                                    .map((item, index) => {
                                        const { uid, name } = item;
                                        return (
                                            <Option value={uid} key={index}>{name}</Option>
                                        );
                                    })
                            }
                        </Select>
                    </Space>
                    <Space>
                        <Text>名称</Text>
                        <Input placeholder="请输入名称" value={name} onChange={e => setName(e.target.value)} />
                    </Space>
                </Space>
            </Spin>
        </Modal>
    );
};

export default withRouter(Comp);
