import React, { useRef, useEffect } from 'react';
import style from './index.module.scss';
import Wrapper from 'components/Wrapper';
import { useData } from './hooks';
import { Spin } from 'antd';
import ICON from './res/icon.svg';

const shifts = [
    {
        name: '早班',
        time_begin: '00:00',
        time_end: '08:00',
    },
    {
        name: '中班',
        time_begin: '08:00',
        time_end: '16:00',
    },
    {
        name: '晚班',
        time_begin: '16:00',
        time_end: '24:00',
    },
];

const Comp = (props) => {
    const { timeInfo } = props;

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(null);
    useEffect(() => {
        let nextFunc = () => { };
        if (typeof setData === 'function') nextFunc = setData;
        setDataFunc.current = nextFunc;
    }, [setData]);
    useEffect(() => {
        if (!!timeInfo) {
            setDataFunc.current(timeInfo);
        };
    }, [timeInfo]);

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={ICON}
                title="运行状态"
                watchDetail={false}
            >
                <div className={style['content']}>
                    <div className="top">
                        <div className="text_wrapper">
                            {
                                shifts
                                    .map((item, index, arr) => {
                                        const { time_begin, time_end, name } = item;
                                        return []
                                            .concat(
                                                [
                                                    (
                                                        <div className="item time" key={`${index}-1`}>
                                                            <div className="text">{time_begin}</div>
                                                        </div>
                                                    ),
                                                    (
                                                        <div className="item name" key={`${index}-2`}>
                                                            <div className="text">{name}</div>
                                                        </div>
                                                    ),
                                                ],
                                                index === arr.length - 1 ? [
                                                    (
                                                        <div className="item time" key={`${index}-3`}>
                                                            <div className="text">{time_end}</div>
                                                        </div>
                                                    ),
                                                ] : [],
                                            );
                                    })
                            }
                        </div>
                        <div className="bar_wrapper">
                            <div className="bar"></div>
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="left">
                            <div className="top">
                                <div className="title">运行时长</div>
                            </div>
                            <div className="bottom">
                                <div className="wrapper">
                                    <div className="item">
                                        <div className="color" style={{ backgroundColor: '#355B99' }}></div>
                                        <div className="name">正常运行</div>
                                        <div className="line"></div>
                                        <div className="time">20.3小时</div>
                                    </div>
                                    <div className="item">
                                        <div className="color" style={{ backgroundColor: '#F69A23' }}></div>
                                        <div className="name">正常停机</div>
                                        <div className="line"></div>
                                        <div className="time">0.0小时</div>
                                    </div>
                                    <div className="item">
                                        <div className="color" style={{ backgroundColor: '#FB6060' }}></div>
                                        <div className="name">低负荷运行</div>
                                        <div className="line"></div>
                                        <div className="time">1.3小时</div>
                                    </div>
                                    <div className="item">
                                        <div className="color" style={{ backgroundColor: '#DB0000' }}></div>
                                        <div className="name">异常停止</div>
                                        <div className="line"></div>
                                        <div className="time">2.4小时</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="top">
                                <div className="title">运行事件</div>
                            </div>
                            <div className="bottom">
                                <div className="wrapper scroll_bar_restyle">
                                    <div className="content">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="color">
                                                        <div className="bar" style={{ backgroundColor: '#FB6060' }}></div>
                                                    </td>
                                                    <td className="name">低负荷运行</td>
                                                    <td className="date">2020/03/21</td>
                                                    <td className="time">08:31-09:22</td>
                                                    <td className="action">
                                                        <div className="btn">查看</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="color">
                                                        <div className="bar" style={{ backgroundColor: '#DB0000' }}></div>
                                                    </td>
                                                    <td className="name">异常停止</td>
                                                    <td className="date">2020/03/21</td>
                                                    <td className="time">08:31-09:22</td>
                                                    <td className="action">
                                                        <div className="btn">查看</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="color">
                                                        <div className="bar" style={{ backgroundColor: '#DB0000' }}></div>
                                                    </td>
                                                    <td className="name">异常停止</td>
                                                    <td className="date">2020/03/21</td>
                                                    <td className="time">08:31-09:22</td>
                                                    <td className="action">
                                                        <div className="btn">查看</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="color">
                                                        <div className="bar" style={{ backgroundColor: '#FB6060' }}></div>
                                                    </td>
                                                    <td className="name">低负荷运行</td>
                                                    <td className="date">2020/03/21</td>
                                                    <td className="time">08:31-09:22</td>
                                                    <td className="action">
                                                        <div className="btn">查看</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="color">
                                                        <div className="bar" style={{ backgroundColor: '#FB6060' }}></div>
                                                    </td>
                                                    <td className="name">低负荷运行</td>
                                                    <td className="date">2020/03/21</td>
                                                    <td className="time">08:31-09:22</td>
                                                    <td className="action">
                                                        <div className="btn">查看</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="color">
                                                        <div className="bar" style={{ backgroundColor: '#DB0000' }}></div>
                                                    </td>
                                                    <td className="name">异常停止</td>
                                                    <td className="date">2020/03/21</td>
                                                    <td className="time">08:31-09:22</td>
                                                    <td className="action">
                                                        <div className="btn">查看</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="color">
                                                        <div className="bar" style={{ backgroundColor: '#FB6060' }}></div>
                                                    </td>
                                                    <td className="name">低负荷运行</td>
                                                    <td className="date">2020/03/21</td>
                                                    <td className="time">08:31-09:22</td>
                                                    <td className="action">
                                                        <div className="btn">查看</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="color">
                                                        <div className="bar" style={{ backgroundColor: '#DB0000' }}></div>
                                                    </td>
                                                    <td className="name">异常停止</td>
                                                    <td className="date">2020/03/21</td>
                                                    <td className="time">08:31-09:22</td>
                                                    <td className="action">
                                                        <div className="btn">查看</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="color">
                                                        <div className="bar" style={{ backgroundColor: '#FB6060' }}></div>
                                                    </td>
                                                    <td className="name">低负荷运行</td>
                                                    <td className="date">2020/03/21</td>
                                                    <td className="time">08:31-09:22</td>
                                                    <td className="action">
                                                        <div className="btn">查看</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
