import React from 'react';
import { get, post } from '../../common/utils.js';
import DeviceDynamicPic from './DeviceDynamicPic.js';
import DisplayCardGroup from './DisplayCardGroup.js';
import './index.scss';
let DEVICE_LIST = [];
let baseUrl = process.env.REACT_APP_SERVER_PATH;
if (["xinchen"].includes(process.env.REACT_APP_PROJECT_TAG)) {
    baseUrl = "https://yxsn.quantile.tech/";
};

export default class DiagramDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // diagramId: null,
            diagramData: {
                HWRatio: null,
                fontData: []
            },
            dataList: {},
            diagramConf: {},
            subTitle: null,
            selectedKeys: [],
            showExtra: false
        }
    }
    componentWillUnmount() {
        clearInterval(this.state.setIntervalId);
    }
    componentDidMount() {
        clearInterval(this.state.setIntervalId);
        let deviceId = null;
        try {
            deviceId = this.props.match.params.id;
        } catch (err) { };
        if (typeof deviceId !== 'string') {
            const { deviceId: dId } = this.props;
            if (typeof dId === 'string') {
                deviceId = dId;
            };
        };
        if (typeof deviceId === 'string') {
            this.setNewDiagram(deviceId);
        };
    }
    setNewDiagram = deviceId => {
        let self = this, diagramData = self.state.diagramData, tag_alias_list = [];
        get(process.env.REACT_APP_SERVER_PATH + 'api/diagram/get_by_uuid/' + deviceId).then(retData => {
            // console.log(retData);
            if (!!retData.data && retData.data.errcode === 0) {
                let newData = retData.data.result;
                if (!newData || newData.content === null) return;
                try {
                    let fontData = this.generateData(JSON.parse(newData.content)), HWRatio = 1;
                    if (fontData.length > 0 && fontData[0].bckSize) {
                        let bckSize = fontData[0].bckSize;
                        HWRatio = bckSize.height / bckSize.width;
                    }
                    diagramData = {
                        fontData,
                        HWRatio
                    };
                    fontData.forEach(item => {
                        if (item.tag_alias && item.tag_alias !== 'bckalias') tag_alias_list.push(item.tag_alias);
                    })
                    self.getLatestData(newData.id, tag_alias_list);
                    let setIntervalId = setInterval(() => { self.getLatestData(newData.id, tag_alias_list) }, 5000);
                    self.setState({ setIntervalId });
                } catch (error) {
                    console.log("error json");
                }
                self.setState({ diagramData, diagramConf: newData });
            } else {
                self.setState({
                    diagramData: {
                        HWRatio: null,
                        fontData: []
                    }
                })
            }
        })
    }
    generateData = source => {
        // console.log("generate data");
        let genData = [];
        genData.push({
            type: 1,
            tag_alias: "bckalias",
            value: 200,
            conditionType: 3,
            condition: "",
            coordinate: [0, 0],
            width: 100,
            srcImg: [source.bckPic, ''],
            bckSize: source.bckSize
        });
        source.listData.forEach(item => {
            item.details.forEach(detail => {
                genData.push(Object.assign(
                    {},
                    {
                        type: item.type,
                        tag_alias: item.dataPoint,
                        coordinate: [detail.top, detail.right]
                    },
                    typeof detail.precision === 'number' ? { precision: detail.precision } : {}
                ));
                if ([1, 2].includes(item.type)) {
                    genData[genData.length - 1].width = detail.size;
                    genData[genData.length - 1].srcImg = [detail.img, ''];
                    if (item.type === 1) {
                        genData[genData.length - 1].conditionType = detail.type;
                        genData[genData.length - 1].condition = detail.type === 3 ? "" : detail.condition;
                    } else {
                        genData[genData.length - 1].condition = detail.condition;
                    }
                } else if ([0, 3, 4].includes(item.type)) {
                    genData[genData.length - 1].fontSize = detail.size * 100 / source.bckSize.width;
                    genData[genData.length - 1].lineHeight = detail.lineHeight ? detail.lineHeight : 100;
                    genData[genData.length - 1].color = detail.color ? detail.color : '#000000';
                    if (item.type === 4) {
                        genData[genData.length - 1].text = detail.text;
                    }
                }
            });
        });
        return genData;
    }
    getLatestData(id, tag_name_list) {
        // if (tag_alias_list == "") return;
        let self = this;
        post(baseUrl + "api/data/get_values_before_time", {
            tag_name_list,
        }).then((retData) => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                let nextDataList = retData.data.results;
                nextDataList = Object.keys(nextDataList)
                    .reduce((pV, cKey) => {
                        return Object.assign({}, pV, {
                            [cKey]: nextDataList[cKey],
                        });
                    }, {});
                self.setState({ dataList: nextDataList });
            }
        });
    }
    deviceRouter = (deviceId) => {
        console.log(deviceId);
        let i, deviceGroupId = this.state.selectedKeys[0].split('-')[0], deviceList;
        for (i = 0; i < DEVICE_LIST.length; i++) {
            if (deviceGroupId + '' === DEVICE_LIST[i].value + '') break;
        }
        if (i === DEVICE_LIST.length) {
            alert("跳转设备不存在：id=" + deviceId);
            return;
        }
        deviceList = DEVICE_LIST[i].children
        for (i = 0; i < deviceList.length; i++) {
            if (deviceId + '' === deviceList[i].value.split('-')[1]) break;
        }
        if (i === deviceList.length) {
            alert("跳转设备不存在：id=" + deviceId);
            return;
        }
        this.setState({ subTitle: deviceList[i].label, selectedKeys: [deviceList[i].value.split('-')[0] + '-' + deviceId] })
        clearInterval(this.state.setIntervalId);
        this.setNewDiagram(deviceId);
    }
    render() {
        let self = this;

        return (
            <div style={{ width: "100%" }} className="DiagramDisplayWrapper">
                <div className="contentWrapper">
                    <div className="rightLayout">
                        <div className="picWrapper">
                            {
                                self.state.showExtra ?
                                    <DisplayCardGroup pageId={self.state.showExtra} />
                                    :
                                    <DeviceDynamicPic diagramData={self.state.diagramData} dataList={self.state.dataList} diagramConf={self.state.diagramConf} deviceRouter={self.deviceRouter} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
