import React from 'react';
import { Button, Table, Upload, message, Modal, Space, Input } from 'antd';
import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { post, get, postDownloadFile, reqDelete, patch, getToken } from 'common/utils';

export default class DiagramList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            page_size: 10,
            dataSource: [],
            visible: false,
            selectedItem: null,
            loading: false,
        };
    }

    componentDidMount() {
        this.getList();
    }

    getList = () => {
        const { page, page_size } = this.state;
        get(process.env.REACT_APP_SERVER_PATH + 'api/diagrams', {
            page,
            page_size,
        }).then(retData => {
            console.log(retData);
            if (!!retData.data && !!retData.data.results) {
                this.setState({
                    dataSource: retData.data.results.map((item, key) => Object.assign({}, item, { key }))
                });
            }
        })
    }

    createDiagram = () => {
        post(process.env.REACT_APP_SERVER_PATH + 'api/diagrams', {
            status: 0
        }).then(retData => {
            console.log(retData);
            if (retData.data && retData.data.id) {
                this.props.history.push(`/DiagramGen/${retData.data.id}`);
            }
        })
    }

    download_package = (id) => {
        postDownloadFile(process.env.REACT_APP_SERVER_PATH + 'api/diagram/download_package', {
            "diagram_id": id,
        })
    }

    delDiagram = (id) => {
        let self = this;
        const execDel = () => {
            reqDelete(process.env.REACT_APP_SERVER_PATH + 'api/diagrams/' + id).then((retData) => {
                if (retData.data) {
                    message.success("删除成功");
                    self.getList();
                } else {
                    message.error("删除失败");
                };
            });
        };

        Modal.confirm({
            title: '确认删除吗？',
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            cancelText: '取消',
            onOk: () => execDel(),
        });
    }

    changeName = (item) => {
        if (!item) return;
        let name = "";
        if (typeof item.name === 'string') {
            name = item.name;
        };
        this.setState({
            visible: true,
            selectedItem: Object.assign({}, item, { name }),
        });
    }

    handleOk = () => {
        const { selectedItem } = this.state;
        const { id } = selectedItem;
        this.setState({ loading: true });
        patch(process.env.REACT_APP_SERVER_PATH + 'api/diagrams/' + id, {
            name: selectedItem.name,
        }).then(retData => {
            this.setState({ loading: false }, () => {
                if (!!retData.data && retData.data.id === id) {
                    message.success("修改成功！");
                    this.handleCancel();
                    this.getList();
                } else {
                    message.error("修改失败!");
                };
            });
        })
    }

    handleCancel = () => {
        const { loading } = this.state;
        if (!!loading) return;
        this.setState({
            visible: false,
            selectedItem: null,
        });
    }

    nameOnChange = (e) => {
        if (typeof e.target.value === 'string') {
            const { selectedItem } = this.state;
            this.setState({
                selectedItem: Object.assign({}, selectedItem, {
                    name: e.target.value,
                })
            });
        } else {
            console.log("not string", e.target.value);
        };
    }

    render() {
        let self = this;
        const { dataSource, visible, selectedItem, loading } = this.state;

        return (
            <div className="diagram_list_wrapper" style={{ padding: 24 }}>
                <div className="header" style={{ paddingBottom: 24, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="primary" style={{ marginRight: 14 }} onClick={this.createDiagram}>新建</Button>
                    <Upload
                        name='file'
                        action={process.env.REACT_APP_SERVER_PATH + "api/diagram/upload_package"}
                        // data={{
                        //     folder_id: folder_id
                        // }}
                        accept=".zip"
                        headers={{
                            authorization: getToken(),
                        }}
                        showUploadList={false}
                        onChange={(info) => {
                            const { status } = info.file;
                            if (status !== 'uploading') {
                                console.log(info.file, info.fileList);
                            }
                            if (status === 'done') {
                                message.success("文件已上传");
                                self.getList();
                            } else if (status === 'error') {
                                message.error("上传失败");
                                message.error(info.file.response['msg']);
                            }
                        }}
                    >
                        <Button className='uploadBtn' style={{ marginRight: 0 }} icon={<UploadOutlined />} type="default">上传备份</Button>
                    </Upload>
                </div>
                <Table
                    dataSource={dataSource}
                    // pagination={{
                    //     defaultCurrent: 1,
                    //     pageSize: 10,
                    //     total: this.state.total,
                    //     onChange: (page, pageSize) => self.getAlarmList(page, pageSize)
                    // }}
                    columns={[
                        {
                            title: '编号',
                            dataIndex: 'id',
                            width: 120,
                        },
                        {
                            title: '名称',
                            dataIndex: 'name',
                        },
                        // {
                        //     title: '状态',
                        //     dataIndex: 'status'
                        // },
                        // {
                        //     title: '顺序',
                        //     dataIndex: 'index'
                        // },
                        {
                            title: 'UUID',
                            dataIndex: 'uuid',
                            width: 220,
                            render: text => <span style={{ fontSize: '12px', background: '#ddd', padding: '3px', whiteSpace: "nowrap" }}>{text}</span>
                        },
                        {
                            title: '操作',
                            key: 'action',
                            width: 380,
                            render: (text, record) => {
                                const { id, uuid } = record;
                                return ([
                                    <Button key={1} type="link" onClick={() => this.props.history.push(`/DiagramDisplay/${uuid}`)}>查看</Button>,
                                    <Button key={2} type="link" onClick={() => this.props.history.push(`/DiagramGen/${id}`)}>编辑</Button>,
                                    <Button key={3} type="link" danger onClick={() => this.delDiagram(id)}>删除</Button>,
                                    <Button key={4} type="link" onClick={() => this.download_package(id)}>下载</Button>,
                                    <Button key={5} type="link" onClick={() => this.changeName(record)}>修改名称</Button>
                                ]);
                            }
                        },
                    ]}
                />
                {
                    !!selectedItem && visible && (
                        <Modal
                            title="更改名称" visible={true} onOk={this.handleOk} onCancel={this.handleCancel}
                            okText="确认保存"
                            confirmLoading={loading}
                        >
                            <Space>
                                <div>名称</div>
                                <Input value={selectedItem.name} style={{ width: 200 }} placeholder="请输入名称" onChange={this.nameOnChange} />
                            </Space>
                        </Modal>
                    )
                }
            </div>
        );
    }
}
