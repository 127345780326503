import React from 'react';
import { get } from 'common/utils';
import moment from 'moment';
import { Button, DatePicker, Table, Select } from 'antd';
import style from './Content.module.scss';
import DetailModal from './DetailModal.js';

const { Option } = Select;

const ALARM_LEVELS = [
    {
        level: 1,
        name: '一级告警',
    }, {
        level: 2,
        name: '二级告警',
    }, {
        level: 3,
        name: '三级告警',
    }
]

export default class Content extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            systems: [],
            selectAlarm: null,
            volumeOff: true,
        };
    }

    componentDidMount() {
        this.getSystems();
    }

    getSystems = () => {
        get(process.env.REACT_APP_CEMENT_SERVER_PATH + 'api/cmt_system/list').then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                this.setState({ systems: retData.data.results });
            }
        });
    }

    render() {
        const { modalVisible, selectAlarm } = this.state;
        const { dataSource, filterSysId, filterLevel, dayBegin, dayEnd, total } = this.props;
        const { sysIdOnchange = () => { }, levelOnchange = () => { }, rangeOnchange = () => { }, getAlarmList = () => { }, pageOnchange = () => { } } = this.props;

        return (
            <div className={style["alarm_page_content_wrapper"]}>
                <div className="content_wrapper">
                    <div className="header">
                        <Select
                            defaultValue="all"
                            value={filterSysId}
                            style={{ width: 180 }}
                            onChange={sysIdOnchange}
                        >
                            <Option value='all'>全部系统</Option>
                            {
                                this.state.systems.map(({ id, name }) =>
                                    <Option value={id + ''} key={id}>{name}</Option>)
                            }
                        </Select>
                        <Select
                            style={{ width: 144, marginLeft: 24 }}
                            defaultValue="all"
                            value={filterLevel}
                            onChange={levelOnchange}
                        >
                            <Option value='all'>全部告警</Option>
                            {
                                ALARM_LEVELS.map(({ level, name }) =>
                                    <Option value={level} key={level}>{name}</Option>
                                )
                            }
                        </Select>
                        <span style={{ marginLeft: 20 }}>告警时间：</span>
                        <DatePicker.RangePicker
                            value={[dayBegin, dayEnd]}
                            onChange={rangeOnchange}
                            disabledDate={current => current > moment().endOf('day')}
                            style={{ width: 240 }}
                        />
                        <div className="btn" onClick={getAlarmList}>
                            <div className="text">查询</div>
                        </div>
                    </div>

                    <Table
                        dataSource={dataSource}
                        pagination={{
                            defaultCurrent: 1,
                            pageSize: 10,
                            total: total,
                            onChange: pageOnchange
                        }}
                        rowClassName={({ time_end }, index) => time_end ? '' : 'not_end_alarm'}
                        columns={[
                            {
                                title: () => {
                                    return (
                                        <div style={{
                                            color: '#357CF7',
                                            fontSize: 16,
                                        }}>点位名称</div>
                                    )
                                },
                                key: 'title',
                                render: (text, { alarm_meta: { data_meta: { name } }, time_end }, index) => (
                                    <div className={time_end ? '' : 'not_end_title'}>
                                        <span>{name}</span>
                                    </div>
                                )
                            },
                            {
                                title: '所属系统',
                                key: 'system',
                                render: (text, { alarm_meta: { sys_name } }) => sys_name
                            },
                            {
                                title: '告警类型',
                                dataIndex: 'type',
                                render: (text) => {
                                    if (text === 1) {
                                        return '超上限告警';
                                    } else if (text === 2) {
                                        return '超下限告警';
                                    } else if (text === 3) {
                                        return '波动告警';
                                    }
                                },
                                align: 'center',
                            },
                            {
                                title: '最大波动值',
                                dataIndex: 'maxRange',
                                align: 'center',
                            },
                            {
                                title: '波动范围',
                                dataIndex: 'range',
                                align: 'center',
                            },
                            {
                                title: '告警起止时间',
                                key: 'alarmTime',
                                render: (text, { time_begin, time_end }, index) => (
                                    <span>{time_begin}<br />{time_end || '—— ——'}</span>
                                ),
                                align: 'center',
                            },
                            {
                                title: '持续时长',
                                dataIndex: 'duration',
                                align: 'center',
                            },
                            {
                                title: '警告级别',
                                key: 'level',
                                render: (text, record) => {
                                    return ALARM_LEVELS.filter(item => item.level === record.alarm_meta.level)[0].name
                                },
                                align: 'center',
                            },
                            {
                                title: '操作',
                                key: 'action',
                                render: (text, record, index) => (
                                    <Button type="link" onClick={() => this.setState({ selectAlarm: record, modalVisible: true })} style={{ color: '#357CF7' }}>查看</Button>
                                ),
                                align: 'center',
                            },
                        ]}
                    />
                    {
                        modalVisible && (
                            <DetailModal
                                data={selectAlarm}
                                onCancel={() => this.setState({ selectAlarm: null, modalVisible: false })}
                            />
                        )
                    }
                </div>
            </div>
        );
    }
}
