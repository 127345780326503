import React from 'react';
import { useEffect, useRef } from 'react';
import style from './index.module.scss';
import echarts from 'echarts';

const Comp = (props) => {
    // const [canvasSize, setCanvasSize] = useState(null);
    useEffect(() => {
        renderChart();
    }, []);
    const chartNode = useRef(null);
    const renderChart = () => {
        if (!!chartNode.current) {
            // render chart
            let instance = echarts.getInstanceByDom(chartNode.current);
            if (!instance) {
                instance = echarts.init(chartNode.current);
            };
            const yData = new Array(10)
                .fill(0)
                .map((v) => Math.floor(Math.random() * 100));
            const xData = new Array(10)
                .fill(0)
                .map((v) => Math.floor(Math.random() * 30));

            instance.setOption({
                grid: {
                    // left: 3,
                    // top: 0,
                    // right: 3,
                    // bottom: 0,
                },
                xAxis: {
                    type: 'category',
                    data: xData,
                },
                yAxis: {
                    type: 'value',
                    scale: true,// true时不一定显示0
                },
                series: [
                    {
                        data: yData,
                        type: 'bar',
                        itemStyle: {
                            color: 'rgba(64, 161, 225, 1)',
                            // barBorderRadius: 2,
                        },
                    },
                ],
            });
        };
    };
    return (
        <div className={style['wrapper']}>
            <div ref={chartNode} style={{ height: 200 }} />
        </div>
    );
};

export default Comp;
