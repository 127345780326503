
const group_I_tags = {
    tag_1: '_H',
    tag_2: '_HDQOQ',
};

const group_I = {
    name: '实时能源消耗',
    children: [
        {
            name: '用电功率',
            basic_tag: 'PLANT_ALL_ELEC_RATE',
            unit: 'kWh',
            ...group_I_tags,
        },
        {
            name: '自耗气流量',
            basic_tag: 'PLANT_FUEL_FG_RATE',
            unit: 'Nm3/h',
            ...group_I_tags,
        },
        {
            name: '耗水流量',
            basic_tag: 'PLANT_COOL_H2O_RATE',
            unit: 't/h',
            ...group_I_tags,
        },
        {
            name: '氮气流量',
            basic_tag: 'PLANT_N2GEN_N2_RATE',
            unit: 'Nm3/h',
            ...group_I_tags,
        },
    ],
};

export {
    group_I,
};
