import React from 'react'
import './index.scss'
const EnergyTotal = (props) => {
    const {
        feedGasTotalWeek,
        feedGasTotalMonth,
        feedGasTotalYear,
        electricTotalWeek,
        electricTotalMonth,
        electricTotalYear,
        waterTotalWeek,
        waterTotalMonth,
        waterTotalYear,
        nitrogenTotalWeek,
        nitrogenTotalMonth,
        nitrogenTotalYear
    } = props
    return (
        <div className="energyTotal">
            <div className="energyTotal-title">能耗汇总</div>
            <div className="energyTotal-list">
                <div className="energyTotal-list-item" style={{marginRight: 16}}>
                    <div className="item-top">
                        <span className="item-top-name">自耗气累计消耗</span>
                        <span className="item-top-type">万Nm3</span>
                    </div>
                    <div className="item-center">
                        <span className="item-center-week">周</span>
                        <span className="item-center-number">{feedGasTotalWeek}</span>
                    </div>
                    <div className="item-bottom">
                        <span className="item-bottom-number" style={{marginRight: 4}}>{feedGasTotalMonth}</span>
                        <span className="item-bottom-time" style={{marginRight: 16}}>月</span>
                        <span className="item-bottom-number" style={{marginRight: 4}}>{feedGasTotalYear}</span>
                        <span className="item-bottom-time">年</span>
                    </div>
                </div>
                <div className="energyTotal-list-item">
                    <div className="item-top">
                        <span className="item-top-name">电累计消耗</span>
                        <span className="item-top-type">万kWh</span>
                    </div>
                    <div className="item-center">
                        <span className="item-center-week">周</span>
                        <span className="item-center-number">{electricTotalWeek}</span>
                    </div>
                    <div className="item-bottom">
                        <span className="item-bottom-number" style={{marginRight: 4}}>{electricTotalMonth}</span>
                        <span className="item-bottom-time" style={{marginRight: 16}}>月</span>
                        <span className="item-bottom-number" style={{marginRight: 4}}>{electricTotalYear}</span>
                        <span className="item-bottom-time">年</span>
                    </div>
                </div>
            </div>


            <div className="energyTotal-list">
                <div className="energyTotal-list-item" style={{marginRight: 16}}>
                    <div className="item-top">
                        <span className="item-top-name">水累计消耗</span>
                        <span className="item-top-type">t</span>
                    </div>
                    <div className="item-center">
                        <span className="item-center-week">周</span>
                        <span className="item-center-number">{waterTotalWeek}</span>
                    </div>
                    <div className="item-bottom" style={{paddingLeft: 20}}>
                        <span className="item-bottom-number" style={{marginRight: 4}}>{waterTotalMonth}</span>
                        <span className="item-bottom-time" style={{marginRight: 16}}>月</span>
                        <span className="item-bottom-number" style={{marginRight: 4}}>{waterTotalYear}</span>
                        <span className="item-bottom-time">年</span>
                    </div>
                </div>
                <div className="energyTotal-list-item">
                    <div className="item-top">
                        <span className="item-top-name">氮气累计消耗</span>
                        <span className="item-top-type">万Nm3</span>
                    </div>
                    <div className="item-center">
                        <span className="item-center-week">周</span>
                        <span className="item-center-number">{nitrogenTotalWeek}</span>
                    </div>
                    <div className="item-bottom">
                        <span className="item-bottom-number" style={{marginRight: 4}}>{nitrogenTotalMonth}</span>
                        <span className="item-bottom-time" style={{marginRight: 16}}>月</span>
                        <span className="item-bottom-number" style={{marginRight: 4}}>{nitrogenTotalYear}</span>
                        <span className="item-bottom-time">年</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EnergyTotal
