import React from 'react';
import { Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import style from './ExamInfoBar.module.scss';

export default class ExamInfoBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }
    render() {
        const { examDetail } = this.props;
        // console.log('in examInfoBar', examDetail);
        let name = ''
        let first_time_begin = ''
        let last_time_end = ''
        let rule_name = ''
        let finish_time = ''
        if (examDetail) {
            name = examDetail.name
            first_time_begin = examDetail.first_time_begin
            last_time_end = examDetail.last_time_end
            rule_name = examDetail.rule_name
            finish_time = examDetail.finish_time
        }
        // console.log(name)

        return (
            <div className={style["exam_analysis_exam_info_bar_wrapper"]}>
                <div className="guide">
                    <span className='mySpan'><strong>&bull;</strong> 当前竞赛: &emsp;<strong>{name}</strong> </span>
                    <span className='mySpan'><strong>&bull;</strong> 竞赛规则: &emsp;<strong>{rule_name}</strong></span>
                    <span className='mySpan'><strong>&bull;</strong> 起止时间: &emsp;<strong>{first_time_begin} 至 {last_time_end}</strong></span>
                    <span className='mySpan'><strong>&bull;</strong> 记分截止时间: &emsp;<strong>{finish_time}</strong></span>
                    <Button
                        className="cus_btn" type='primary' icon={<SyncOutlined />} onClick={() => { window.location.reload() }}
                        style={{
                            backgroundColor: '#357CF7',
                            borderColor: '#357CF7',
                        }}
                    >刷新</Button>
                </div>
            </div>
        );
    }
}
