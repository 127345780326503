import React from 'react';
import { Button, List, Select, Dropdown, Menu } from 'antd';
import './RightWrapperPart.scss';
import { PlusCircleOutlined, DownOutlined } from '@ant-design/icons';
const Option = Select.Option;
const PIC_SERVER_PATH = process.env.REACT_APP_SERVER_PATH + 'api/img/download/';

export default class RightWrapperPart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    getDataPointVal() {
        let spd = this.props.data, nS = spd.nowSelected;
        if (nS === null) return undefined;
        if (spd.listData[nS]) {
            if (spd.listData[nS].dataPoint === null) return undefined;
            let dP = spd.listData[nS].dataPoint, i, len = spd.dataPoint.length;
            for (i = 0; i < len; i++) {
                if (dP + '' === spd.dataPoint[i].value + '') return dP.toString();
            }
            //没有对应的说明列表项value与数据点value不对应，有冲突
        }
        // alert("内部错误：列表项不存在！");
        return undefined;
    }
    dataPointOnChange(v) {
        let spd = this.props.data, listData = spd.listData, NS = listData[spd.nowSelected];
        NS.dataPoint = v;
        // PC端，修改数据点时，里面的内容不清掉
        // NS.detailItemNowSelected = null;
        // NS.type === 1 && (NS.details = []);
        this.props.componentSetState({ listData });
    }
    detailTypeOnChange = v => {
        let spd = this.props.data, listData = spd.listData, detailIndex = listData[spd.nowSelected].detailItemNowSelected, CD = listData[spd.nowSelected].details[detailIndex];
        //CD: current detail item
        delete CD.condition;
        CD.type = parseInt(v);
        this.props.componentSetState({ listData });
    }
    detailItemOnClick(index) {
        let spd = this.props.data, listData = spd.listData;
        listData[spd.nowSelected].detailItemNowSelected = index;
        this.props.componentSetState({ listData });
    }
    addDetailOnClick() {
        let spd = this.props.data, listData = spd.listData;
        listData[spd.nowSelected].details.push({
            top: 10,
            right: 10,
            size: 10,
            type: 3
        });
        this.props.componentSetState({ listData });
    }
    itemInputOnChange = (v, item) => {
        let spd = this.props.data, listData = spd.listData
        console.log(v);
        if (parseInt(item.type) === 1) {
            let tmpV = parseFloat(v);
            if (isNaN(tmpV)) {
                delete item.condition;
            } else {
                item.condition = tmpV;
            }
        } else if (item.hasOwnProperty('text')) {
            item.text = v;
        } else { item.condition = v; }
        this.props.componentSetState({ listData });
    }
    exchangeTwoItem(obj, InA, InB) {
        let tmp = obj[InA];
        obj[InA] = obj[InB];
        obj[InB] = tmp;
    }
    itemManipulation = (sign, index) => {
        if (![0, 1, 2].includes(sign)) return;
        console.log(sign, index);
        let spd = this.props.data, NS, listData = spd.listData;
        if (spd.nowSelected !== null && spd.listData[spd.nowSelected]) {
            NS = spd.listData[spd.nowSelected];
            let newIndex;
            if (sign === 2) {//delete
                newIndex = null;
                NS.details.splice(index, 1);
            } else {
                if (sign === 0) {
                    newIndex = index > 0 ? index - 1 : 0;
                } else {//sign===1
                    newIndex = index === NS.details.length - 1 ? index : index + 1;
                }
                this.exchangeTwoItem(NS.details, newIndex, index);
            }
            NS.detailItemNowSelected = newIndex;
            this.props.componentSetState({ listData });
        }
    }
    render() {
        let self = this, spd = self.props.data,
            menu = (index) => (
                <Menu>
                    <Menu.Item>
                        <Button type="link" onClick={() => this.itemManipulation(0, index)}>向上</Button>
                    </Menu.Item>
                    <Menu.Item>
                        <Button type="link" onClick={() => this.itemManipulation(1, index)}>向下</Button>
                    </Menu.Item>
                    <Menu.Item>
                        <Button type="link" onClick={() => this.itemManipulation(2, index)}>删除</Button>
                    </Menu.Item>
                </Menu>
            ),
            detailItemContent = (item, index) => (
                <div className="itemWrapper">
                    <div className="typeWrapper">
                        <input type="text" value={item.hasOwnProperty('condition') ? item.condition.toString() : (item.hasOwnProperty('text') ? item.text.toString() : "")} disabled={item.type === 3} onChange={e => self.itemInputOnChange(e.target.value, item)} />
                        <Select style={{ width: 100 }} onChange={self.detailTypeOnChange} value={item.type ? item.type.toString() : undefined} placeholder="选择类型" disabled={![1, 2, 3].includes(item.type)}>
                            <Option value="1">简单值</Option>
                            <Option value="2">表达式</Option>
                            <Option value="3">默认图</Option>
                        </Select>
                    </div>
                    {
                        !item.hasOwnProperty('text')
                        &&
                        <div className="imgWrapper">
                            <img src={item.img ? PIC_SERVER_PATH + item.img : undefined} alt="" />
                            <input className="inputFile" type="file" accept="image/*" onChange={() => { self.props.onFilesChange("detailInputFile" + index); }} id={"detailInputFile" + index} />
                            <label htmlFor={"detailInputFile" + index} className="inputLabel">上传</label>
                            <div className="flexGrow"></div>
                            <div onClick={e => { e.stopPropagation() }}>
                                <Dropdown overlay={menu(index)} disabled={![1, 2, 3].includes(item.type)}>
                                    <span className="ant-dropdown-link">操作 <DownOutlined /></span>
                                </Dropdown>
                            </div>
                        </div>
                    }
                </div>
            ),
            ifNowSelected = () => {
                if (spd.nowSelected === null) return false;
                if (spd.listData[spd.nowSelected]) return true;
                return false;
            }, NS = ifNowSelected() ? spd.listData[spd.nowSelected] : null;
        return (
            <div className="RightWrapperPart">
                {
                    NS !== null && [
                        (<div className="bindData" key="bindData">
                            <Select
                                className="dataPointWrapper"
                                disabled={![0, 1, 3].includes(NS.type)}
                                showSearch
                                value={self.getDataPointVal()}
                                style={{ width: 250 }}
                                placeholder="请选择数据点"
                                optionFilterProp="children"
                                onChange={v => { self.dataPointOnChange(v); }}
                                filterOption={(input, option) => {
                                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}
                            >
                                {
                                    spd.dataPoint.map((item, index) => (
                                        <Option key={item.value}>{`${item.name} ${item.value}`}</Option>
                                    ))
                                }
                            </Select>
                        </div>),
                        (<div className="listWrapper" key="listWrapper">
                            {
                                (((NS.dataPoint !== null) && (NS.type === 1)) || (NS.type === 2) || (NS.type === 4)) &&
                                <List
                                    itemLayout="horizontal"
                                    dataSource={NS.details}
                                    renderItem={(item, index) => (
                                        <List.Item className={"ListItem" + (NS.detailItemNowSelected === index ? " selected" : "")} onClick={() => { self.detailItemOnClick(index); }}>
                                            {detailItemContent(item, index)}
                                        </List.Item>
                                    )}
                                    footer={<Button className="AddButton" type="primary" icon={<PlusCircleOutlined />} onClick={() => self.addDetailOnClick()} disabled={NS.type === 2 || NS.type === 4}>添加</Button>}
                                />
                            }
                        </div>)
                    ]
                }
            </div>
        )
    }
}