import React from 'react';
import style from './index.module.scss';
import Wrapper from 'components/Wrapper';
import ICON from './res/icon.svg';
import List from '../components/List';

const Comp = (props) => {

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={ICON}
                title="最近报警列表"
                watchDetail={false}
            >
                <div className={style["content"]}>
                    <List />
                </div>
            </Wrapper>
        </div>
    );
};

export default Comp;
