import React, { useEffect, useState, useContext, useMemo } from 'react'
import style from './index.module.scss'
import ICON_EVENT from './res/event_icon.svg'
import ICON_EVENT_TEXT from './res/event_text.svg'
import EventBar from './EventBar'
import TimeLine from './TimeLine'
import moment from 'moment'
import { TIME_RANGE_HOURS } from './utils'
import Dialog from './Dialog'

import FilterCapsuleModal from './filterCapsuleModal'
import CommonContext from '../context'
import { get, getCachedUserInfo } from 'common/utils'
const baseUrl = process.env.REACT_APP_SERVER_PATH
const REFRESH_TIMER = 3 * 60 * 1000 // 3 minute刷新一次new标记个数

const Comp = props => {
  const [timeInfo, setTimeInfo] = useState(null)
  const [timeRange, setTimeRange] = useState({})
  const [capsules, setCapsules] = useState([])
  const [newEventCount, setNewEventCount] = useState(0) // 新建胶囊数量
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [refreshNum, addRefreshNum] = useState(0) // 用于触发胶囊面板刷新计数
  const [readedUpdateId, setReadedUpdateId] = useState('') // 用于触发胶囊面板移除new标记
  const commonInfo = useContext(CommonContext)
  const commonRefresh = useMemo(() => {
    if (!!commonInfo) {
      return commonInfo.refresh
    }
    return null
  }, [commonInfo])

  useEffect(() => {
    setTimeInfo({ isCurrent: true, endTime: moment().add(30, 'seconds') }) // add 30 seconds to fix bug: hard code
    getNewEventCount()
    const newEventCountInterval = setInterval(() => {
      getNewEventCount()
      addRefreshNum(refreshNum + 1)
    }, REFRESH_TIMER)
    return () => {
      sessionStorage.removeItem('filterParams')
      clearInterval(newEventCountInterval)
    }
  }, [])

  const event = useMemo(() => {
    if (!!commonInfo && !!commonInfo.event) {
      return commonInfo.event
    }
    return null
  }, [commonInfo])

  useEffect(() => {
    if (!!event && !showFilterModal) {
      // 目前不区分type，只看id
      const { id } = event
      if (typeof id === 'number') {
        // 请求这个id的胶囊，并跳转到这个胶囊时间的endTime
        get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/singleCapsuleSearch', {
          capsuleId: id
        }).then(retData => {
          // console.log(retData);
          if (!!retData.data && retData.data.error === 0) {
            const { occur_time } = retData.data.results
            setTimeInfo({ isCurrent: false, endTime: moment(occur_time) })
          }
        })
      }
      getNewEventCount()
    }
  }, [event])

  /** 向上/向下推8小时 */
  const timeChangeOnClick = addon => {
    const nextEndTime = moment(timeInfo.endTime).add(addon * TIME_RANGE_HOURS, 'hours')
    let nextTimeInfo = { isCurrent: false, endTime: nextEndTime }
    const localCurrentTime = moment()
    if (moment(nextEndTime).isAfter(localCurrentTime)) {
      nextTimeInfo = { isCurrent: true, endTime: localCurrentTime }
    }
    setTimeInfo(nextTimeInfo)
  }

  /** 编辑胶囊 */
  const editEventOnClick = (id, isEdit) => {
    if (typeof props.editEventOnClick === 'function') props.editEventOnClick(id, isEdit)
  }
  /** 跳转至当前时刻-点击时间轴的“当前” */
  const setToCurrent = () => {
    setTimeInfo({ isCurrent: true, endTime: moment() })
  }

  /** 滚动胶囊看板 更新 */
  const changeTimeRange = range => {
    setCapsules(range.sourceData)
    setTimeRange({ start: range.start, end: range.end })
  }
  // 跳转至指定时刻
  const toggleTime = time => {
    setTimeInfo({ isCurrent: false, endTime: moment(time) })
  }

  /** 获取近2小时内的新增胶囊数量 */
  const getNewEventCount = () => {
    get(baseUrl + 'api/v1/event/capsule/capsuleByTimeSearch', {
      startDate: moment().subtract(2, 'hours').format('YYYY-MM-DD HH:mm:ss'),
      stopDate: moment().format('YYYY-MM-DD HH:mm:ss'),
      useId: 1,
      onlyNewNum: true
    }).then(countData => {
      if (countData.data && countData.data.error === 0) {
        setNewEventCount(countData.data.results || 0)
      }
    })
  }

  /** 关闭筛选窗口 */
  const closeFilterModal = () => {
    setShowFilterModal(false)
  }

  /** 关闭筛选窗口并跳转至指定时间 */
  const navigateToEvent = eventItem => {
    setShowFilterModal(false)
    setTimeInfo({ isCurrent: false, endTime: moment(eventItem.occur_time) })
  }

  /** 从筛选窗口点击带有new标记的胶囊，刷新胶囊面板new标记 */
  const refreshDialogNewNum = id => {
    getNewEventCount()
    setReadedUpdateId(id)
  }

  return (
    <div className={style['wrapper']}>
      <div className="icons_wrapper">
        <img alt="" src={ICON_EVENT} className="icon" />
        <img alt="" src={ICON_EVENT_TEXT} className="icon_text" />
        <div className="filter-btn" onClick={() => setShowFilterModal(true)}>
          筛选
        </div>
      </div>
      <div className="event_bar_comp_wrapper">
        <EventBar editEventOnClick={editEventOnClick} />
      </div>
      {!!timeInfo && (
        <div className="content_wrapper">
          <div className="left">
            <TimeLine
              isCurrent={timeInfo.isCurrent}
              endTime={timeInfo.endTime}
              newEventCount={newEventCount}
              timeChangeOnClick={timeChangeOnClick}
              setToCurrent={setToCurrent}
              timeRange={timeRange}
              capsules={capsules}
              toggleTime={toggleTime}
            />
          </div>
          <div className="right">
            <Dialog
              isCurrent={timeInfo.isCurrent}
              endTime={timeInfo.endTime}
              newEventCount={newEventCount}
              refreshNum={refreshNum}
              readedUpdateId={readedUpdateId}
              editEventOnClick={editEventOnClick}
              changeTimeRange={changeTimeRange}
              refreshNewNum={getNewEventCount}
            />
          </div>
        </div>
      )}
      {showFilterModal && (
        <FilterCapsuleModal
          closeFilterModal={closeFilterModal}
          editEventOnClick={editEventOnClick}
          navigateToEvent={navigateToEvent}
          refreshNewNum={refreshDialogNewNum}
        />
      )}
    </div>
  )
}

export default Comp
