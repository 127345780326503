import React, { useEffect, useMemo, useState } from 'react';
import Chart from './index.js';
import moment from 'moment';
import { post } from '../../common/utils.js';

const Comp = (props) => {
    const { layoutChange, config, range } = props;
    const [lineData, setLineData] = useState(null);
    const { point, lineColor, title, tag_name } = useMemo(() => {
        return !!config ? {
            point: typeof config.point !== "undefined" ? config.point : undefined,
            lineColor: !!config.color ? config.color : undefined,
            title: !!config.title ? config.title : undefined,
            tag_name: !!config.tag_name ? config.tag_name : undefined,
        } : {};
    }, [config]);
    useEffect(() => {
        // console.log(tag_name, range);
        if (!!tag_name) {
            const time_end = moment(!!range ? range[1] : "2020-12-01 15:00:00");
            const time_begin = moment(time_end).subtract(1, 'hours');
            post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data_sep_time', {
                time_type: 'seconds',
                tag_list: [{
                    tag_name,
                    time_begin: moment(time_begin).format('YYYY-MM-DD HH:mm:00'),
                    time_end: moment(time_end).format('YYYY-MM-DD HH:mm:00'),
                    interval: 60,
                }],
            })
                .then(retData => {
                    // console.log(retData);
                    if (retData.data && retData.data.errcode === 0) {
                        if (retData.data.results.length > 0) {
                            setLineData(retData.data.results[0].data_list);
                        };
                    };
                });
        };
    }, [tag_name, range]);
    return (
        <Chart
            layoutChange={layoutChange}
            {
            ...point !== undefined ? {
                point,
            } : {}
            }
            {
            ...lineColor !== undefined ? {
                lineColor,
            } : {}
            }
            {
            ...title !== undefined ? {
                title,
            } : {}
            }
            {
            ...!!lineData ? {
                lineData,
            } : {}
            }
        />
    );
};

export default Comp;
