let nameList = [['当前值', 'HH设定', 'H设定', 'L设定', 'LL设定'],
['HH', 'H', 'L', 'LL'],
['启动', '停止'],
['HH', 'LL'],
['酸启动', '酸停止', '碱启动', '碱停止', 'HH', 'LL']],
    templateItems = (type, tagAliasList, unit, nameList, like0) => {
        let retItems = [];
        parseInt(type) === 0 && tagAliasList.forEach((item, index) => {
            retItems.push({
                type: (parseInt(index) === 0 && !like0) ? 0 : 3,
                name: nameList[index],
                value: 0,
                tagAlias: item,
                unit: typeof unit == "string" ? unit : unit[index],
                bckColor: (parseInt(index) === 0 && !like0) ? "#FDD0A9" : "#D0F2F4"
            })
        })
        parseInt(type) === 1 && retItems.push({
            type: 0,
            name: "日累积量",
            value: 0,
            tagAlias: tagAliasList[0],
            unit: typeof unit == "string" ? unit : unit[0],
            bckColor: "#FDD0A9"
        })
        return retItems;
    };
const conf = {
    1: [
        {
            type: 0,
            width: 214,
            title: "含银批次反应槽Ag",
            deviceId: 1,
            // subtitle: "V01液位设定(mm)",
            items: templateItems(0, ["A1_102_AGIV_A0121", "A1_102_SVHH_A0124", "A1_102_SVH_A0125", "A1_102_SVL_A0126", "A1_102_SVLL_A0127"], "mg/L", nameList[0])
        },
        {
            type: 0,
            width: 214,
            title: "脱胶废水均质池COD",
            deviceId: 5,
            items: templateItems(0, ["A5_117_CODIV_A0831", "A5_117_SVHH_A0834", "A5_117_SVH_A0835", "A5_117_SVL_A0836", "A5_117_SVLL_A0837"], "mg/L", nameList[0])
        },
        {
            type: 0,
            width: 214,
            title: "脱胶废水均质池TN",
            deviceId: 5,
            items: templateItems(0, ["A5_117_TNIV_A0851", "A5_117_SVHH_A0854", "A5_117_SVH_A0855", "A5_117_SVL_A0856", "A5_117_SVLL_A0857"], "mg/L", nameList[0])
        },
        {
            type: 0,
            width: 214,
            title: "脱胶废水均质池EC",
            deviceId: 5,
            items: templateItems(0, ["A5_117_ECIV_A0841", "A5_117_SVHH_A0844", "A5_117_SVH_A0845", "A5_117_SVL_A0846", "A5_117_SVLL_A0847"], "mg/L", nameList[0])
        },
        {
            type: 0,
            width: 214,
            title: "脱胶废水缺氧池ML",
            deviceId: 5,
            items: templateItems(0, ["A5_122_MLIV_A1021", "A5_122_SVHH_A1024", "A5_122_SVH_A1025", "A5_122_SVL_A1026", "A5_122_SVLL_A1027"], "mg/L", nameList[0])
        },
        {
            type: 0,
            width: 214,
            title: "脱胶废水好氧池DO",
            deviceId: 5,
            items: templateItems(0, ["A5_123_DOIV_A1061", "A5_123_SVHH_A1024", "A5_123_SVH_A1025", "A5_123_SVL_A1026", "A5_123_SVLL_A1027"], "mg/L", nameList[0])
        },
        {
            type: 0,
            width: 214,
            title: "脱胶中间水池EC",
            deviceId: 6,
            items: templateItems(0, ["A6_125_ECIV_A1191", "A6_125_SVHH_A1194", "A6_125_SVH_A1195", "A6_125_SVL_A1196", "A6_125_SVLL_A1197"], "ms/cm", nameList[0])
        },
        {
            type: 0,
            width: 214,
            title: "脱胶中间水池COD",
            deviceId: 6,
            items: templateItems(0, ["A6_125_CODIV_A1201", "A6_125_SVHH_A1204", "A6_125_SVH_A1205", "A6_125_SVL_A1206", "A6_125_SVLL_A1207"], "mg/L", nameList[0])
        },
        {
            type: 0,
            width: 214,
            title: "脱胶中间水池TN",
            deviceId: 6,
            items: templateItems(0, ["A6_125_NIV_A1211", "A6_125_SVHH_A1214", "A6_125_SVH_A1215", "A6_125_SVL_A1216", "A6_125_SVLL_A1217"], "mg/L", nameList[0])
        },
        {
            type: 0,
            width: 214,
            title: "脱胶中间水池SS",
            deviceId: 6,
            items: templateItems(0, ["A6_125_SSIV_A1231", "A6_125_SVHH_A1234", "A6_125_SVH_A1235", "A6_125_SVL_A1236", "A6_125_SVLL_A1237"], "mg/L", nameList[0])
        },
        {
            type: 0,
            width: 214,
            title: "含银反应槽进水口流量",
            deviceId: 1,
            items: templateItems(0, ["A1_101_FLIV_A0071", "A1_101_SVHH_A0074", "A1_101_SVH_A0075", "A1_101_SVL_A0076", "A1_101_SVLL_A0077"], "m3/h", nameList[0])
        },
        {
            type: 0,
            width: 214,
            title: "浓硝酸收集桶出水流量",//没有浓碱？？？???
            deviceId: 2,
            items: templateItems(0, ["A2_103_FLIV_A0311", "A2_103_SVHH_A0314", "A2_103_SVH_A0315", "A2_103_SVL_A0316", "A2_103_SVLL_A0317"], "m3/h", nameList[0])
        },
        {
            type: 0,
            width: 214,
            title: "浓氟收集桶出水流量",
            deviceId: 2,
            items: templateItems(0, ["A2_104_FLIV_A0371", "A2_104_SVHH_A0374", "A2_104_SVH_A0375", "A2_104_SVL_A0376", "A2_104_SVLL_A0377"], "m3/h", nameList[0])
        },
        {
            type: 0,
            width: 214,
            title: "稀氮调节池出水流量",
            deviceId: 3,
            items: templateItems(0, ["A3_105_FLIV_A0431", "A3_105_SVHH_A0434", "A3_105_SVH_A0435", "A3_105_SVL_A0436", "A3_105_SVLL_A0437"], "m3/h", nameList[0])
        },
        // {
        //     type: 1,
        //     width: 282,
        //     title: "含银反应槽进水口流量累计",
        //     deviceId: 1,
        //     items: templateItems(1, ["A1_101_FLCV_A0074"], "m3")
        // },
        {
            type: 1,
            width: 282,
            title: "浓碱收集桶出水流量累计",
            deviceId: 2,
            items: templateItems(1, ["A2_103_FLCV_A0314"], "m3")
        },
        {
            type: 1,
            width: 282,
            title: "浓氟收集桶出水流量累计",
            deviceId: 2,
            items: templateItems(1, ["A2_104_FLCV_A0374"], "m3")
        },
        {
            type: 1,
            width: 282,
            title: "稀氮废水调节池出水流量累计",
            deviceId: 3,
            items: templateItems(1, ["A3_105_FLCV_A0434"], "m3")
        }
    ],
    2: [
        {
            type: 0,
            width: 200,
            title: "含银废水收集桶CSB",
            deviceId: 1,
            items: templateItems(0, ["A1_101_LVIV_A0031", "A1_101_SVHH_A0034", "A1_101_SVH_A0035", "A1_101_SVL_A0036", "A1_101_SVLL_A0037"], "m", nameList[0])
        },
        {
            type: 0,
            width: 200,
            title: "含银废水收集桶CFB",
            deviceId: 1,
            items: templateItems(0, ["A1_101_LVIV_A0041", "A1_101_SVHH_A0044", "A1_101_SVH_A0045", "A1_101_SVL_A0046", "A1_101_SVLL_A0047"], "m", nameList[0])
        },
        {
            type: 0,
            width: 200,
            title: "含银批次反应槽CSB",
            deviceId: 1,
            items: templateItems(0, ["A1_102_LVIV_A0131", "A1_102_SVHH_A0134", "A1_102_SVH_A0135", "A1_102_SVL_A0136", "A1_102_SVLL_A0137"], "m", nameList[0])
        },
        {
            type: 0,
            width: 200,
            title: "含银污泥池CSB",
            deviceId: 1,
            items: templateItems(0, ["A1_201_LVIV_A0201", "A1_201_SVHH_A0204", "A1_201_SVH_A0205", "A1_201_SVL_A0206", "A1_201_SVLL_A0207"], "m", nameList[0])
        },
        {
            type: 0,
            width: 200,
            title: "浓硝酸废水收集桶CSB",
            deviceId: 2,
            items: templateItems(0, ["A2_103_LVIV_A0271", "A2_103_SVHH_A0274", "A2_103_SVH_A0275", "A2_103_SVL_A0276", "A2_103_SVLL_A0277"], "m", nameList[0])
        },
        {
            type: 0,
            width: 220,
            title: "浓硝酸废水收集桶CFB",
            deviceId: 2,
            items: templateItems(0, ["A2_103_LVIV_A0281", "A2_103_SVHH_A0284", "A2_103_SVH_A0285", "A2_103_SVL_A0286", "A2_103_SVLL_A0287"], "m", nameList[0])
        },
        {
            type: 0,
            width: 220,
            title: "浓氟废水收集桶CSB",
            deviceId: 2,
            items: templateItems(0, ["A2_104_LVIV_A0331", "A2_104_SVHH_A0334", "A2_104_SVH_A0335", "A2_104_SVL_A0336", "A2_104_SVLL_A0337"], "m", nameList[0])
        },
        {
            type: 0,
            width: 200,
            title: "浓氟废水收集桶CFB",
            deviceId: 2,
            items: templateItems(0, ["A2_104_LVIV_A0341", "A2_104_SVHH_A0344", "A2_104_SVH_A0345", "A2_104_SVL_A0346", "A2_104_SVLL_A0347"], "m", nameList[0])
        },
        {
            type: 0,
            width: 200,
            title: "稀氮废水调节池CSB",
            deviceId: 3,
            items: templateItems(0, ["A3_105_LVIV_A0391", "A3_105_SVHH_A0394", "A3_105_SVH_A0395", "A3_105_SVL_A0396", "A3_105_SVLL_A0397"], "m", nameList[0])
        },
        {
            type: 0,
            width: 200,
            title: "脱胶废水均质池CSB",
            deviceId: 5,
            items: templateItems(0, ["A5_117_LVIV_A0861", "A5_117_SVHH_A0864", "A5_117_SVH_A0865", "A5_117_SVL_A0866", "A5_117_SVLL_A0867"], "m", nameList[0])
        },
        {
            type: 0,
            width: 180,
            title: "液碱溶药池CSB",
            deviceId: 8,
            items: templateItems(0, ["A8_311_LVIV_A1621", "A8_311_SVHH_A1624", "A8_311_SVH_A1625", "A8_311_SVL_A1626", "A8_311_SVLL_A1627"], "m", nameList[0])
        },
        {
            type: 0,
            width: 180,
            title: "液碱储药桶CSB",
            deviceId: 8,
            items: templateItems(0, ["A8_312_LVIV_A1671", "A8_312_SVHH_A1674", "A8_312_SVH_A1675", "A8_312_SVL_A1676", "A8_312_SVLL_A1677"], "m", nameList[0])
        },
        // {
        //     type: 0,
        //     width: 180,
        //     title: "磷源储药桶CSB",
        //     deviceId: 9,
        //     items: templateItems(0, ["A9_332_LVIV_A1871", "A9_332_SVHH_A1874", "A9_332_SVH_A1875", "A9_332_SVL_A1876", "A9_332_SVLL_A1877"], "m", nameList[0])
        // },
        {
            type: 0,
            width: 180,
            title: "PAC溶药池CSB",
            deviceId: 9,
            items: templateItems(0, ["A9_331_LVIV_A1821", "A9_331_SVHH_A1824", "A9_331_SVH_A1825", "A9_331_SVL_A1826", "A9_331_SVLL_A1827"], "m", nameList[0])
        },
        {
            type: 0,
            width: 180,
            title: "PAC储药桶CSB",
            deviceId: 9,
            items: templateItems(0, ["A9_332_LVIV_A1871", "A9_332_SVHH_A1874", "A9_332_SVH_A1875", "A9_332_SVL_A1876", "A9_332_SVLL_A1877"], "m", nameList[0])
        },
        {
            type: 0,
            width: 200,
            title: "重补剂溶药桶CSB",
            deviceId: 9,
            items: templateItems(0, ["A9_341_LVIV_A1961", "A9_341_SVHH_A1964", "A9_341_SVH_A1965", "A9_341_SVL_A1966", "A9_341_SVLL_A1967"], "m", nameList[0])
        },
        {
            type: 0,
            width: 200,
            title: "氯化钙溶药池CSB",
            deviceId: 10,
            items: templateItems(0, ["A10_351_LVIV_A2011", "A10_351_SVHH_A2014", "A10_351_SVH_A2015", "A10_351_SVL_A2016", "A10_351_SVLL_A2017"], "m", nameList[0])
        },
        {
            type: 0,
            width: 200,
            title: "氯化钙储药桶CSB",
            deviceId: 10,
            items: templateItems(0, ["A10_352_LVIV_A2061", "A10_352_SVHH_A2064", "A10_352_SVH_A2065", "A10_352_SVL_A2066", "A10_352_SVLL_A2067"], "m", nameList[0])
        },
        // {
        //     type: 0,
        //     width: 200,
        //     title: "碳源溶药桶CSB",
        //     deviceId: 10,
        //     items: templateItems(0, ["A10_352_LVIV_A2061", "A10_352_SVHH_A2064", "A10_352_SVH_A2065", "A10_352_SVL_A2066", "A10_352_SVLL_A2067"], "m", nameList[0])
        // },
        {
            type: 0,
            width: 220,
            title: "PAM自动溶解装置CSB",
            deviceId: 11,
            items: templateItems(0, ["A11_371_LVIV_A2151", "A11_371_SVHH_A2154", "A11_371_SVH_A2155", "A11_371_SVL_A2156", "A11_371_SVLL_A2157"], "m", nameList[0])
        },
        {
            type: 0,
            width: 220,
            title: "石灰自动溶药槽CSB",
            deviceId: 11,
            items: templateItems(0, ["A11_382_LVIV_A2251", "A11_382_SVHH_A2254", "A11_382_SVH_A2255", "A11_382_SVL_A2256", "A11_382_SVLL_A2257"], "m", nameList[0])
        },
        {
            type: 0,
            width: 220,
            title: "氟化钙污泥池CSB",
            deviceId: 4,
            items: templateItems(0, ["A4_221_LVIV_A0741", "A4_221_SVHH_A0744", "A4_221_SVH_A0745", "A4_221_SVL_A0746", "A4_221_SVLL_A0747"], "m", nameList[0])
        },
    ],
    3: [
        {
            type: 0,
            width: 210,
            title: "含银反应槽进水口PH",
            deviceId: 1,
            items: templateItems(0, ["A1_101_PHIV_A0081", "A1_101_SVHH_A0084", "A1_101_SVH_A0085", "A1_101_SVL_A0086", "A1_101_SVLL_A0087"], "", nameList[0])
        },
        {
            type: 0,
            width: 190,
            title: "含银反应池PH",
            deviceId: 1,
            items: templateItems(0, ["A1_102_PHIV_A0111", "A1_102_SVHH_A0114", "A1_102_SVH_A0115", "A1_102_SVL_A0116", "A1_102_SVLL_A0117"], "", nameList[0])
        },
        {
            type: 0,
            width: 190,
            title: "稀氮废水调节池PH",
            deviceId: 3,
            items: templateItems(0, ["A3_105_PHIV_A0381", "A3_105_SVHH_A0384", "A3_105_SVH_A0385", "A3_105_SVL_A0386", "A3_105_SVLL_A0387"], "", nameList[0])
        },
        {
            type: 0,
            width: 210,
            title: "稀氮一级PH调节池PH",
            deviceId: 3,
            items: templateItems(0, ["A3_106_PHIV_A0451", "A3_106_SVHH_A0454", "A3_106_SVH_A0455", "A3_106_SVL_A0456", "A3_106_SVLL_A0457"], "", nameList[0])
        },
        {
            type: 0,
            width: 210,
            title: "稀氮一级混凝池PH",
            deviceId: 3,
            items: templateItems(0, ["A3_108_PHIV_A0491", "A3_108_SVHH_A0494", "A3_108_SVH_A0495", "A3_108_SVL_A0496", "A3_108_SVLL_A0497"], "", nameList[0])
        },
        {
            type: 0,
            width: 210,
            title: "稀氮二级PH调节池PH",
            deviceId: 4,
            items: templateItems(0, ["A4_111_PHIV_A0571", "A4_111_SVHH_A0574", "A4_111_SVH_A0575", "A4_111_SVL_A0576", "A4_111_SVLL_A0577"], "", nameList[0])
        },
        {
            type: 0,
            width: 210,
            title: "稀氮二级混凝池PH",
            deviceId: 4,
            items: templateItems(0, ["A4_113_PHIV_A0611", "A4_113_SVHH_A0614", "A4_113_SVH_A0615", "A4_113_SVL_A0616", "A4_113_SVLL_A0617"], "", nameList[0])
        },
        {
            type: 0,
            width: 210,
            title: "稀氮中间水池PH",
            deviceId: 4,
            items: templateItems(0, ["A4_116_PHIV_A0691", "A4_116_SVHH_A0694", "A4_116_SVH_A0695", "A4_116_SVL_A0696", "A4_116_SVLL_A0697"], "", nameList[0])
        },
        {
            type: 0,
            width: 210,
            title: "脱胶废水均质池PH值",
            deviceId: 5,
            items: templateItems(0, ["A5_117_PHIV_A0821", "A5_117_SVHH_A0824", "A5_117_SVH_A0825", "A5_117_SVL_A0826", "A5_117_SVLL_A0827"], "", nameList[0])
        },
        {
            type: 0,
            width: 210,
            title: "脱胶中间水池PH",
            deviceId: 6,
            items: templateItems(0, ["A6_125_PHIV_A1181", "A6_125_SVHH_A1184", "A6_125_SVH_A1185", "A6_125_SVL_A1186", "A6_125_SVLL_A1187"], "", nameList[0])
        },
        {
            type: 0,
            width: 220,
            title: "稀氮一级反应池F",
            deviceId: 3,
            items: templateItems(0, ["A3_107_FIV_A0471", "A3_107_SVHH_A0474", "A3_107_SVH_A0475", "A3_107_SVL_A0476", "A3_107_SVLL_A0477"], "mg/L", nameList[0])
        },
        {
            type: 0,
            width: 220,
            title: "稀氮二级反应池F",
            deviceId: 4,
            items: templateItems(0, ["A4_112_FIV_A0591", "A4_112_SVHH_A0594", "A4_112_SVH_A0595", "A4_112_SVL_A0596", "A4_112_SVLL_A0597"], "mg/L", nameList[0])
        },
        {
            type: 0,
            width: 220,
            title: "稀氮中间水池F",
            deviceId: 4,
            items: templateItems(0, ["A4_116_FIV_A0701", "A4_116_SVHH_A0704", "A4_116_SVH_A0705", "A4_116_SVL_A0706", "A4_116_SVLL_A0707"], "mg/L", nameList[0])
        },
        {
            type: 0,
            width: 220,
            title: "脱胶中间水池F",
            deviceId: 6,
            items: templateItems(0, ["A6_125_FIV_A1221", "A6_125_SVHH_A1224", "A6_125_SVH_A1225", "A6_125_SVL_A1226", "A6_125_SVLL_A1227"], "mg/L", nameList[0])
        },
    ],
    4: [
        {
            type: 0,
            width: 180,
            title: "高浓度废水缓冲池",
            deviceId: 14,
            subtitle: "V01液位设定(mm)",
            items: templateItems(0, ["B2_01_SVHH_A0714", "B2_01_SVH_A0715", "B2_01_SVL_A0716", "B2_01_SVLL_A0717"], "mm", nameList[1], true)
        },
        {
            type: 0,
            width: 170,
            title: "催化氧化污泥槽",
            deviceId: 14,
            subtitle: "V08液位设定(mm)",
            items: templateItems(0, ["B2_08_SVHH_A0954", "B2_08_SVH_A0955", "B2_08_SVL_A0956", "B2_08_SVLL_A0957"], "mm", nameList[1], true)
        },
        {
            type: 0,
            width: 165,
            title: "脱胶生化调节池",
            deviceId: 13,
            subtitle: "V09液位设定(mm)",
            items: templateItems(0, ["B1_09_SVHH_A0024", "B1_09_SVH_A0025", "B1_09_SVL_A0026", "B1_09_SVLL_A0027"], "mm", nameList[1], true)
        },
        {
            type: 0,
            width: 150,
            title: "V17中间水池",
            deviceId: 13,
            subtitle: "V液位设定(mm)",
            items: templateItems(0, ["B1_17_SVHH_A0594", "B1_17_SVH_A0595", "B1_17_SVL_A0596", "B1_17_SVLL_A0597"], "mm", nameList[1], true)
        },
        {
            type: 0,
            width: 140,
            title: "物化污泥池",
            deviceId: 14,
            subtitle: "液位设定(mm)",
            items: templateItems(0, ["B2_001_SVHH_A0994", "B2_001_SVH_A0995", "B2_001_SVL_A0996", "B2_001_SVLL_A0997"], "mm", nameList[1], true)
        },
        {
            type: 0,
            width: 140,
            title: "生化污泥池",
            deviceId: 14,
            subtitle: "液位设定(mm)",
            items: templateItems(0, ["B2_002_SVHH_A1054", "B2_002_SVH_A1055", "B2_002_SVL_A1056", "B2_002_SVLL_A1057"], "mm", nameList[1], true)
        },
        // {
        //     type: 0,
        //     width: 140,
        //     title: "事故池",
        //     deviceId: 14,
        //     subtitle: "液位设定(mm)",
        //     items: templateItems(0, ["B2_002_SVHH_A1054", "B2_002_SVH_A1055", "B2_002_SVL_A1056", "B2_002_SVLL_A1057"], "mm", nameList[1], true)
        // },
        {
            type: 0,
            width: 140,
            title: "V02反应池1",
            deviceId: 14,
            subtitle: "PH设定(酸碱度)",
            items: templateItems(0, ["B2_02_SVHH_A0764", "B2_02_SVH_A0765", "B2_02_SVL_A0766", "B2_02_SVLL_A0767"], "", nameList[1], true)
        },
        {
            type: 0,
            width: 140,
            title: "V05混凝槽1",
            deviceId: 14,
            subtitle: "PH设定(酸碱度)",
            items: templateItems(0, ["B2_05_SVHH_A0844", "B2_05_SVH_A0845", "B2_05_SVL_A0846", "B2_05_SVLL_A0847"], "", nameList[1], true)
        },
        {
            type: 0,
            width: 149,
            title: "V05混凝槽2",
            deviceId: 14,
            subtitle: "PH设定(酸碱度)",
            items: templateItems(0, ["B2_05_SVHH_A0854", "B2_05_SVH_A0855", "B2_05_SVL_A0856", "B2_05_SVLL_A0857"], "", nameList[1], true)
        },
        {
            type: 0,
            width: 180,
            title: "稀硫酸液位设定",
            deviceId: 15,
            subtitle: "(mm)",
            items: templateItems(0, ["B3_008_SVHH_A1324", "B3_008_SVH_A1325", "B3_008_SVL_A1326", "B3_008_SVLL_A1327"], "mm", nameList[1], true)
        },
        {
            type: 0,
            width: 170,
            title: "液碱液位设定",
            deviceId: 15,
            subtitle: "(mm)",
            items: templateItems(0, ["B3_006_SVHH_A1194", "B3_006_SVH_A1195", "B3_006_SVL_A1196", "B3_006_SVLL_A1197"], "mm", nameList[1], true)
        },
        {
            type: 0,
            width: 165,
            title: "PAM液位设定",
            deviceId: 15,
            subtitle: "(mm)",
            items: templateItems(0, ["B3_009_SVHH_A1394", "B3_009_SVH_A1395", "B3_009_SVL_A1396", "B3_009_SVLL_A1397"], "mm", nameList[1], true)
        },
        {
            type: 0,
            width: 148,
            title: "PAC液位设定",
            deviceId: 15,
            subtitle: "(mm)",
            items: templateItems(0, ["B3_010_SVHH_A1494", "B3_010_SVH_A1495", "B3_010_SVL_A1496", "B3_010_SVLL_A1497"], "mm", nameList[1], true)
        },
        {
            type: 0,
            width: 178,
            title: "硫酸亚铁液位设定",
            deviceId: 15,
            subtitle: "(mm)",
            items: templateItems(0, ["B3_007_SVHH_A1274", "B3_007_SVH_A1275", "B3_007_SVL_A1276", "B3_007_SVLL_A1277"], "mm", nameList[1], true)
        },
        {
            type: 0,
            width: 135,
            title: "回用水池",
            deviceId: 14,
            subtitle: "液位设定(mm)",
            items: templateItems(0, ["B2_005_SVHH_A1144", "B2_005_SVH_A1145", "B2_005_SVL_A1146", "B2_005_SVLL_A1147"], "mm", nameList[1], true)
        },
        {
            type: 0,
            width: 185,
            title: "回用水池PD目标值",
            deviceId: 14,
            // subtitle: " ",
            items: templateItems(0, ["B2_005_PSV_A1172"], "kPa", [""], true)
        },
        {
            type: 0,
            width: 197,
            title: "回用水池变频器频率",
            deviceId: 14,
            subtitle: "手动设定Hz",
            items: templateItems(0, ["B2_005_SVFRQ_A1154", "B2_005_SVFRQ_A1164"], "Hz", ["供水泵A", "供水泵B"], true)
        },
        {
            type: 0,
            width: 161,
            title: "V16污泥回流泵",
            deviceId: 13,
            subtitle: "时间设定min",
            items: templateItems(0, ["B1_16_STATM_A0513", "B1_16_STPTM_A0514"], "min", nameList[2], true)
        },
        {
            type: 0,
            width: 166,
            title: "V04催化氧化池1",
            deviceId: 14,
            subtitle: "ORP设定",
            items: templateItems(0, ["B2_04_SVHH_A0804", "B2_04_SVLL_A0805"], "mv", nameList[3], true)
        },
        {
            type: 0,
            width: 168,
            title: "V04催化氧化池2",
            deviceId: 14,
            subtitle: "ORP设定",
            items: templateItems(0, ["B2_04_SVHH_A0814", "B2_04_SVLL_A0815"], "mv", nameList[3], true)
        },
        {
            type: 0,
            width: 240,
            title: "07高效斜板沉淀池电磁阀",
            deviceId: 14,
            subtitle: "时间设定Min",
            items: templateItems(0, ["B2_07_STATM_A0903", "B2_07_STPTM_A0904"], "mv", nameList[2], true)
        },
        {
            type: 0,
            width: 168,
            title: "V14水解酸化池A",
            deviceId: 13,
            subtitle: "污泥浓度设定",
            items: templateItems(0, ["B1_14_SVHH_A0294", "B1_14_SVLL_A0295"], "g/L", nameList[3], true)
        },
        {
            type: 0,
            width: 168,
            title: "V14水解酸化池B",
            deviceId: 13,
            subtitle: "污泥浓度设定",
            items: templateItems(0, ["B1_14_SVHH_A0304", "B1_14_SVLL_A0305"], "g/L", nameList[3], true)
        },
        {
            type: 0,
            width: 168,
            title: "V15接触氧化池A",
            deviceId: 13,
            subtitle: "溶解氧浓度",
            items: templateItems(0, ["B1_15_SVHH_A0454", "B1_15_SVLL_A0455"], "mg/L", nameList[3], true)
        },
        {
            type: 0,
            width: 160,
            title: "V10PH调节槽B",
            deviceId: 13,
            subtitle: "PH设定(酸碱度)",
            items: templateItems(0, ["B1_10_ACSTA_A0144", "B1_10_ACSTP_A0145", "B1_10_BASTA_A0146", "B1_10_BASTP_A0147", "B1_10_SVHH_A0148", "B1_10_SVLL_A0149"], "", nameList[4], true)
        },
        {
            type: 0,
            width: 160,
            title: "V10PH调节槽A",
            deviceId: 13,
            subtitle: "PH设定(酸碱度)",
            items: templateItems(0, ["B1_10_ACSTA_A0134", "B1_10_ACSTP_A0135", "B1_10_BASTA_A0136", "B1_10_BASTP_A0137", "B1_10_SVHH_A0138", "B1_10_SVLL_A0139"], "", nameList[4], true)
        },
        {
            type: 0,
            width: 168,
            title: "V15接触氧化池B",
            deviceId: 13,
            subtitle: "溶解氧浓度",
            items: templateItems(0, ["B1_15_SVHH_A0464", "B1_15_SVLL_A0465"], "mg/L", nameList[3], true)
        },
        {
            type: 0,
            width: 168,
            title: "V13初沉池电磁阀",
            deviceId: 13,
            subtitle: "时间设定min",
            items: templateItems(0, ["B1_13_STATM_A0233", "B1_13_STPTM_A0234"], "min", nameList[2], true)
        },
        {
            type: 0,
            width: 180,
            title: "V15接触氧化池A",
            deviceId: 13,
            subtitle: "污泥浓度设定",
            items: templateItems(0, ["B1_15_SVHH_A0474", "B1_15_SVLL_A0475"], "g/L", nameList[3], true)
        },
        {
            type: 0,
            width: 180,
            title: "V15接触氧化池B",
            deviceId: 13,
            subtitle: "污泥浓度设定",
            items: templateItems(0, ["B1_15_SVHH_A0484", "B1_15_SVLL_A0485"], "g/L", nameList[3], true)
        },
        {
            type: 0,
            width: 180,
            title: "水解酸化池V14A～F搅拌机",
            deviceId: 13,
            subtitle: "时间设定min",
            items: templateItems(0, ["B1_14_STATM_A0313", "B1_14_STPTM_A0314"], "min", nameList[2], true)
        },
        {
            type: 0,
            width: 181.2,
            title: "水解酸化池V14G～L搅拌机",
            deviceId: 13,
            subtitle: "时间设定min",
            items: templateItems(0, ["B1_14_STATM_A0373", "B1_14_STPTM_A0374"], "min", nameList[2], true)
        },
    ]
};

export default conf;
