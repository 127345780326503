import React from "react";
import './survive.scss'
import SurvivePieChart from './SurvivePieChart'
import style from "./index.module.scss";
const SurviveTable = (props) => {
    const {
        naturalGas_single,
        naturalGas_comparison,
        naturalGas_dayTotal,
        naturalGas_MonthTotal,
        naturalGas_YearTotal,
        naturalGas_redNum,
        naturalGas_yellowNum,
        naturalGas_greenNum,
        naturalGas_LightGreenNum,
        electric_single,
        electric_comparison,
        electric_dayTotal,
        electric_MonthTotal,
        electric_YearTotal,
        electric_redNum,
        electric_yellowNum,
        electric_greenNum,
        electric_LightGreenNum,
        water_single,
        water_comparison,
        water_dayTotal,
        water_MonthTotal,
        water_YearTotal,
        nitrogen_single,
        nitrogen_comparison,
        nitrogen_dayTotal,
        nitrogen_MonthTotal,
        nitrogen_YearTotal,
        gasNumber,
        electricNumber,
        waterNumber,
        nitrogenNumber,
        gasTotal,
        electricTotal
    } = props
    return (
        <div className="surviveTable">
            <div className="surviveTable-title">能耗情况</div>
            <div className="surviveTable-content">
                <div className="surviveTable-table">
                    <div className="surviveTable-table-th" style={{backgroundColor: "#F5F5F5",fontSize: 16}}>
                        <div>名称</div>
                        <div>单耗</div>
                        <div className="borderRight">环比</div>
                        <div></div>
                        <div style={{textAlign: "right"}}>日累</div>
                        <div style={{textAlign: "right"}}>月累</div>
                        <div style={{textAlign: "right",paddingRight: 16}}>年累</div>
                    </div>
                    <div className="surviveTable-table-tr">
                        <div className="left" style={{paddingLeft: 16}}>燃料气</div>
                        <div>
                            <span className="bold">{naturalGas_single}</span>
                            <span className="light">Nm3/万Nm3</span>
                        </div>
                        <div className="borderRight">{`${naturalGas_comparison}%`}</div>
                        <div className="right pl16">万Nm3</div>
                        <div className="right">{naturalGas_dayTotal}</div>
                        <div className="right">{naturalGas_MonthTotal}</div>
                        <div className="right" style={{paddingRight: 16}}>{naturalGas_YearTotal}</div>
                    </div>
                    <div className="surviveTable-table-tr">
                        <div className="left" style={{paddingLeft: 16}}>电</div>
                        <div>
                            <span className="bold">{electric_single}</span>
                            <span className="light">kWh/万Nm3</span>
                        </div>
                        <div className="borderRight">{`${electric_comparison}%`}</div>
                        <div className="right pl16">kWh</div>
                        <div className="right">{electric_dayTotal}</div>
                        <div className="right">{electric_MonthTotal}</div>
                        <div className="right" style={{paddingRight: 16}}>{electric_YearTotal}</div>
                    </div>
                    <div className="surviveTable-table-tr">
                        <div className="left" style={{paddingLeft: 16}}>氮气</div>
                        <div>
                            <span className="bold">{nitrogen_single}</span>
                            <span className="light">Nm3/万Nm3</span>
                        </div>
                        <div className="borderRight">{`${nitrogen_comparison}%`}</div>
                        <div className="right pl16">万Nm3</div>
                        <div className="right">{nitrogen_dayTotal}</div>
                        <div className="right">{nitrogen_MonthTotal}</div>
                        <div className="right" style={{paddingRight: 16}}>{nitrogen_YearTotal}</div>
                    </div>
                </div>
                <div className="surviveTable-content-right">
                    <div className="title">能耗成本占比</div>
                    <SurvivePieChart
                        gasNumber={gasNumber}
                        electricNumber={electricNumber}
                        nitrogenNumber={nitrogenNumber}
                    />
                    <div className="legend">
                        <div>
                            <span className="blue"></span>
                            <span>燃料气</span>
                        </div>
                        <div>
                            <span className="green"></span>
                            <span>电</span>
                        </div>
                        <div>
                            <span className="orange"></span>
                            <span>氮气</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default SurviveTable
