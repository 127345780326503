import React from 'react'
import { Checkbox, Tree, Button, Modal, Input, message, Switch } from 'antd';
import { get, post, patch, reqDelete } from 'common/utils.js';
import { PlusOutlined } from '@ant-design/icons';
import { getMyPermissions } from 'common/AuthPermission.js';
import DELETE_ICON from './res/delete.svg';
import style from './index.module.scss';

export default class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            myPermissions: getMyPermissions(),
            roleList: [],
            pageList: [],
            flatPageList: [],
            isAddingRole: false,
            modifyRoleStatus: false,
            edittingName: '',
            selectedRole: null,
            selectedKeys: [],
            authChangeLock: {},
            checkedKeys: [],
            modalVisible: false,
            currentUserId: null,
            initSelectedKeys: [],
        }
    }
    componentDidMount() {
        this.getInfoAndConfig();
    }
    componentDidUpdate() {
        // console.log('componentDidUpdate');
        const { selectedRole, roleList, checkedKeys: prevCheckedKeys, flatPageList } = this.state;
        if (selectedRole) {
            const checkedKeys = [];
            const { permissions: rolePermissions = [] } = roleList.filter(({ id: roleId }) => roleId === selectedRole)[0] || {};
            flatPageList
                .forEach(({ children = [], id: pageId, permissions }) => {
                    if (children.length === 0) {
                        //叶子节点
                        if (permissions.length !== 0 && permissions
                            .filter(({ id }) => rolePermissions.indexOf(id) > -1)
                            .length === permissions.length) {
                            checkedKeys.push(pageId);
                        }
                    } else {
                        //父节点
                        if (children
                            .filter(({ id }) => checkedKeys.indexOf(id) > -1)
                            .length === children.length) {
                            checkedKeys.push(pageId);
                        }
                    }
                });
            //判断一下checkedKeys和prevCheckedKeys一样的话就不更新了,否则无限更新
            if (!(checkedKeys.length === prevCheckedKeys.length && checkedKeys.length === [...new Set(checkedKeys.concat(prevCheckedKeys))].length)) {
                this.setState({ checkedKeys });
            }
            // console.log(checkedKeys, prevCheckedKeys);
        } else {
            if (prevCheckedKeys.length > 0) {
                this.setState({ checkedKeys: [] });
            }
        }
    }
    getInfoAndConfig = () => {
        get(process.env.REACT_APP_SERVER_PATH + 'api/auth/roles/list_info')
            .then(retData => {
                // console.log(retData);
                if (retData.data && retData.data.errcode === 0) {
                    const { pages = [], roles: roleList = [] } = retData.data.results || {};
                    const flatPageList = [];
                    pages.forEach(page => {
                        flatPageList.push(...(page.children || []), page);
                    });
                    this.setState({
                        pageList: pages
                            .map(page => ({
                                ...page,
                                title: page.name,
                                key: page.id,
                                children: page.children
                                    .map(child => ({
                                        ...child,
                                        title: child.name,
                                        key: child.id,
                                    }))
                            })),
                        flatPageList: flatPageList
                            .sort(({ children: children_1 = [] }, { children: children_2 = [] }) => children_1.length - children_2.length),
                        initSelectedKeys: flatPageList.length > 0 ? [flatPageList[0].id] : [],
                        roleList,
                    });
                }
            })
    }
    updatePermission = (operation, role_id, permission_id) => {
        return post(process.env.REACT_APP_SERVER_PATH + 'api/auth/roles/permissions/update_one', {
            role_id, permission_id, operation,
        })
            .then(retData => {
                console.log(retData);
                if (retData.data && retData.data.errcode === 0) return true;
                return false;
            })
    }
    addRoleBtn = () => {
        this.setState({ isAddingRole: true, edittingName: '', selectedRole: null }, () => {
            this.addRoleInput && this.addRoleInput.focus && this.addRoleInput.focus();
        });
    }
    createRole = name => {
        return post(process.env.REACT_APP_SERVER_PATH + 'api/user/roles', {
            name,
            permission_ids: [],
        }).then((retData) => {
            if (retData && retData.data) {
                if (retData.data.id > 0) {
                    message.success("角色创建成功");
                    return {
                        id: retData.data.id,
                        status: retData.data.status,
                    };
                } else {
                    message.error("角色创建失败");
                    return null;
                }
            } else {
                message.error("角色创建失败,请检测网络等问题");
                return null;
            }
        });
    }
    updateRole = (roleId, permission_ids) => {
        return patch(process.env.REACT_APP_SERVER_PATH + 'api/user/roles/' + roleId, {
            permission_ids,
        }).then((retData) => {
            if (retData && retData.data) {
                if (retData.data.id > 0) {
                    message.success("权限修改成功");
                    return true;
                } else {
                    message.error("权限修改失败");
                    return false;
                }
            } else {
                message.error("权限修改失败,请检测网络等问题");
                return false;
            }
        });
    }
    addNewRoleEnter = () => {
        const { edittingName, roleList } = this.state;
        this.setState({ isAddingRole: false });
        if (edittingName) {
            this.createRole(edittingName)
                .then((ret) => {
                    if (ret !== null) {
                        const { id, status } = ret;
                        roleList.push({
                            id,
                            name: edittingName,
                            status,
                        });
                        this.setState({ roleList });
                    }
                })
        };
    }
    deleteRoleBtn = (id, e) => {
        e.stopPropagation();
        this.setState({
            currentUserId: id,
            modalVisible: true,
        });
    }
    deleteRole = () => {
        const { currentUserId, roleList, selectedRole } = this.state;
        reqDelete(process.env.REACT_APP_SERVER_PATH + 'api/user/roles/' + currentUserId, {})
            .then((retData) => {
                this.setState({
                    modalVisible: false,
                })
                if (retData.status === 204) {
                    message.info("角色删除成功");
                    this.setState({
                        roleList: roleList.filter(({ id: _id }) => _id !== currentUserId),
                        currentUserId: null,
                        ...(selectedRole === currentUserId ?
                            {
                                selectedRole: null,
                            } : {}),
                    })
                } else {
                    message.info("角色删除失败");
                }
            });
    }

    changeRoleStatus = (roleItem, status) => {
        let self = this;
        patch(process.env.REACT_APP_SERVER_PATH + 'api/user/roles/' + roleItem.id, {
            status: status === true ? 1 : 0,
        }).then((retData) => {
            if (retData && retData.data) {
                if (retData.data.id > 0) {
                    roleItem.status = (status === true ? 1 : 0);
                    self.forceUpdate();
                    message.success("权限修改成功", 1);
                    return true;
                } else {
                    message.error("权限修改失败", 1);
                    return false;
                }
            } else {
                message.error("权限修改失败，网络或服务器异常");
                return false;
            }
        });
    }

    selectRoleOnClick = id => {
        const { selectedRole, isAddingRole, initSelectedKeys } = this.state;
        if (isAddingRole || selectedRole === id) return;
        this.setState({
            selectedRole: id,
            selectedKeys: initSelectedKeys,
        }, () => {
            this.treeDataContent && (this.treeDataContent.scrollTop !== undefined) && (this.treeDataContent.scrollTop = 0);
        });
    }
    authCheckOnChange = (checked, id) => {
        const { roleList, selectedRole, authChangeLock } = this.state;
        if (selectedRole === null) {
            message.error("尚未选择角色");
            return;
        }
        if (authChangeLock[`${selectedRole}`] === true) {
            message.error("权限尚未修改完成，请稍后再次尝试");
            return;
        }
        authChangeLock[`${selectedRole}`] = true;
        const { permissions = [] } = roleList.filter(({ id: roleId }) => roleId === selectedRole)[0] || {};
        permissions.splice(permissions.indexOf(id), ...checked ? [0, id] : [1]);
        this.updatePermission(checked ? 'ADD' : 'DELETE', selectedRole, id)
            .then(isSuccess => {
                if (isSuccess) {
                    message.success("权限修改成功");
                    authChangeLock[`${selectedRole}`] = false;
                } else {
                    message.error("权限修改失败");
                    permissions.splice(permissions.indexOf(id), ...!checked ? [0, id] : [1]);
                    this.setState({ roleList }, () => {
                        authChangeLock[`${selectedRole}`] = false;
                    });
                };
            });
        this.setState({ roleList });
    }
    checkedKeysOnChange = (checkedKeys) => {
        const { checkedKeys: prevCheckedKeys } = this.state;
        // console.log(prevCheckedKeys, checkedKeys, this.state.flatPageList);
        // this.setState({ checkedKeys });
        const { roleList, selectedRole, flatPageList, authChangeLock } = this.state;
        if (selectedRole === null) {
            message.error("尚未选择角色");
            return;
        }
        if (authChangeLock[`${selectedRole}`] === true) {
            message.error("权限尚未修改完成，请稍后再次尝试");
            return;
        }
        const curRole = roleList.filter(({ id: roleId }) => roleId === selectedRole)[0] || {};
        const { permissions: prevPermissions = [] } = curRole;
        let changedPermissions = [...prevPermissions];
        const allChangedCheckedKeys = [...new Set(prevCheckedKeys.concat(checkedKeys))];
        allChangedCheckedKeys
            .forEach(key => {
                const curPagePermissions =
                    ((flatPageList
                        .filter(({ id }) => id === key)[0] || {})
                        .permissions || [])
                        .map(({ id }) => id);
                if (prevCheckedKeys.indexOf(key) > -1 && checkedKeys.indexOf(key) === -1) {
                    //之前有，后来无，删除该page所有权限
                    curPagePermissions
                        .forEach(permission => {
                            if (changedPermissions.indexOf(permission) > -1) {
                                changedPermissions.splice(changedPermissions.indexOf(permission), 1);
                            }
                        })
                } else if (prevCheckedKeys.indexOf(key) === -1 && checkedKeys.indexOf(key) > -1) {
                    // console.log(allChangedCheckedKeys);
                    //之前无，后来有，添加该page所有权限
                    changedPermissions = [...new Set(changedPermissions.concat(curPagePermissions))];
                }
            });
        // console.log(prevPermissions, changedPermissions);
        if (prevPermissions.length === changedPermissions.length && prevPermissions.length === [...new Set(prevPermissions.concat(changedPermissions))].length) {
            message.error("新增页面尚未配置具体权限，无法选择!");
        } else {
            authChangeLock[`${selectedRole}`] = true;
            this.updateRole(selectedRole, changedPermissions)
                .then(isSuccess => {
                    if (isSuccess) {
                        authChangeLock[`${selectedRole}`] = false;
                    } else {
                        curRole.permissions = prevPermissions;
                        this.setState({
                            checkedKeys: prevCheckedKeys,
                            roleList,
                        }, () => {
                            authChangeLock[`${selectedRole}`] = false;
                        });
                    }
                })
            curRole.permissions = changedPermissions;
            this.setState({
                checkedKeys,
                roleList,
            });
        }
    }
    render() {
        const { myPermissions, roleList, pageList, isAddingRole, edittingName, selectedRole, selectedKeys, flatPageList, checkedKeys, modalVisible } = this.state;
        let authList = [];
        if (selectedRole && selectedKeys.length > 0) {
            authList =
                (flatPageList
                    .filter(page => page.id === selectedKeys[0])[0] || {})
                    .permissions || [];
        };
        return (
            <div className={style["RoleMngSetting"]}>
                <div className="bottom_content_wrapper">
                    <div>
                        {
                            myPermissions.includes('roles/add_role') && (
                                <div className="content_wrapper_header">
                                    <Button type="primary" style={{ marginLeft: 'auto', backgroundColor: '#357CF7' }} onClick={this.addRoleBtn}><PlusOutlined />添加</Button>
                                </div>
                            )
                        }
                        <div className="content_wrapper_content">
                            <div className="block_wrapper">
                                <div className="block_header">角色名称</div>
                                <div className="block_content">
                                    {
                                        roleList.map(({ id, name, status }, index) => (
                                            <div
                                                key={index}
                                                className={"role_list_row" + (selectedRole === id ? ' role_list_row_selected' : '')}
                                                onClick={() => this.selectRoleOnClick(id)}
                                            >
                                                <div className="name" style={{ color: (status === 1 ? '#000000a6' : '#A5A5A5a6') }}>{name}</div>
                                                {
                                                    //Hard code
                                                    [1, 2].indexOf(id) === -1 &&
                                                    <div className="opt_box">
                                                        {myPermissions.includes('roles/on_off_role') &&
                                                            <Switch
                                                                className="on_off_switch"
                                                                size="default"
                                                                checkedChildren="已启用"
                                                                unCheckedChildren="已禁用"
                                                                defaultChecked={status === 1}
                                                                checked={status === 1}
                                                                onChange={(_status) => this.changeRoleStatus(roleList[index], _status)}
                                                            />
                                                        }
                                                        {!isAddingRole && (
                                                            <div
                                                                className="delete"
                                                                onClick={e => this.deleteRoleBtn(id, e)}
                                                            >
                                                                <img
                                                                    src={DELETE_ICON}
                                                                    alt=""
                                                                    width="15"
                                                                    height="17"
                                                                    style={{ display: 'block', marginRight: 2 }}
                                                                />
                                                                    删除
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    }
                                    {
                                        isAddingRole && (
                                            <div className="role_list_row role_list_row_selected role_list_row_selected_input">
                                                <Input
                                                    placeholder="请输入角色名称"
                                                    style={{ width: '40%', borderWidth: 0 }}
                                                    value={edittingName}
                                                    onChange={e => this.setState({ edittingName: e.target.value })}
                                                    onPressEnter={this.addNewRoleEnter}
                                                    ref={e => this.addRoleInput = e}
                                                />
                                                <div className="save" onClick={this.addNewRoleEnter}>保存</div>
                                                <div className="delete_2" onClick={() => this.setState({ isAddingRole: false })}><img src={DELETE_ICON} alt="" width="15" height="17" style={{ display: 'block', marginRight: 2 }} />删除</div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="middle_block_item" />
                            <div className="block_wrapper">
                                <div className="block_header">页面名称</div>
                                <div className="block_content" ref={e => this.treeDataContent = e}>
                                    {
                                        selectedRole && pageList.length > 0 && (
                                            <Tree
                                                checkable
                                                defaultExpandAll
                                                blockNode
                                                checkedKeys={checkedKeys}
                                                onCheck={this.checkedKeysOnChange}
                                                selectedKeys={selectedKeys}
                                                onSelect={selectedKeys => this.setState({ selectedKeys })}
                                                treeData={pageList}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            <div className="middle_block_item" />
                            <div className="block_wrapper">
                                <div className="block_header">操作权限</div>
                                <div className="block_content">
                                    {
                                        authList
                                            .map(({ id, name }, index) => (
                                                <div key={index} className="auth_list_row">
                                                    <Checkbox
                                                        onChange={e => this.authCheckOnChange(e.target.checked, id)}
                                                        checked={((roleList.filter(({ id: roleId }) => roleId === selectedRole)[0] || {}).permissions || []).indexOf(id) > -1}
                                                    />
                                                    <div className="name">{name}</div>
                                                </div>
                                            ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    visible={modalVisible}
                    title="角色删除"
                    onOk={this.deleteRole}
                    onCancel={() =>
                        this.setState({
                            modalVisible: false
                        })
                    }>
                    <span>你确定要删除该角色吗？</span>
                </Modal>
            </div>
        )
    }
}
