import React, {useEffect, useState, useImperativeHandle} from 'react'
import Empty from '../../../Empty'
import style from './index.module.scss'
import {message, Modal, Upload} from "antd";
import {PhotoSlider} from 'react-photo-view'
import 'react-photo-view/dist/index.css'
import {post, get, postDownloadFile, reqDelete, patch, getToken} from 'common/utils';
import addImg from '../../../../../../../../assets/images/v3/add_icon.png'
import audioLine from '../../../../../../../../assets/aduio_line.png'
import playImg from '../../../../../../../../assets/videoPlay.png'
import videoPlayBg from '../../../../../../../../assets/videoBg.jpg'

const Com = props => {
    const {isDisabled, capsuleId} = props
    const [uuids, setUuids] = useState([])
    const [imgList, setImgList] = useState([]) // 图片
    const [audioList, setAudioList] = useState([]) // 音频
    const [videoList, setVideoList] = useState([]) // 视频
    const [visible, setVisible] = useState(false) // 图片弹出
    const [photoIndex, setPhotoIndex] = useState(0)
    const [videoVisible, setVideoVisible] = useState(false) // 视频弹出
    const [videoFilePath, setVideoFilePath] = useState('')
    const [attachArray, setAttachArray] = useState([])
    /** 暴漏给父组件的方法和属性 */
    useImperativeHandle(props.refInstance, () => ({
        uuids
    }))
    // 附件详情
    const enclosureDetail = id => {
        get(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/capsule/queryAttachment', {
            id: id
        }).then(res => {
            const data = res.data.results
            setAttachArray(data)
            const imageArr = []
            const audioArr = []
            const videoArr = []
            data.forEach(item => {
                if (item.fileType === '图片') {
                    imageArr.push(item)
                } else if (item.fileType === '音频') {
                    audioArr.push(item)
                } else {
                    videoArr.push(item)
                }
            })
            setImgList(imageArr)
            setAudioList(audioArr)
            setVideoList(videoArr)
            console.log(videoArr)
        }).catch(err => {
            console.log(err)
        })
    }
    // 删除附件
    const deleteAttach = (arr, attachId, index, type, e) => {
        e.stopPropagation()
        reqDelete(process.env.REACT_APP_SERVER_PATH + 'api/v1/event/event/attachmentDelete', {
            attachmentIds: capsuleId ? [capsuleId] : [],
            uuid: uuids
        }).then(res => {
            arr.splice(index, 1)
            if (res.data.msg === '删除完成') {
                message.success('删除成功')
                const tplArr = arr.concat()
                if (type === 'image') {
                    setImgList(tplArr)
                } else if (type === 'audio') {
                    setAudioList(tplArr)
                } else if (type === 'video') {
                    setVideoList(tplArr)
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }
    // 音频播放
    const audioPlay = (filePath) => {
        var audioElement = new Audio(process.env.REACT_APP_SERVER_PATH +
            `api/v1/event/capsule/downloadAttachment?file_path=${filePath}`);
        audioElement.loop = false;
        audioElement.currentTime = 0;
        audioElement.preload = 'auto';
        audioElement.play();
    }
    const handleVideoConfirm = () => {
        var media = document.getElementById('voice')
        if (media) {
            media.pause()
        }
        setVideoVisible(false)
    }
    const handleVideoCancel = () => {
        setVideoVisible(false)
    }
    const videoPlay = (filePath) => {
        setVideoVisible(true)
        setVideoFilePath(filePath)
    }
    useEffect(() => {
        capsuleId && enclosureDetail(capsuleId)
    }, [capsuleId])
    return (
        <div className={style['attachmentWrapper']}>
            <div className="top">
                <span>附件</span>
                {
                    !isDisabled && <Upload
                        name='file'
                        action={process.env.REACT_APP_SERVER_PATH + 'api/v1/event/event/attachmentUploading'}
                        accept='image/JPEG,image/TIFF,image/RAW,image/BMP,image/GIF,image/PNG,
    audio/WAV,audio/MP3,audio/WMA,audio/MIDI,video/MP4,
    video/AVI,video/MOV,video/3GP,video/RM,video/RMVB'
                        headers={{
                            authorization: getToken(),
                        }}
                        multiple={true}
                        showUploadList={false}
                        onChange={(info) => {
                            const {status} = info.file;
                            if (status !== 'uploading') {
                                console.log(info.file)
                                const arr = []
                                const fileType = info.file.type.split('/')
                                if (fileType[0] === 'image') {
                                    arr.push({
                                        file_path: info.file.response.results.filePath,
                                        uuid: info.file.response.results.uuid
                                    })
                                    setImgList(arr)
                                } else if (fileType[0] === 'audio') {
                                    arr.push({
                                        file_path: info.file.response.results.filePath,
                                        uuid: info.file.response.results.uuid
                                    })
                                    setAudioList(arr)
                                } else if (fileType[0] === 'video') {
                                    arr.push({
                                        file_path: info.file.response.results.filePath,
                                        uuid: info.file.response.results.uuid
                                    })
                                    setVideoList(arr)
                                } else {
                                    message.success("文件格式错误");
                                    return
                                }
                                const uuidArray = []
                                arr && arr.forEach(item => {
                                    uuidArray.push(item.uuid)
                                })
                                setUuids(uuidArray)
                            }
                            if (status === 'done') {
                                message.success("文件已上传");
                            } else if (status === 'error') {
                                message.error("上传失败");
                                message.error(info.file.response['msg']);
                            }
                        }}
                    >
                        <img src={addImg} alt=""/>
                        <span className="add-btn">添加附件</span>
                    </Upload>
                }
                {/*{!isDisabled && <span className="add-btn">添加附件</span>}*/}
            </div>
            {(uuids.length === 0 && attachArray.length === 0) && <Empty tipMsg="暂无附件"/>}
            <div className="attachmentPic" style={{display: attachArray.length > 0 ? 'block' : 'none'}}>
                <div className="attachmentPicTitle">
                    <span className="text">图片</span>
                    <span className="num">(+{imgList.length})</span>
                </div>
                <ul className="picUl">
                    {
                        imgList && imgList.map((item, index) => {
                            return (
                                <li key={`img${index}`} className="picList" onClick={() => setVisible(true)}>
                                    <img src={imgList.length > 0 ? process.env.REACT_APP_SERVER_PATH +
                                        `api/v1/event/capsule/downloadAttachment?file_path=${item.file_path}` : ''}
                                         alt="" width="148" height="111"/>
                                    <div className="close"
                                         onClick={(e) => deleteAttach(imgList, item.uuid, index, 'image',e)}>X
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <PhotoSlider
                images={imgList.map(item => ({
                    src:
                        process.env.REACT_APP_SERVER_PATH +
                        `api/v1/event/capsule/downloadAttachment?file_path=${item.file_path}`
                }))}
                visible={visible}
                onClose={() => setVisible(false)}
                index={photoIndex}
                onIndexChange={setPhotoIndex}
            />
            <div className="attachmentPic" style={{display: attachArray.length > 0 ? 'block' : 'none'}}>
                <div className="attachmentPicTitle">
                    <span className="text">音频</span>
                    <span className="num">(+{audioList.length})</span>
                </div>
                <ul className="picUl">
                    {
                        audioList && audioList.map((item, index) => {
                            return (
                                <li className="picList" key={`audio${index}`}>
                                    <img src={audioLine}
                                         alt=""
                                         width="148" height="84"/>
                                    <div className="close" onClick={(e) => deleteAttach(audioList, item.uuid, index, 'image',e)}>X</div>
                                    <div className="play" onClick={() => audioPlay(item.file_path)}>
                                        <img src={playImg}/>
                                    </div>
                                    <div className="name">{item.filePath}</div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="attachmentPic" style={{display: attachArray.length > 0 ? 'block' : 'none'}}>
                <div className="attachmentPicTitle">
                    <span className="text">视频</span>
                    <span className="num">(+{videoList.length})</span>
                </div>
                <ul className="picUl">
                    {
                        videoList && videoList.map((item, index) => {
                            return (
                                <li className="picList" style={{border: `1px solid #cccccc`}} key={`video${index}`}>
                                    <img src={videoPlayBg}
                                         alt=""
                                         width="148" height="84"/>
                                    <div className="close" onClick={(e) => deleteAttach(videoList, item.uuid, index, 'image',e)}>X</div>
                                    <div className="play" onClick={() => videoPlay(item.file_path)}>
                                        <img src={playImg}/>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <Modal title="视频" visible={videoVisible} onOk={handleVideoConfirm} onCancel={handleVideoCancel}>
                <div>
                    <video id="movie" style={{ objectFit: 'fill', width: 400, height: 320 }}
                        src={process.env.REACT_APP_SERVER_PATH + `api/v1/event/capsule/downloadAttachment?file_path=${videoFilePath}`}
                        controls="controls"
                    />
                </div>
            </Modal>
        </div>
    )
}

export default Com
