import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Input, Modal, Space, Typography, message, Spin } from 'antd';
import { post } from '../../common/utils';
const { Text } = Typography;
const Comp = (props) => {
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const onCancel = () => {
        const { onCancel: propOnCancel } = props;
        if (loading) {
            message.info("创建中,请稍后！");
        } else {
            if (typeof propOnCancel === 'function') {
                propOnCancel();
            };
        };
    };
    const onOk = () => {
        if (typeof name === 'string' && name !== "") {
            setLoading(true);
            post(process.env.REACT_APP_SERVER_PATH + "api/grid/screens", {
                name,
            })
                .then((retData) => {
                    setLoading(false);
                    if (!!retData.data && typeof retData.data.id === 'number') {
                        //创建成功，id
                        props.history.push(`/DnDPage/${retData.data.id}`);
                    } else {
                        message.error("创建失败!");
                    };
                });
        } else {
            message.error("请输入名称!");
        };
    };
    return (
        <Modal title="创建新布局" visible={true} onOk={onOk} onCancel={onCancel} {...loading ? { okButtonProps: { disabled: true }, cancelButtonProps: { disabled: true } } : {}}>
            <Spin spinning={loading}>
                <Space>
                    <Text>名称</Text>
                    <Input placeholder="请输入名称" value={name} onChange={e => setName(e.target.value)} />
                </Space>
            </Spin>
        </Modal>
    );
};

export default withRouter(Comp);
