import { LineChartWrapper, BarChart } from '../../components';
const UID_CONFIG = [
    {
        uid: 'BOARD_TEST_01',
        comp: LineChartWrapper,
        name: '折线图',
    },
    {
        uid: 'BOARD_TEST_02',
        comp: BarChart,
        name: '柱状图',
    },
];
const template_uid_2_comp = UID_CONFIG
    .reduce((pV, cItem) => {
        const { uid, comp } = cItem;
        return Object.assign({}, pV, { [uid]: comp });
    }, {});

const GRID_ROW_HEIGHT = 25;
const GRID_MIN_HEIGHT = 8;
const GRID_MIN_WIDTH = 8;
const GRID_COLS = { lg: 48, md: 36, sm: 24, xs: 24, xxs: 12 };
const GRID_BREAK_POINTS = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
export {
    UID_CONFIG,
    template_uid_2_comp,
    GRID_ROW_HEIGHT,
    GRID_MIN_HEIGHT,
    GRID_MIN_WIDTH,
    GRID_COLS,
    GRID_BREAK_POINTS,
};