import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Input, Modal, message } from 'antd';
const { TextArea } = Input;
const Comp = (props) => {
    const { config } = props;
    const [configStr, setConfigStr] = useState("");
    useEffect(() => {
        let nextConfigStr = "";
        try {
            // config: json
            nextConfigStr = JSON.stringify(config, null, "\t");
        } catch (e) { };
        setConfigStr(nextConfigStr);
    }, [config]);
    const onCancel = () => {
        const { onCancel: propOnCancel } = props;
        if (typeof propOnCancel === 'function') {
            propOnCancel();
        };
    };
    const onOk = () => {
        const { onOk: propsOnOk } = props;
        if (typeof propsOnOk === 'function') {
            let retConfig = {};
            try {
                if (!!configStr) {
                    retConfig = JSON.parse(configStr);
                };
            } catch (e) {
                message.error("json格式有误，请修改后重试!");
            };
            propsOnOk(retConfig);
        };
    };
    return (
        <Modal title="编辑配置信息" visible={true} onOk={onOk} onCancel={onCancel} width={600}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TextArea rows={10} value={configStr} onChange={e => setConfigStr(e.target.value)} style={{ width: 500 }} />
            </div>
        </Modal>
    );
};

export default withRouter(Comp);
