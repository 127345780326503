import React from 'react';
import { Input, Button, Spin, Modal } from 'antd';
import md5 from 'md5';
import { post } from '../../common/utils.js';
import './Login.scss';

let PERMISSION_2_PAGE = {
    'read_order': '/navi/OrderList',
    // 'nbvw_overview': '',
}

class NormalLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spinLoading: false,
            remember: false,
        }
    }

    componentDidMount() {
        window.token_expired_notification = false;
        // if (getToken() != null) {
        //     this.setState({ spinLoading: true });
        //     setTimeout(() => {
        //         loadMyPermissions().then((permissions) => {
        //             self.setState({ spinLoading: false });
        //             let indexUrl = null;
        //             Object.keys(PERMISSION_2_PAGE).map(permission => {
        //                 if (permissions.indexOf(permission) >= 0) {
        //                     indexUrl = PERMISSION_2_PAGE[permission];
        //                 }
        //             })
        //             if (indexUrl == null) {
        //                 indexUrl = "/navi/OrderList";
        //             }
        //             self.props.history.replace(indexUrl);
        //         }).catch(() => {
        //             self.setState({ spinLoading: false });
        //             alert('权限信息获取失败，请刷新重试');
        //         });
        //     }, 1500);
        // }
    }

    handleSubmit = (e) => {
        console.log("登陆");
        // e.preventDefault();
        var self = this;
        if (!self.state.password && !self.state.name) {
            Modal.error({ title: '登陆失败', content: "用户名或密码错误" });
            return;
        };
        // this.props.form.validateFields((err, values) => {
        post(process.env.REACT_APP_SERVER_PATH + 'api/auth/password_login', {
            name: self.state.name,
            username: self.state.name,
            password: md5(self.state.password),
        }).then((retData) => {
            console.log(JSON.stringify(retData));
            if (retData.error === true) {
                Modal.error({ title: '登陆失败', content: '服务异常或网络出现错误' });
            } else if (retData && retData.data && retData.data.errcode === 0) {
                let storage = null;
                if (this.state.remember === true) {
                    storage = window.localStorage;
                    window.localStorage.setItem('PERSIST_SESSION', 'true');
                } else {
                    storage = window.sessionStorage;
                    window.localStorage.setItem('PERSIST_SESSION', 'false');
                }
                storage.setItem('USER_INFO', JSON.stringify({
                    user_id: retData.data.user_id,
                    token: retData.data.token,
                    time: new Date().getTime(),
                    name: retData.data.name,
                    expire_time: retData.data.token_expire_time,
                }));
                window.localStorage.setItem('CACHED_PERMISSION_LIST', JSON.stringify({
                    data: retData.data.permissions,
                    time: new Date().getTime(),
                    // expire_time: retData.data.token_expire_time,
                }));
                let indexUrl = null;
                Object.keys(PERMISSION_2_PAGE).map(permission => {
                    if (retData.data.permissions.indexOf(permission) >= 0) {
                        indexUrl = PERMISSION_2_PAGE[permission];
                    };
                    return null;
                });
                if (indexUrl == null) {
                    indexUrl = "";
                }
                self.props.history.replace(indexUrl);
            } else if (retData && retData.data && retData.data.errcode === 400) {
                Modal.error({ title: '登陆失败', content: "用户名或密码错误" });
            } else if (retData && retData.data && retData.data.errcode === 404) {
                Modal.error({ title: '登陆失败', content: "该账号不存在，请联系管理员" });
            } else if (retData && retData.data && retData.data.errcode === 405) {
                Modal.error({ title: '登陆失败', content: "用户账号已停用" });
            } else {
                Modal.error({ title: '登陆失败' });
            }
        })
        // }
        // });
    }

    render() {
        return (
            <Spin spinning={this.state.spinLoading}>
                <div onSubmit={this.handleSubmit} className="login-form logBox" >
                    <Input value={this.state.name} onChange={e => this.setState({ name: e.target.value })} style={{ height: 40 }} placeholder="用户名/手机号" />
                    <Input
                        value={this.state.password} onChange={e => this.setState({ password: e.target.value })}
                        style={{ marginTop: 16, height: 40 }} type="password" placeholder="密码"
                        onPressEnter={() => this.handleSubmit()}
                    />
                    <div className="pass_opt" style={{ marginTop: '24px' }} >
                        {
                            // <Checkbox value={this.state.checked}>记住密码</Checkbox>
                        }
                        <Button onClick={() => this.handleSubmit()} type="primary" htmlType="submit" className="login-form-button">
                            登录
                        </Button>
                    </div>
                </div>
            </Spin>
        );
    }
}

export default class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div id="loginFormOuterWrapper" style={{ backgroundColor: '#EDF7F8' }}>
                <div id="loginFormInnerWrapper">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="titleText">PEIMS生产能源一体化管理系统</div>
                    </div>
                    <NormalLoginForm history={this.props.history} />
                </div>
            </div>
        );
    }
}