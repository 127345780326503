import React from 'react';
import { Input, Spin, Modal } from 'antd';
import md5 from 'md5';
import { post } from '../../common/utils.js';
import style from './index.module.scss';
import IMG_LOGIN from './res/login.png';

let PERMISSION_2_PAGE = {
    'read_order': '/navi/OrderList',
    // 'nbvw_overview': '',
}

export default class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            spinLoading: false,
            remember: false,
            redirectHash: null,
        };
    }

    componentDidMount() {
        window.token_expired_notification = false;
        const redirect = new URLSearchParams(this.props.location.search).get('redirect');
        if (typeof redirect === 'string' && redirect !== "") {
            this.setState({ redirectHash: redirect });
        };
        // if (getToken() != null) {
        //     this.setState({ spinLoading: true });
        //     setTimeout(() => {
        //         loadMyPermissions().then((permissions) => {
        //             self.setState({ spinLoading: false });
        //             let indexUrl = null;
        //             Object.keys(PERMISSION_2_PAGE).map(permission => {
        //                 if (permissions.indexOf(permission) >= 0) {
        //                     indexUrl = PERMISSION_2_PAGE[permission];
        //                 }
        //             })
        //             if (indexUrl == null) {
        //                 indexUrl = "/navi/OrderList";
        //             }
        //             self.props.history.replace(indexUrl);
        //         }).catch(() => {
        //             self.setState({ spinLoading: false });
        //             alert('权限信息获取失败，请刷新重试');
        //         });
        //     }, 1500);
        // }
    }

    handleSubmit = (e) => {
        console.log("登陆");
        // e.preventDefault();
        var self = this;
        if (!self.state.password && !self.state.name) {
            Modal.error({ title: '登陆失败', content: "用户名或密码错误" });
            return;
        };
        // this.props.form.validateFields((err, values) => {
        post(process.env.REACT_APP_SERVER_PATH + 'api/auth/password_login', {
            username: self.state.name,
            password: md5(self.state.password),
        }).then((retData) => {
            console.log(JSON.stringify(retData));
            if (retData.error === true) {
                Modal.error({ title: '登陆失败', content: '服务异常或网络出现错误' });
            } else if (retData && retData.data && retData.data.errcode === 0) {
                let storage = null;
                if (this.state.remember === true) {
                    storage = window.localStorage;
                    window.localStorage.setItem('PERSIST_SESSION', 'true');
                } else {
                    storage = window.sessionStorage;
                    window.localStorage.setItem('PERSIST_SESSION', 'false');
                }
                storage.setItem('USER_INFO', JSON.stringify({
                    user_id: retData.data.user_id,
                    token: retData.data.token,
                    time: new Date().getTime(),
                    name: retData.data.name,
                    expire_time: retData.data.token_expire_time,
                }));
                window.localStorage.setItem('CACHED_PERMISSION_LIST', JSON.stringify({
                    data: retData.data.permissions,
                    time: new Date().getTime(),
                    // expire_time: retData.data.token_expire_time,
                }));
                let indexUrl = null;
                if (typeof this.state.redirectHash === "string") {
                    indexUrl = this.state.redirectHash;
                };
                Object.keys(PERMISSION_2_PAGE).map(permission => {
                    if (retData.data.permissions.indexOf(permission) >= 0) {
                        indexUrl = PERMISSION_2_PAGE[permission];
                    };
                    return null;
                });
                if (indexUrl == null) {
                    indexUrl = "";
                }
                self.props.history.replace(indexUrl);
            } else if (retData && retData.data && retData.data.errcode === 400) {
                Modal.error({ title: '登陆失败', content: "用户名或密码错误" });
            } else if (retData && retData.data && retData.data.errcode === 404) {
                Modal.error({ title: '登陆失败', content: "该账号不存在，请联系管理员" });
            } else if (retData && retData.data && retData.data.errcode === 405) {
                Modal.error({ title: '登陆失败', content: "用户账号已停用" });
            } else {
                Modal.error({ title: '登陆失败' });
            }
        })
        // }
        // });
    }

    render() {
        return (
            <div className={style["wrapper"]}>
                <div className="form_wrapper">
                    <div className="all_circle_wrapper">
                        <div className="circle_1"></div>
                        <div className="circle_2"></div>
                        <div className="circle_3"></div>
                        <div className="circle_group_wrapper"></div>
                    </div>
                    <div className="left">
                        <img alt="" src={IMG_LOGIN} className="bck_img" />
                        <div className="slogan">工厂管理更简单！</div>
                    </div>
                    <div className="right">
                        <Spin spinning={this.state.spinLoading}>
                            <div className="content_wrapper">
                                <div className="title_wrapper">
                                    <div className="text_1">轻环</div>
                                    <div className="text_2">生产能源一体化系统</div>
                                </div>
                                <div className="title title_1">用户名/手机号</div>
                                <Input
                                    placeholder="请输入用户名/手机号" bordered={false}
                                    style={{ fontSize: 18, padding: 0, height: 32, lineHeight: '32px', marginTop: 23, color: '#999' }}
                                    value={this.state.name} onChange={e => this.setState({ name: e.target.value })}
                                />
                                <div className="line"></div>
                                <div className="title title_2">密码</div>
                                <Input.Password
                                    visibilityToggle={false} placeholder="请输入密码" bordered={false}
                                    style={{ fontSize: 18, padding: 0, height: 32, lineHeight: '32px', marginTop: 23, color: '#999' }}
                                    onPressEnter={() => this.handleSubmit()}
                                    value={this.state.password} onChange={e => this.setState({ password: e.target.value })}
                                />
                                <div className="line"></div>
                                <div className="btn_wrapper" onClick={() => this.handleSubmit()}>
                                    <div className="text">登录</div>
                                </div>
                            </div>
                        </Spin>
                    </div>
                </div>
            </div>
        );
    }
}