import ExamInfoBar from './ExamInfoBar.js';
import { connect } from 'react-redux';
import {
    setValues,
    setInitialValues,
} from '../../../common/redux/ExamAnalysis/actions.js';
import { post, get, getCachedUserInfo } from '../../../common/utils';
const moment = require('moment');

const mapStateToProps = state => {
    const { examAnalysis } = state;
    const { } = examAnalysis;
    return {
        ...examAnalysis,
    };
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    };
}

const WrapperedExamInfoBar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ExamInfoBar);

export default WrapperedExamInfoBar;