import React, { useEffect, useMemo, useRef, useState } from 'react';
import style from './index.module.scss';
import Wrapper from '../Wrapper';
import Item from './Item';
import ICON from '../res/icon_0.svg';
import { useList } from './hooks';
import { withRouter } from 'react-router';
import { Spin } from 'antd';
import SortIcons from 'components/SortIcons';

const Comp = (props) => {
    const { timeInfo } = props;

    const [list, setList, loading] = useList(null);
    const setListFunc = useRef(null);
    useEffect(() => {
        let nextFunc = () => { };
        if (typeof setList === 'function') nextFunc = setList;
        setListFunc.current = nextFunc;
    }, [setList]);

    const [sortInfo, setSortInfo] = useState(null);

    const sorted_list = useMemo(() => {
        if (!!list) {
            let ret_list = []
                .concat(list);
            if (!!sortInfo) {
                const { key, is_desc } = sortInfo;
                ret_list = []
                    .concat(ret_list)
                    .sort(({ [key]: v1 }, { [key]: v2 }) => {
                        const v_1 = is_desc ? v1 : v2;
                        const v_2 = is_desc ? v2 : v1;
                        if (typeof v_1 === 'number' && typeof v_2 === 'number') {
                            return v_2 - v_1;
                        };
                        if (typeof v1 === 'number') return -1;
                        if (typeof v2 === 'number') return 1;
                        return 0;
                        // 1是交换
                    });
            };
            ret_list = []
                .concat(ret_list)
                .sort(({ cpl_rate: cpl_rate_1 }, { cpl_rate: cpl_rate_2 }) => {
                    if (typeof cpl_rate_1 === 'number' && typeof cpl_rate_2 === 'number') {
                        return 0;
                    };
                    if (typeof cpl_rate_1 === 'number') return -1;
                    if (typeof cpl_rate_2 === 'number') return 1;
                    return 0;
                    // 1是交换
                });
            return ret_list;
        };
        return null;
    }, [list, sortInfo]);

    useEffect(() => {
        if (!!timeInfo) {
            setListFunc.current(timeInfo);
        };
    }, [timeInfo]);

    const itemOnClick = (item) => {
        let suffix = `id=${item.id}`;
        if (!!timeInfo) {
            suffix += `&time=${timeInfo.time}&timeKey=${timeInfo.key}`;
        };
        const pathname = props?.history?.location?.pathname ?? null;
        if (typeof pathname === 'string' && pathname !== "") {
            const layoutName = pathname.split("/")[1] ?? null;
            if (typeof layoutName === 'string' && layoutName !== "") {
                props.history.push(`/${layoutName}/Analysis?${suffix}`);
                return;
            };
        };
        props.history.push(`/PEIMSV2/Analysis?${suffix}`);
    };

    const sortOnClick = (key) => {
        setSortInfo((prevSortInfo) => {
            let nextSortInfo = null;
            if (typeof key === 'string') {
                let nextIsDesc = false;
                if (!!prevSortInfo) {
                    if (prevSortInfo.key === key) {
                        nextIsDesc = !prevSortInfo.is_desc;
                    };
                };
                nextSortInfo = { key, is_desc: nextIsDesc };
            };
            return nextSortInfo;
        });
    };

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={ICON}
                title="关键指标"
                watchDetail={false}
            >
                <div className={style['content']}>
                    <div style={{ height: 44 }} className="title_wrapper">
                        <div className="title_1">
                            <div>指标名称</div>
                            <div className="sort">
                                <div className="wrapper" onClick={() => sortOnClick("cpl_rate")}>
                                    <div className="content">
                                        <div className="text">合格率</div>
                                        <SortIcons is_desc={sortInfo?.key === "cpl_rate" ? sortInfo?.is_desc : null} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="title_2">指标均值</div>
                        <div className="title_3">过去7{timeInfo?.cn_text ?? "周期"}趋势</div>
                    </div>
                    <div className="scroll_wrapper scroll_bar_restyle scroll_bar_restyle_2">
                        <div className="content">
                            {
                                (sorted_list ?? [])
                                    .map((item, index) => {
                                        return [
                                            <div
                                                key={index}
                                                className="item_wrapper"
                                                onClick={() => itemOnClick(item)}
                                            >
                                                <Item {...item} />
                                            </div>
                                            ,
                                            <div style={{ height: 12 }} key={`space_${index}`}></div>
                                        ];
                                    })
                            }
                        </div>
                    </div>
                    {
                        loading && (
                            <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                                <Spin tip="加载中..." />
                            </div>
                        )
                    }
                </div>
            </Wrapper>
        </div>
    );
};

export default withRouter(Comp);
