import React, { useEffect, useRef, useState } from 'react';
import { get, patch } from '../../common/utils';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Button, message, Typography, Space, Divider, Modal, DatePicker } from 'antd';
import style from './index.module.scss';
import ItemWrapper from './ItemWrapper.js';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import CreateItemModal from './CreateItemModal.js';
import { GRID_ROW_HEIGHT, GRID_MIN_HEIGHT, GRID_MIN_WIDTH, GRID_COLS, GRID_BREAK_POINTS } from './constants.js';
// console.log(style);
const { Title } = Typography;
const { RangePicker } = DatePicker;
const ResponsiveGridLayout = WidthProvider(Responsive);
//BOARD_TEST_01
const Page = (props) => {
    const id = useRef(props.match.params.id);
    const [screen, setScreen] = useState(null);
    const [layoutChange, setLayoutChange] = useState(null);
    const [visible, setVisible] = useState(false);
    const [range, setRange] = useState(null);

    // layout 配置相关
    const [layout, setLayout] = useState(null);
    const formattedLayout = useRef(layout);

    // 实例变量
    // mode
    const mode = useRef(new URLSearchParams(props.location.search).get('mode'));
    const isViewMode = useRef(mode.current === 'view');
    // grid_config
    const rowHeight = useRef(GRID_ROW_HEIGHT);
    const cols = useRef(GRID_COLS);
    const breakpoints = useRef(GRID_BREAK_POINTS);
    const minHeight = useRef(GRID_MIN_HEIGHT);
    const minWidth = useRef(GRID_MIN_WIDTH);
    // render times
    const renderTimes = useRef(0);
    useEffect(() => { renderTimes.current++; });

    const parseLayout = (layout) => {
        return layout
            .map((item) => {
                const { h, w } = item;
                return {
                    ...item,
                    minH: minHeight.current,
                    minW: minWidth.current,
                    ...isViewMode.current ? {
                        static: true,
                    } : {},
                    h: (h > minHeight.current ? h : minHeight.current),
                    w: (w > minWidth.current ? w : minWidth.current),
                };
            });
    };

    useEffect(() => {
        get(process.env.REACT_APP_SERVER_PATH + `api/grid/screens/${id.current}`, {})
            .then((retData) => {
                console.log(retData);
                if (!!retData.data && typeof retData.data.id === 'number') {
                    setScreen(retData.data);
                    try {
                        if (!!retData.data.config) {
                            const config = JSON.parse(retData.data.config);
                            let nextLayout = [];
                            if (!!config.layout) {
                                nextLayout = config.layout;
                                if (!!config.grid_config) {
                                    const gConf = config.grid_config;
                                    // row_height, cols, breakpoints, min_height, min_width
                                    if (!!gConf.row_height) {
                                        rowHeight.current = gConf.row_height;
                                    };
                                    if (!!gConf.cols) {
                                        cols.current = gConf.cols;
                                    };
                                    if (!!gConf.breakpoints) {
                                        breakpoints.current = gConf.breakpoints;
                                    };
                                    if (!!gConf.min_height) {
                                        minHeight.current = gConf.min_height;
                                    };
                                    if (!!gConf.min_width) {
                                        minWidth.current = gConf.min_width;
                                    };
                                };
                            } else if (typeof config.length === 'number') {
                                nextLayout = config;
                            };
                            nextLayout = parseLayout(nextLayout);
                            setLayout(nextLayout);
                        };
                    } catch (e) { };
                } else {
                    message.error("获取信息失败,请刷新重试!");
                };
            });
    }, []);
    const onResizeStop = () => {
        setLayoutChange(Symbol());
    };
    const onLayoutChange = (v) => {
        formattedLayout.current = v;
    };
    const addBlockOnClick = () => {
        setVisible(true);
        return;
    };
    const saveLayout = () => {
        //saveLayout
        const newLayout = formattedLayout.current.map((item) => {
            const { i, x, y, w, h } = item;
            return { i, x, y, w, h };
        });
        const newGridLayout = {
            row_height: rowHeight.current,
            cols: cols.current,
            breakpoints: breakpoints.current,
            min_height: minHeight.current,
            min_width: minWidth.current,
        };
        const newConfig = {
            layout: newLayout,
            grid_config: newGridLayout,
        };
        console.log(newConfig, JSON.stringify(newConfig));

        patch(process.env.REACT_APP_SERVER_PATH + `api/grid/screens/${id.current}`, {
            config: JSON.stringify(newConfig),
        })
            .then((retData) => {
                // console.log(retData);
                if (!!retData.data && typeof retData.data.id === 'number') {
                    message.success("保存成功!");
                } else {
                    message.error("保存布局信息失败,请重试!");
                };
            });
    };
    const deleteItem = (i) => {
        console.log(i, "deleteItem");
        Modal.confirm({
            content: `确认删除该看板吗?`,
            onOk: () => {
                const nextLayout = [].concat(formattedLayout.current).filter(({ i: cI }) => cI !== i);
                setLayout(nextLayout);
            },
        });
    };
    const editItem = (i) => {
        console.log(i, "editItem");
        props.history.push(`/BoardEdit/${i}`);
    };
    const return2List = () => {
        props.history.push(`/ScreenList`);
    }
    const createItemOnOk = (item) => {
        let nextLayout = [].concat(formattedLayout.current).concat([{
            i: item.id + '',
            x: 0,
            y: 0,
            w: minWidth.current,
            h: minHeight.current,
        }]);
        nextLayout = parseLayout(nextLayout);
        setLayout(nextLayout);
        setVisible(false);
    };
    const rangeOnChange = (v) => {
        // console.log(v);
        setRange(v);
    };
    console.log(layout, 'new', renderTimes.current);
    return screen !== null && (
        <div className={style['wrapper']}>
            <div className="header">
                <Title level={3}>{screen.name}</Title>
                <Space style={{ marginLeft: 'auto' }}>
                    <RangePicker
                        value={range}
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        onChange={rangeOnChange}
                    />
                    {
                        !isViewMode.current && (
                            <Button type="default" onClick={saveLayout}>保存布局</Button>
                        )
                    }
                    <Button type="primary" onClick={return2List}>返回</Button>
                    {
                        !isViewMode.current && ([
                            <Divider type="vertical" key="1" />
                            ,
                            <Button type="primary" onClick={addBlockOnClick} key="2">添加模块</Button>
                        ])
                    }
                </Space>
            </div>
            {
                !!layout && (
                    <div className="grid_wrapper">
                        <ResponsiveGridLayout
                            className="layout"
                            // layout={{
                            //     lg: layout,
                            // }}
                            rowHeight={rowHeight.current}
                            cols={cols.current}
                            breakpoints={breakpoints.current}
                            // onDragStop={onDragStop}
                            onResizeStop={onResizeStop}
                            // onDrop={onDrop}
                            onLayoutChange={onLayoutChange}
                            verticalCompact={false}
                            draggableCancel=".tool_tip"
                        >
                            {
                                layout
                                    .map((item, index) => {
                                        const { i, x, y, w, h, static: staticKey = false, minH, minW } = item;
                                        // console.log(item);
                                        return (
                                            <div key={i} data-grid={{ x, y, w, h, static: staticKey, minH, minW }} style={{ border: '1px solid rgba(0,0,255,0.2)', position: 'relative', overflow: 'hidden' }} className="layout_item">
                                                <ItemWrapper layoutChange={layoutChange} id={parseInt(i)} range={range} />
                                                {
                                                    !isViewMode.current && (
                                                        <div className="tool_tip">
                                                            <Space>
                                                                <DeleteOutlined onClick={() => deleteItem(i)} />
                                                                <FormOutlined onClick={() => editItem(i)} />
                                                            </Space>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        );
                                    })
                            }
                        </ResponsiveGridLayout>
                    </div>
                )
            }
            {
                visible && (
                    <CreateItemModal onCancel={() => setVisible(false)} id={id.current} onOk={createItemOnOk} />
                )
            }
        </div>
    );
};

export default Page;
