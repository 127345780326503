import React from 'react';
import { message } from 'antd';
import { get, post } from '../../common/utils.js';
import './DiagramGen.scss';
import LeftSettingPart from './LeftSettingPart';
import PicEditPart from './PicEditPart';
import MidWrapperPart from './MidWrapperPart';
import RightWrapperPart from './RightWrapperPart';

export default class DiagramGen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            nowSelected: null,
            groupSelected: [],
            dataPoint: [],
            bckPic: null,
            bckSize: null,
            diagramConf: null,
            showAll: true,
            boardBckSize: null,
        };
    }
    componentDidMount() {
        console.log("this is DiagramGen page");
        console.log(this.props.match.params);
        // this.setInitialDataPoint(AliasList[this.props.match.params.deviceId]);
        this.setInitialDataPoint();
        this.setInitialState();
    }
    setInitialState() {
        let self = this, keyList = ['listData', 'bckPic', 'bckSize'], dataPointMergeCount = 0;
        get(process.env.REACT_APP_SERVER_PATH + 'api/diagrams/' + this.props.match.params.id).then(retData => {
            console.log(retData);
            if (retData && retData.data) {
                self.setState({ diagramConf: retData.data });
                try {
                    if (retData.data.content !== null) {
                        let nState = JSON.parse(retData.data.content);
                        if (typeof nState === 'object') {
                            keyList.forEach(key => {
                                if (nState[key]) self.state[key] = nState[key];
                            })
                            self.state.listData.forEach(item => {
                                item.detailItemNowSelected = null;
                                item.dataPoint && (self.state.dataPoint.length > 0) && (dataPointMergeCount += self.mergeDataPoint(item.dataPoint, self.state.dataPoint));
                            })
                        }
                        self.state.boardBckSize = nState.bckSize;
                    }
                } catch (e) {
                    alert("输入数据有误，请重新输入");
                }
                self.setState(self.state);
            }
        })
    }
    mergeDataPoint = (newDataPoint, dataPointList) => {
        let i;
        for (i = 0; i < dataPointList.length; i++) {
            if ((dataPointList[i].value + '') === (newDataPoint + '')) return 0;
        }
        dataPointList.unshift({ value: newDataPoint, name: '[merge]' + newDataPoint });
        return 1;
    }
    setInitialDataPoint() {
        // let dataPoint = this.state.dataPoint, self = this, dataPointMergeCount = 0;
        post(process.env.REACT_APP_SERVER_PATH + 'api/meta_data/list', {
            // status: 1,
            page: 1,
            page_size: 5000,
            // keyword: '煤',
        }).then(retData => {
            console.log(retData);
            if (retData.data && retData.data.results) {
                this.setState({ dataPoint: retData.data.results.map(({ name, tag_alias }) => ({ name, value: tag_alias })) });
            }
        })
        //从服务器读列表
        // get(Config.deviceApi.API_DATA_METAS, {
        //     device_id: self.props.match.params.deviceId,
        //     page_size: 1000
        // }).then(retData => {
        //     console.log(retData);
        //     if (retData && retData.data && retData.data.results) {
        //         retData.data.results.forEach(item => {
        //             //PC端，配置文件alert_list里的tag_alias为空字符串时，点击“添加”，然后对齐点击后会崩溃
        //             typeof item.tag_alias !== 'undefined' && item.tag_alias !== '' && dataPoint.push({ value: item.tag_alias, name: "(" + item.id + ")" + item.tag_alias });
        //         })
        //         if (self.state.listData.length > 0) {
        //             self.state.listData.forEach(item => {
        //                 item.dataPoint && (dataPointMergeCount += self.mergeDataPoint(item.dataPoint, dataPoint));
        //             })
        //         }
        //         self.setState({ dataPoint });
        //     }
        // })
    }
    componentSetState = (obj, cb) => {
        //定时保存
        localStorage.setItem("diagramGenLatestData", JSON.stringify(this.state));
        this.setState(obj, typeof cb === 'function' ? cb : () => { });
    }
    onFilesChange = refName => {
        let self = this;
        const file = document.getElementById(refName).files[0];

        var formData = new FormData();
        formData.append('file', file);
        formData.append('diagram_id', this.props.match.params.id);

        post(process.env.REACT_APP_SERVER_PATH + 'api/img/upload', formData)
            .then((retData) => {
                console.log(retData);
                if (!!retData.data && retData.data.errcode === 0) {
                    message.success("图片处理完成");
                    const fileName = retData.data.img_name;
                    if (refName === "bckId") {
                        self.setState({ bckPic: fileName, bckSize: null });
                    } else {
                        let listData = self.state.listData, detailIndex = listData[self.state.nowSelected].detailItemNowSelected;
                        listData[self.state.nowSelected].details[detailIndex].img = fileName;
                        self.setState({ listData });
                    }
                } else {
                    message.error("图片处理失败");
                };
            });
    }
    render() {
        let self = this,
            propsConf = {
                data: self.state,
                componentSetState: self.componentSetState
            };
        return (
            <div className="DiagramGenWrapper">
                <div className="LeftWrapper">
                    <LeftSettingPart {...propsConf} onFilesChange={self.onFilesChange} />
                    <PicEditPart className="editWrapper" {...propsConf} />
                </div>
                <MidWrapperPart {...propsConf} />
                <RightWrapperPart {...propsConf} onFilesChange={self.onFilesChange} />
            </div>
        );
    }
}
