import React, { useContext, useEffect, useState, useMemo } from 'react';
import style from './index.module.scss';
import CommonContext from '../context';
import { post } from 'common/utils';
import moment from 'moment';
import TrendChart from './TrendChart';
import { message, Spin } from 'antd';

const getTimeRange = (timeType, timeStr) => {
    if (timeType === 'date') {
        return [moment(timeStr).add(-6, 'days').format("YYYY-MM-DD 00:00:00"), moment(timeStr).add(1, 'days').format("YYYY-MM-DD 00:05:00")];
    } else if (timeType === 'week') {
        return [moment(timeStr).add(-1, 'weeks').startOf('week').format("YYYY-MM-DD 00:00:00"), moment(timeStr).endOf('week').format("YYYY-MM-DD 00:05:00")];
    } else if (timeType === 'month') {
        return [moment(timeStr).add(-2, 'months').startOf('month').format("YYYY-MM-DD 00:00:00"), moment(timeStr).endOf('month').format("YYYY-MM-DD 00:05:00")];
    };
    return null;
};

const Comp = (props) => {
    const commonInfo = useContext(CommonContext);
    const { state: { time: [timeTab, timeStr] } } = commonInfo;
    const [results, setResults] = useState(null);
    const [[statusSort, chainSort], setSort] = useState([null, null]);
    const [selectedDevice] = useMemo(() => {
        if (!!commonInfo) {
            return [commonInfo.state.selectedDevice];
        };
        return [null];
    }, [commonInfo]);

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!!selectedDevice) {
            setLoading(true);
            post(process.env.REACT_APP_SERVER_PATH + 'api/meter_node/get_meter_table_report', {
                "meter_id": selectedDevice.id,
                "day_begin": moment(timeStr).format("YYYY-MM-DD"),
                "time_type": timeTab.queryStr,
            })
                .then((retData) => {
                    // console.log(retData);
                    if (!!retData.data && retData.data.errcode === 0) {
                        let nextResults = retData.data.results;
                        const tag_list = nextResults.map(({ trend_tag }) => trend_tag);
                        const [his_time_begin, his_time_end] = getTimeRange(timeTab.tag, timeStr) || [];
                        post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data', {
                            tag_list: tag_list
                                .map((tag_name) => {
                                    return {
                                        tag_name,
                                        time_begin: his_time_begin,
                                        time_end: his_time_end,
                                    }
                                }),
                        })
                            .then((retData) => {
                                setLoading(false);
                                // console.log(retData);
                                if (!!retData.data && retData.data.errcode === 0) {
                                    if (retData.data.results.length > 0) {
                                        // renderChart(chartNode.current, retData.data.results[0].data_list);
                                        nextResults = nextResults.map((item) => {
                                            const { trend_tag } = item;
                                            const result = retData.data.results.filter(({ tag_name }) => tag_name === trend_tag)[0] || null;
                                            return Object.assign({}, item, !!result ? { data_list: result.data_list } : {});
                                        });
                                    };
                                };
                                // console.log(nextResults);
                                setResults(nextResults);
                            });
                    } else {
                        // 第一请求失败，提示
                        message.error("数据请求失败");
                        setLoading(false);
                        setResults(null);
                    };
                });
        };
    }, [timeTab, timeStr, selectedDevice]);

    // const statusSortOnClick = () => {
    //     const nextStatusSort = statusSort === null ? 'up' : (statusSort === 'up' ? 'down' : 'up');
    //     setSort([nextStatusSort, null]);
    // };

    const chainSortOnClick = () => {
        const nextChainSort = chainSort === null ? 'up' : (chainSort === 'up' ? 'down' : 'up');
        setSort([null, nextChainSort]);
    };

    const sortedResults = useMemo(() => {
        if (!results) return null;
        let ret = results;
        if (chainSort !== null) {//chain_comp
            ret = [].concat(ret).sort((a, b) => { return chainSort === 'up' ? (a.chain_comp - b.chain_comp) : (b.chain_comp - a.chain_comp) });
        } else if (statusSort !== null) {//alarm_status
            ret = [].concat(ret).sort((a, b) => { return statusSort === 'up' ? (a.alarm_status - b.alarm_status) : (b.alarm_status - a.alarm_status) });
        };
        return ret;
    }, [results, statusSort, chainSort]);

    return (
        <div className={style['wrapper']}>
            <div className="content_wrapper">
                <div className="table_wrapper">
                    <table>
                        <thead>
                            <tr>
                                {/* <th style={{ textAlign: 'center', width: 180 }}>
                                    <div className="sort_header_wrapper">
                                        <div className="inner_wrapper" onClick={statusSortOnClick}>
                                            <div className="title">状态</div>
                                            <div className="tips">
                                                <div className={"up" + (statusSort === 'up' ? " selected" : "")}></div>
                                                <div className={"down" + (statusSort === 'down' ? " selected" : "")}></div>
                                            </div>
                                        </div>
                                    </div>
                                </th> */}
                                <th>指标名称</th>
                                <th style={{ textAlign: 'center', minWidth: 200 }}>实际值</th>
                                <th style={{ width: 376 }}></th>
                                <th style={{ width: 160 }}>
                                    <div className="sort_header_wrapper">
                                        <div className="inner_wrapper" onClick={chainSortOnClick}>
                                            <div className="title">环比</div>
                                            <div className="tips">
                                                <div className={"up" + (chainSort === 'up' ? " selected" : "")}></div>
                                                <div className={"down" + (chainSort === 'down' ? " selected" : "")}></div>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                                <th style={{ textAlign: 'center', width: 240 }}>差值</th>
                            </tr>
                            <tr>
                                <th colSpan="6">
                                    <div style={{ height: 1, marginTop: 16 }} />
                                </th>
                            </tr>
                        </thead>
                        {
                            !!sortedResults && (
                                <tbody>
                                    {
                                        sortedResults
                                            .map((item, index) => {
                                                const {
                                                    // alarm_status,
                                                    name, value, unit, trend_tag, chain_comp, diff, data_list
                                                } = item;
                                                let valueText = '--';
                                                if (typeof value === 'number') {
                                                    valueText = Math.floor(value * 100) / 100;
                                                };
                                                let chainText = '--';
                                                if (typeof chain_comp === 'number') {
                                                    chainText = Math.floor(chain_comp * 100) / 100;
                                                };
                                                let diffText = '--';
                                                if (typeof diff === 'number') {
                                                    diffText = Math.floor(diff * 100) / 100;
                                                };
                                                let unitText = "";
                                                if (typeof unit === 'string') {
                                                    unitText = unit;
                                                };
                                                return (
                                                    <tr key={`${trend_tag}_${index}`}>
                                                        {/* <td></td> */}
                                                        <td className="name">{name}</td>
                                                        <td>
                                                            <div className="value_wrapper">
                                                                <div className="value">{valueText}</div>
                                                                <div className="space"></div>
                                                                <div className="unit">{unitText}</div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <TrendChart trendTag={trend_tag} data_list={data_list} />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {`${chainText} %`}
                                                            {/* <div className="value_wrapper">
                                                                <div className="value">{chainText}</div>
                                                                <div className="space"></div>
                                                                <div className="unit">%</div>
                                                            </div> */}
                                                        </td>
                                                        <td>
                                                            {`${diffText} ${unit}`}
                                                            {/* <div className="value_wrapper">
                                                                <div className="value">{diffText}</div>
                                                                <div className="space"></div>
                                                                <div className="unit">{unit}</div>
                                                            </div> */}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                    }
                                </tbody>
                            )
                        }
                    </table>
                </div>
            </div>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)',borderRadius:16 }}>
                        <Spin />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
