import React, { useEffect, useMemo, useRef, useState } from 'react';
import { get, patch } from '../../common/utils';
import { Button, message, Typography, Select, Space, Divider, Checkbox, Input, Popover } from 'antd';
import style from './index.module.scss';
import { LineChartWrapper } from '../../components';
import JSONEditModal from './JSONEditModal.js';
import { SwatchesPicker } from 'react-color';
import { CheckIsColor } from '../../components/LineChart/utils.js';
// console.log(style);
const { Text } = Typography;
const { Option } = Select;
const default_LineC = "#40A1E1";
const tag_name_list = [
    {
        id: 1,
        tag_name: "FY5423A",
        name: "生料台时A",
    },
    {
        id: 2,
        tag_name: "FY5423B",
        name: "生料台时B",
    },
];
//BOARD_TEST_01
const Page = (props) => {
    const id = useRef(props.match.params.id);
    const [point, setPoint] = useState(true);
    const [title, setTitle] = useState("");
    const [visible, setVisible] = useState(false);
    const [screenId, setScreenId] = useState(null);
    const [selectedTagName, setSelectedTagName] = useState(null);
    const [lineColor, setLineColor] = useState(default_LineC);
    const config = useMemo(() => {
        return {
            point, color: lineColor, title, tag_name: selectedTagName,
        };
    }, [lineColor, point, title, selectedTagName]);
    useEffect(() => {
        get(process.env.REACT_APP_SERVER_PATH + `api/grid/boards`, {
            ids: id.current + "",
        })
            .then((retData) => {
                console.log(retData);
                if (!!retData.data && !!retData.data.results && retData.data.results.length === 1) {
                    // setScreen(retData.data);
                    try {
                        if (!!retData.data.results[0].config) {
                            let config = JSON.parse(retData.data.results[0].config);
                            parseConfig(config);
                        };
                    } catch (e) { };
                    setScreenId(retData.data.results[0].screen_id);
                } else {
                    message.error("获取信息失败,请刷新重试!");
                };
            });
    }, []);// 如果外部传进来的id发生了变化, 不理会
    const parseConfig = (config) => {
        // console.log(config, JSON.stringify(config, null, "\t"))
        if (typeof config.point === 'boolean') {
            setPoint(config.point);
        };
        if (!!CheckIsColor(config.color)) {
            setLineColor(config.color);
        };
        if (!!config.title) {
            setTitle(config.title);
        };
        if (!!config.tag_name) {
            setSelectedTagName(config.tag_name);
        };
    };
    const saveEdit = () => {
        const newConfig = JSON.stringify(config);
        patch(process.env.REACT_APP_SERVER_PATH + `api/grid/boards/${id.current}`, {
            config: newConfig,
        })
            .then((retData) => {
                console.log(retData);
                if (!!retData.data && typeof retData.data.id === 'number') {
                    message.success("保存成功！");
                    if (typeof screenId === 'number') {
                        props.history.push(`/DnDPage/${screenId}`);
                    };
                } else {
                    message.error("保存设置失败,请重试!");
                };
            });
    };
    const handleChangeComplete = (color) => {
        setLineColor(color.hex);
    };
    const jsonEditOnOk = (v) => {
        parseConfig(v);
        setVisible(false);
    };
    const tagNameOnChange = (v) => {
        setSelectedTagName(v);
    };
    return (
        <div className={style['wrapper']}>
            <div className="display_block">
                <LineChartWrapper config={config} />
            </div>
            <div className="edit_block">
                <Divider />
                <Space direction="vertical">
                    <Space>
                        <Text>标题</Text>
                        <Input placeholder="请输入标题名" value={title} onChange={e => setTitle(e.target.value)} style={{ width: 200 }} />
                    </Space>
                    <Space>
                        <Text>选择数据点</Text>
                        <Select value={selectedTagName} onChange={tagNameOnChange} style={{ width: 200 }}>
                            {
                                tag_name_list
                                    .map((item, index) => {
                                        const { name, tag_name, id } = item;
                                        return (
                                            <Option key={id} value={tag_name}>{name} {tag_name}</Option>
                                        );
                                    })
                            }
                        </Select>
                    </Space>
                    <Space>
                        <Text>显示数据标记图形</Text>
                        <Checkbox checked={point} onChange={e => setPoint(e.target.checked)} />
                    </Space>
                    <Space>
                        <Text>线条颜色</Text>
                        <Popover
                            title="选择颜色"
                            // trigger="click"
                            placement="right"
                            content={(
                                <Space direction="vertical">
                                    <SwatchesPicker
                                        color={lineColor}
                                        onChangeComplete={handleChangeComplete}
                                    />
                                </Space>
                            )}
                        >
                            <div style={{ width: 100, height: 30, backgroundColor: lineColor, borderRadius: 5, cursor: 'pointer' }} />
                        </Popover>
                    </Space>
                </Space>
                <Divider />
                <Space>
                    <Button type="primary" onClick={saveEdit}>保存修改</Button>
                    <Button type="default" onClick={() => setVisible(true)}>编辑JSON</Button>
                </Space>
            </div>
            {
                visible && (
                    <JSONEditModal onCancel={() => setVisible(false)} onOk={jsonEditOnOk} config={config} />
                )
            }
        </div>
    );
};

export default Page;
