import {
  AppstoreOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  MailOutlined
} from '@ant-design/icons'
const MenuList = [
  {
    key: 'sub1',
    title: '看板',
    icon: <i className="anticon menu-icon board" />,
    level: 1,
    childrens: [
      { key: '11', title: '综合看板', level: 2, path: '/PEIMSV2/Overview' },
      { key: '12', title: '运行看板', level: 2, path: '/PEIMSV2/OperatingBoard' },
      {
        key: '13',
        title: '操作看板',
        level: 2,
        childrens: [
          { key: '131', title: '对标', level: 3, path: '/PEIMSV2/Board/Energy' },
          { key: '132', title: '报警', level: 3, path: '/PEIMSV2/Board/Warning' },
          { key: '133', title: '竞赛', level: 3, path: '/PEIMSV2/Board/ExamAnalysis' },
          { key: '134', title: '数据趋势', level: 3, path: '/PEIMSV2/Board/HistoryData' },
          { key: '135', title: '样本维护', level: 3, path: '/PEIMSV2/Board/SampleMaintenance' }
        ]
      },
      { key: '14', title: '大屏展示', level: 2, path: '/PEIMSV2/ScreenShow' }
    ]
  },
  {
    key: 'sub2',
    title: '事件',
    icon: <i className="anticon menu-icon events" />,
    level: 1,
    childrens: [
      { key: '21', title: '事件看板', level: 2, path: '/PEIMSV2/EventBoard' },
      { key: '22', title: '事件列表', level: 2, path: '/PEIMSV2/EventSummary' }
    ]
  },
  {
    key: 'sub3',
    title: '监控',
    icon: <i className="anticon menu-icon monitor" />,
    level: 1,
    childrens: [
      { key: '31', title: '工艺流程', level: 2, path: '/PEIMSV2/ProcessPage' },
      { key: '32', title: '主要设备', level: 2, path: '/PEIMSV2/DeviceStatus' },
      { key: '33', title: '电表监测', level: 2, 
      path: '/PEIMSV2/ElecManagement' 
    }
    ]
  },
  {
    key: 'sub4',
    title: '指标',
    icon: <i className="anticon menu-icon target" />,
    level: 1,
    childrens: [
      { key: '41', title: '指标统计', level: 2, path: '/PEIMSV2/FactorStatistics' },
      { key: '42', title: '指标预测', level: 2, path: '/PEIMSV2/FactorPredict' },
      { key: '43', title: '指标分析', level: 2, path: '/PEIMSV2/Analysis' },
      {
        key: '44',
        title: '指标考核',
        level: 2,
        childrens: [
          { key: '441', title: '考核规则', level: 3, path: '/PEIMSV2/Exam/RuleList' },
        ]
      },
    ]
  },
  {
    key: 'sub5',
    title: '成本',
    icon: <i className="anticon menu-icon cost" />,
    level: 1,
    childrens: [{ key: '51', title: '成本汇总', level: 2, path: '/PEIMSV2/ProductCost' }]
  },
  {
    key: 'sub6',
    title: '报警',
    icon: <i className="anticon menu-icon warning" />,
    level: 1,
    childrens: [
      { key: '61', title: '报警统计', level: 2, path: '/PEIMSV2/AlarmPage' },
      { key: '62', title: '报警设置', level: 2, path: '/PEIMSV2/AlarmSettings' }
    ]
  },
  {
    key: 'sub7',
    title: '考核',
    icon: <i className="anticon menu-icon assess" />,
    level: 1,
    childrens: [
      { key: '71', title: '竞赛结果', level: 2, path: '/PEIMSV2/RaceList' },
      { key: '72', title: '规则设置', level: 2, path: '/PEIMSV2/ExamRules' },
      { key: '73', title: '剔除时段', level: 2, path: '/PEIMSV2/EliminationPeriod' }
    ]
  },
  {
    key: 'sub8',
    title: '报表',
    icon: <i className="anticon menu-icon report" />,
    level: 1,
    childrens: [{ key: '81', title: '自动报表', level: 2, path: '/PEIMSV2/AutoReport' }]
  },
  {
    key: 'sub9',
    title: '设置',
    icon: <i className="anticon menu-icon setting" />,
    level: 1,
    childrens: [
      { key: '91', title: '用户管理', level: 2, path: '/PEIMSV2/user' },
      { key: '92', title: '角色管理', level: 2, path: '/PEIMSV2/role' },
      { key: '93', title: '部门管理', level: 2, path: '/PEIMSV2/department' },
      { key: '94', title: '排班管理', level: 2, path: '/PEIMSV2/SchedulingManagement' }
    ]
  }
]

export { MenuList }
