import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router'
import style from './index.module.scss';
import ICON_AVATAR from './res/avatar.svg';
import ICON_LOGOUT from './res/logout.svg';
import moment from 'moment';

const Comp = (props) => {
    const [name, setName] = useState(null);

    useEffect(() => {
        const userInfoStr = window.sessionStorage.getItem('USER_INFO') || window.localStorage.getItem('USER_INFO');
        let userInfo = null;
        if (typeof userInfoStr === 'string' && userInfoStr !== "") {
            try {
                userInfo = JSON.parse(userInfoStr);
            } catch (err) { };
        };
        if (!!userInfo) {
            const { name, expire_time } = userInfo;
            try {
                if (moment.unix(expire_time).isAfter(moment())) {
                    if (typeof name === 'string') setName(name);
                };
            } catch (err) { };
        };
    }, []);

    const logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        props.history.push('/Login');
    };

    const nameText = useMemo(() => {
        if (typeof name === 'string') {
            return name;
        };
        return "(未登录)"
    }, [name]);

    return (
        <div className={style['wrapper']}>
            <div className="left">
                <div className="prod_name">轻环</div>
                <div className="sys_name">生产能源一体化管理系统</div>
            </div>
            <div className="right">
                <img src={ICON_AVATAR} alt="" className="avatar" />
                <div className="user_name">{nameText}</div>
                <img src={ICON_LOGOUT} alt="" className="logout" onClick={logout} />
            </div>
        </div>
    );
};

export default withRouter(Comp);
