import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react'
import { Input, Select, DatePicker, message } from 'antd'
import moment from 'moment'
import { getCachedUserInfo } from 'common/utils'
import { getCurrentTime } from '../../../../../utils'
import Custom1 from './Custom1'
import Custom2 from './Custom2'
import Custom3 from './Custom3'
import Custom4 from './Custom4'
import Custom5 from './Custom5'
import Custom6 from './Custom6'
import style from './index.module.scss'
const { TextArea } = Input
const { Option } = Select

const ForwardCustomInfo = forwardRef((props, ref) => {
  const { templateId } = props
  return (
    <>
      {templateId === 1 && <Custom1 {...props} refInstance={ref} />}
      {templateId === 2 && <Custom2 {...props} refInstance={ref} />}
      {templateId === 3 && <Custom3 {...props} refInstance={ref} />}
      {templateId === 4 && <Custom4 {...props} refInstance={ref} />}
      {templateId === 5 && <Custom5 {...props} refInstance={ref} />}
      {templateId === 6 && <Custom6 {...props} refInstance={ref} />}
    </>
  )
})

/** 新建/编辑事件内容主体(可编辑状态) */
const Com = props => {
  const { capsuleDetailInfo, templateId, tplId, classifyTags } = props
  const userInfo = getCachedUserInfo() // 当前用户信息
  const [title, setTitle] = useState('') // 标题
  const [startTime, setStartTime] = useState(moment(getCurrentTime())) // 事件发生时间
  const [stopTime, setStopTime] = useState() // 事件结束时间
  const [classify, setClassify] = useState(null) // 分类
  const [info, setInfo] = useState('') // 说明
  const [cacheData, setCacheData] = useState({}) // 缓存信息
  const [resetAction, setResetAction] = useState() // 发出重置指令 :Symbal

  const customRef = useRef() // 自定义UI模版

  /** 暴漏给父组件的方法和属性 */
  useImperativeHandle(props.refInstance, () => ({
    cacheData, // 不能删
    setTitle, // 不能删
    setClassify, // 不能删
    saveCacheData, // 不能删
    checkValidate, // 不能删
    getContentInfo // 不能删
  }))

  useEffect(() => {
    // 新建时会产生缓存数据
    if (capsuleDetailInfo) return
    const cacheInfo = tplId && cacheData[tplId] ? cacheData[tplId] : null
    if (cacheInfo) {
      const jsonContent = cacheInfo.json_content || {}
      const { date, occurEndTime } = jsonContent
      date && setStartTime(moment(date))
      occurEndTime && setStopTime(moment(occurEndTime))
      if (Object.keys(jsonContent).length > 0) {
        jsonContent.classify ? setClassify(jsonContent.classify) : initDefaultClassify()
        setInfo(jsonContent.info)
      }
    } else {
      if (tplId) {
        setInfo('')
        setStartTime(moment(getCurrentTime()))
        setStopTime()
      }
    }
  }, [tplId])

  useEffect(() => {
    if (capsuleDetailInfo) {
      const jsonContent = capsuleDetailInfo?.jsonContent || {}
      if (Object.keys(jsonContent).length > 0) {
        const { occur_time, occur_end_time, sn } = capsuleDetailInfo
        occur_time && setStartTime(moment(occur_time))
        occur_end_time && setStopTime(moment(occur_end_time))
        setTitle(jsonContent.title)
        jsonContent.classify
          ? setClassify(jsonContent.classify)
          : sn
          ? setClassify(Number(sn))
          : initDefaultClassify()
        setInfo(jsonContent.info)
      } else {
        initDefaultClassify()
      }
    }
    !classify && initDefaultClassify()
  }, [classifyTags, capsuleDetailInfo])

  /** 校验表单值是否有效 */
  const checkValidate = () => {
    if (startTime && stopTime && startTime.isAfter(stopTime)) {
      message.error('事件结束事件不能大于事件开始时间！')
      return false
    }
    if (!title || !title.trim()) {
      message.error('请输入标题')
      return false
    }
    if (customRef.current && !customRef.current.checkValidate()) {
      return false
    }
    return true
  }

  /** 读取事件信息 */
  const getContentInfo = () => {
    const cutomeData = customRef.current ? customRef.current.getCustomData() : {}
    return {
      date: startTime.format('YYYY-MM-DD HH:mm:00'),
      occurEndTime: stopTime?.format('YYYY-MM-DD HH:mm:00') || null,
      json_content: {
        title,
        classify,
        info,
        templateId,
        ...cutomeData
      }
    }
  }

  /** 缓存数据 */
  const saveCacheData = () => {
    const templateInfo = getContentInfo()
    cacheData[tplId] = templateInfo
    setCacheData(cacheData)
  }

  /** 设置初始值 */
  const initDefaultClassify = () => {
    if (classifyTags && Object.keys(classifyTags).length > 0) {
      setClassify(Number(Object.keys(classifyTags)[0]))
    }
  }

  /** 禁用时间发生时间 */
  const disabledStartDate = e => {
    if (stopTime) {
      return moment(e.format('YYYY-MM-DD')).isAfter(stopTime.format('YYYY-MM-DD'))
    }
    return false
  }

  /** 禁用事件结束时间 */
  const disabledStopDate = e => {
    if (startTime) {
      return moment(e.format('YYYY-MM-DD')).isBefore(startTime.format('YYYY-MM-DD'))
    }
    return false
  }

  return (
    <div className={style['contentWrapper']}>
      <div className="content-info">
        <span>
          {capsuleDetailInfo?.name || userInfo.name}{' '}
          <span>{capsuleDetailInfo?.orgName ? `（${capsuleDetailInfo.orgName}）` : ''}</span>
        </span>

        <div className="reset-icon" onClick={() => setResetAction(Symbol())}>
          重置
        </div>
      </div>
      <div style={{ marginBottom: 15 }}>
        <DatePicker
          showTime
          value={startTime}
          allowClear={false}
          style={{ width: 180, borderColor: '#375B98', borderRadius: 4 }}
          format="YYYY/MM/DD HH:mm"
          disabledDate={disabledStartDate}
          onChange={setStartTime}
          onOk={setStartTime}
        />
        <span style={{ color: 'rgba(0, 0, 0, 0.65)', margin: '0 4px' }}>-</span>
        <DatePicker
          showTime
          value={stopTime}
          style={{ width: 180, borderColor: '#375B98', borderRadius: 4 }}
          format="YYYY/MM/DD HH:mm"
          disabledDate={disabledStopDate}
          onChange={setStopTime}
          onOk={setStopTime}
        />
        <Select value={classify} className="classify" onChange={e => setClassify(e)}>
          {classifyTags &&
            Object.keys(classifyTags).map(tagId => {
              return (
                <Option key={tagId} value={Number(tagId)}>
                  {classifyTags[tagId]}
                </Option>
              )
            })}
        </Select>
      </div>
      <div style={{ marginBottom: 24 }}>
        <Input
          value={title}
          placeholder="请输入标题"
          maxLength={20}
          className="title"
          onChange={e => setTitle(e.target.value)}
        />
      </div>
      <div style={{ marginBottom: 16 }}>
        <ForwardCustomInfo
          ref={customRef}
          templateId={templateId}
          capsuleDetailInfo={capsuleDetailInfo}
          cacheData={cacheData[tplId]}
          resetAction={resetAction}
        />
      </div>
      <div>
        <TextArea
          value={info}
          placeholder="请输入说明"
          autoSize={{ minRows: 3, maxRows: 5 }}
          maxLength={200}
          className="info"
          onChange={e => setInfo(e.target.value)}
        />
      </div>
    </div>
  )
}

export default Com
