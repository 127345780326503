import React from 'react'
import ExamInfoBar from './ExamInfoBarContainer.js'
import LeftViewer from './LeftViewerContainer.js'
import RightViewer from './RightViewerContainer.js'
import style from './ExamAnalysis.module.scss'

export default class ExamAnalysis extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { getSystemsInfo, getExamDetailData } = this.props
    getSystemsInfo().then(isSuccess => {})
    getExamDetailData().then(isSuccess => {
      // console.log(isSuccess);
      if (isSuccess) {
        //成功获取到竞赛信息并且当前存在竞赛
        // 获取最近班组签到信息
        const { getLatestShiftAttendedUsers } = this.props
        getLatestShiftAttendedUsers().then(isSuccess => {
          //
        })
      }
    })
    window.onresize = this.pageOnResize
  }

  componentWillUnmount() {
    const { resetState } = this.props
    resetState()
  }

  pageOnResize = () => {
    this.setRightContentWidth()
  }

  refFunc = refNode => {
    if (!!refNode) {
      this.refNode = refNode
      this.setRightContentWidth()
    }
  }

  setRightContentWidth = () => {
    if (this.refNode) {
      const { rightContentWidth, updateContentWidth } = this.props
      let newRightContentWidth = this.refNode.offsetWidth
      // console.log(newRightContentWidth);
      if (newRightContentWidth !== rightContentWidth) {
        updateContentWidth(newRightContentWidth)
      }
    }
  }
  render() {
    const { selectedUserValue, customStyle, hideExamInfoBar } = this.props
    return (
      <div className={style['exam_analysis_root_wrapper']} style={customStyle}>
        <div className="exam_info_out_wrapper" style={{ display: hideExamInfoBar ? 'none' : '' }}>
          <ExamInfoBar />
        </div>
        {/*------------------------------------ */}
        <div className="root_content_bck_wrapper" style={{ marginTop: hideExamInfoBar ? 0 : '' }}>
          <div className="root_content">
            <div className="left_content">
              {/* 左侧视图 */}
              <LeftViewer />
              {/*------------------------------------ */}
            </div>
            <div className="right_content" ref={this.refFunc}>
              {/* 右侧视图 */}
              {selectedUserValue !== null && <RightViewer />}
              {/*------------------------------------ */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
