import React, { useState } from 'react';
import style from './index.module.scss';
import Header from './Header';
import Compare from './Compare';
import Trend from './Trend';
import Alarm from './Alarm';
import Factor from './Factor';
import Event from './Event';

const Page = (props) => {
    const [sysInfo, setSysInfo] = useState(null);

    return (
        <div className={style['wrapper']}>
            <Header sysInfo={sysInfo} setSysInfo={setSysInfo} />
            <div className="content_wrapper">
                <div className="left">
                    <Compare />
                </div>
                <div className="mid">
                    <Trend />
                </div>
                <div className="right">
                    <div className="top">
                        <Alarm />
                    </div>
                    <div className="mid">
                        <Factor />
                    </div>
                    <div className="bottom">
                        <Event />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;
