import React from 'react';
import { Table, message, Spin, Breadcrumb, Button, Divider, Badge, Menu, Dropdown, Space, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import ScriptEditor from './ScriptEditor.js';
import { get, post, patch } from '../../common/utils.js';
import './ScriptList.scss';
// import 'antd/dist/antd.less';

const menu = (
  <Menu>
    <Menu.Item>Action 1</Menu.Item>
    <Menu.Item>Action 2</Menu.Item>
  </Menu>
);

const STATUS_ENUM = {
    '0': {
        status: 'default',
        text: '即将运行'
    },
    '1': {
        status: 'processing',
        text: '正在执行'
    },
    '2': {
        status: 'success',
        text: '执行成功'
    },
    '3': {
        status: 'error',
        text: '执行失败'
    }
}

export default class ScriptList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            scriptList: [],
            taskList: [],
            activeScriptId: null,
            tableLoading: false,
            tasksLoading: false,
            taskProcessing: false,
            expandedRowKeys: [],
            taskResult: '',
            taskStatus: null,
            taskModalId: '',
        };
    }

    componentDidMount() {
        this.fetchScriptList();
    }

    fetchScriptList = ()=>{
        let self = this;
        self.setState({tableLoading: true});
        get(process.env.REACT_APP_SERVER_PATH+'api/pan/scripts', {
            folder_id: this.props.folder_id
        })
        .then(retData => {
            self.setState({tableLoading: false});
            let d = retData.data
            if (d && d.results) {
                self.setState({
                    scriptList: d.results,
                });
                if(self.state.expandedRowKeys.length > 0){
                    self.fetchTaskList(self.state.expandedRowKeys[0]);
                }
            } else {
            };
        });
    }

    fetchTaskList = (script_id)=>{
        let self = this;
        self.setState({tasksLoading: true});
        get(process.env.REACT_APP_SERVER_PATH+'api/pan/script_tasks', {
            script_id: script_id,
            page_id: 1,
            page_size: 30
        }).then(retData => {
            self.setState({tasksLoading: false});
            let d = retData.data
            if (d && d.results) {
                self.setState({
                    taskList: d.results
                });
            } else {
            };
        });
    }

    expandedRowRender = ()=>{

    }

    editScript = (_id) => {
        this.setState({
            activeScriptId: _id
        });
    }

    // createTask = (script_id) => {
    //     let self = this;
    //     self.setState({tasksLoading: true});
    //     post(process.env.REACT_APP_SERVER_PATH+'api/pan/script_tasks', {
    //         script_id: script_id,
    //         status: 0,
    //     }).then(retData => {
    //         self.setState({tasksLoading: false}, ()=>self.fetchTaskList(script_id));
    //         let d = retData.data
    //         if (d && d.id) {
    //             message.success('任务已创建');
    //         } else {
    //         };
    //     });
    // }

    execTask = (script_id) => {
        let self = this;

        let _exec = _=>{
            self.setState({tasksLoading: true, taskProcessing: true});
            post(process.env.REACT_APP_SERVER_PATH+'api/pan/exec_script', {
                script_id: script_id,
                file_id: (self.props.target_file_id ? self.props.target_file_id:null)
            }).then(retData => {
                self.setState({tasksLoading: false, taskProcessing: false});
                let d = retData.data
                if (d && (d.errcode === 0 || d.errcode === 500)) {
                    self.setState({
                        execStatus: d.errcode,
                        execResult: d.result,
                        resultVisible: true,
                    });
                } else {
                    message.error('运行失败');
                };
            });
        }

        if(this.props.beforeExec){
            this.props.beforeExec().then(_=>_exec());
        }else{
            _exec();
        }
    }

    delScript = (record)=>{
        const execDel = () => {
            patch(process.env.REACT_APP_SERVER_PATH + 'api/pan/scripts/' + record.id, {
                status: -1,
            }).then((retData) => {
                if (retData.data) {
                    message.success("删除成功");
                    this.fetchScriptList();
                } else {
                    message.error("删除失败");
                };
            });
        };

        Modal.confirm({
            title: '确认删除吗？',
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            cancelText: '取消',
            onOk: () => execDel(),
        });
    }

    render() {
        let self = this;
        const {
            scriptList, activeScriptId, expandedRowKeys, displayTaskResult, taskResult, taskStatus, taskModalId, taskProcessing,
            resultVisible, execResult, execStatus,
        } = this.state;
        const { folder_id } = this.props;

        const expandedRowRender = (record) => {
            const columns = [
                {
                    title: '状态',
                    key: 'state',
                    render: (_r) => (
                        <span>
                            <Badge status={STATUS_ENUM[_r.status+''].status} />
                            {STATUS_ENUM[_r.status+''].text}
                        </span>
                    ),
                },
                { title: '创建时间', dataIndex: 'create_time', key: 'create_time' },
                { title: '开始时间', dataIndex: 'time_begin', key: 'time_begin' },
                { title: '结束时间', dataIndex: 'time_end', key: 'time_end' },
                {
                    title: '任务操作',
                    key: 'operation',
                    width: 168,
                    render: (_r) => (
                        <Space size="middle">
                        {
                            // (_r.status === 0 || _r.status === 1) &&
                            // <a>停止</a>
                        }
                        {
                            (_r.status === 2 || _r.status === 3) &&
                            <a onClick={()=>self.setState({
                                taskResult: _r.result,
                                taskStatus: _r.status,
                                displayTaskResult: true,
                                taskModalId: _r.id})
                            }>查看详情</a>
                        }
                        </Space>
                    ),
                },
            ];

            return (
                <Table
                    size="small"
                    bordered
                    columns={columns}
                    loading={this.state.tasksLoading}
                    dataSource={self.state.taskList}
                    pagination={false}
                />
            );
        }

        return (
            <div className="ScriptList">
            {
                // activeScriptId !== null &&
                // <Breadcrumb>
                //     <Breadcrumb.Item onClick={()=>this.setState({activeScriptId: null})}><a>全部脚本</a></Breadcrumb.Item>
                //     { activeScriptId === -1 && <Breadcrumb.Item>新建脚本</Breadcrumb.Item> }
                //     { activeScriptId !== -1 && <Breadcrumb.Item>修改脚本</Breadcrumb.Item> }
                // </Breadcrumb>
            }
            {
                activeScriptId === null &&
                <React.Fragment>
                    <div className="toolBar">
                        <div className="leftTools">
                            <Button className="createBtn" style={{marginLeft: 0}} onClick={()=>this.editScript(-1)} type="primary">新建脚本</Button>
                        </div>
                        <div className="rightTools">
                            {
                                taskProcessing &&
                                <Badge className="processBadge" status="processing" text="运行中..." />
                            }
                            <Button className="refreshBtn" style={{marginLeft: 0}} onClick={()=>this.fetchScriptList()} type="default">刷新</Button>
                        </div>
                    </div>
                    <div className="downContent">
                        <Table
                            rowKey='id'
                            bordered
                            expandable={{
                                expandedRowRender: expandedRowRender,
                                expandedRowKeys: expandedRowKeys,
                                onExpand: (expanded, _r,  y)=>{
                                    if(expanded === false){
                                        self.setState({expandedRowKeys: []});
                                    }else{
                                        self.setState({
                                            expandedRowKeys: [_r.id],
                                            taskList: []
                                        }, ()=>self.fetchTaskList(_r.id));
                                    }
                                }
                            }}
                            pagination={{
                                defaultCurrent: 1,
                                current: 1,
                                pageSize: 99999,
                                total: scriptList.length,
                            }}
                            loading={this.state.tableLoading}
                            dataSource={scriptList}
                            style={{width: '100%'}}
                        >
                            <Table.Column
                                title="名称"
                                key="name"
                                dataIndex="name"
                                // width={150}
                            />
                            <Table.Column
                                title="上次运行"
                                key="last_exec_time"
                                dataIndex="last_exec_time"
                                width={200}
                            />
                            <Table.Column
                                title="创建时间"
                                key="create_time"
                                dataIndex="create_time"
                                width={200}
                            />
                            <Table.Column
                                title="操作"
                                key="operate"
                                width={170}
                                render={(record)=>(
                                    <span>
                                        <a style={{color: (taskProcessing?'#999':'green')}} onClick={_=>!taskProcessing && self.execTask(record.id)}>运行</a>
                                        <Divider type="vertical" />
                                        <a onClick={_=>self.editScript(record.id)}>编辑</a>
                                        <Divider type="vertical" />
                                        <a style={{color: 'red'}} onClick={_=>self.delScript(record)}>删除</a>
                                    </span>
                                )}
                            />
                        </Table>
                    </div>
                </React.Fragment>
            }
            {
                activeScriptId !== null &&
                <ScriptEditor
                    key={'script-'+activeScriptId}
                    target_file_id={this.props.target_file_id}
                    script_id={activeScriptId}
                    folder_id={folder_id}
                    onExecFinish={self.props.onExecFinish}
                    beforeExec={self.props.beforeExec}
                    onClose={(refresh)=>{
                        this.setState({activeScriptId: null});
                        if(refresh){
                            this.fetchScriptList();
                        }
                    }}
                />
            }
                <Modal
                    key={'task-'+taskModalId}
                    visible={displayTaskResult}
                    onCancel={()=>this.setState({displayTaskResult: false})}
                    onOk={()=>this.setState({displayTaskResult: false})}
                >
                    <div>
                        <Badge status={taskStatus === 2 ? 'success':'error'} />
                        {taskStatus === 2 ? '运行成功':'运行失败'}
                    </div>
                    <pre style={{whiteSpace: 'pre-wrap', marginTop: '10px'}}>{taskResult}</pre>
                </Modal>
                <Modal
                    key={'task-result'}
                    visible={resultVisible}
                    width={700}
                    onCancel={()=>this.setState({resultVisible: false},_=>self.props.onExecFinish && self.props.onExecFinish())}
                    onOk={()=>this.setState({resultVisible: false},_=>self.props.onExecFinish && self.props.onExecFinish())}
                >
                    <div>
                        <Badge status={execStatus === 0 ? 'success':'error'} />
                        {execStatus === 0 ? '运行成功':'运行失败'}
                    </div>
                    <pre style={{whiteSpace: 'pre-wrap', marginTop: '10px'}}>{execResult}</pre>
                </Modal>
            </div>
        );
    }
}
