import React from 'react';
import { Input, Button, Slider, InputNumber, Modal, Space } from 'antd';
import { get, patch } from '../../common/utils.js';
import Config from '../../common/config.js';
import './LeftSettingPart.scss';
import NumInput from './components/NumInput.js';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
const { TextArea } = Input;
const PIC_SERVER_PATH = process.env.REACT_APP_SERVER_PATH + 'api/img/download/';

export default class LeftSettingPart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            addDataPointVisible: false,
            sourceValue: "",
            addDataPointSourceValue: "",
            activeXY: {
                right: {
                    onFocus: false,
                    value: ""
                },
                top: {
                    onFocus: false,
                    value: ""
                }
            }
        }
        this.dataSet = {
            ctrlOnKey: false
        }
    }
    componentDidMount() {
        let self = this;
        document.onkeydown = function (event) {
            var e = event || window.event;
            if (!e) return;
            var ctrlKey = e.ctrlKey || e.metaKey, times = ctrlKey ? 10 : 1;
            // var btnNum = e.button;
            // console.log(e);
            // if (btnNum == 1) {
            //     alert("您点击了鼠标左键！")
            // }//nouse
            if ((e && e.keyCode === 38) || (e && e.keyCode === 37)) {//上,左
                // alert('38=上键，37=左键');
                if (e.keyCode === 37) self.dirMove(0, times)
                else self.dirMove(1, times);
            }
            if ((e && e.keyCode === 40) || (e && e.keyCode === 39)) {//下,右
                // alert('40=下键，39=右键');
                if (e.keyCode === 39) self.dirMove(3, times)
                else self.dirMove(2, times);
            }
        };
        // document.onkeyup = function (event) {
        //     var e = event || window.event || arguments.callee.caller.arguments[0];
        //     var ctrlKey = e.ctrlKey || e.metaKey;
        //     if (ctrlKey) alert('ctrl up')
        // }
    }
    ifNowSelected() {
        let spd = this.props.data;
        if (spd.nowSelected === null) return false;
        if (spd.listData[spd.nowSelected]) return true;
        return false;
    }
    ifDetailSelected() {
        if (this.ifNowSelected()) {
            let spd = this.props.data, NS = spd.listData[spd.nowSelected];
            if (([1, 2].includes(NS.type)) && NS.detailItemNowSelected === null) return false;
            return true;
        }
        return false;
    }
    dirMove = (dir, times = 1) => {
        let singleItemSelected = this.ifDetailSelected(),
            moveItemList = [],
            dirArr = [[0, 1 * times], [-1 * times, 0], [1 * times, 0], [0, -1 * times]],
            spd = this.props.data,
            listData = spd.listData;
        if (singleItemSelected) {
            let NS = listData[spd.nowSelected], coordObj = NS.details[0];
            NS.type === 1 && (coordObj = NS.details[NS.detailItemNowSelected]);
            moveItemList.push(coordObj);
        } else if (this.props.data.groupSelected.length > 0) {
            spd.groupSelected.forEach(idx => {
                listData[idx].details.forEach(d => moveItemList.push(d));
            })
        } else return;
        moveItemList.forEach(coordObj => {
            //移动时按px移动
            let bckW = 100, bckH = 100;
            if (spd.boardBckSize !== null) {
                bckW = spd.boardBckSize.width;
                bckH = spd.boardBckSize.height;
            }
            let nTop = coordObj.top + dirArr[dir][0] * 100 / bckH, nRight = coordObj.right + dirArr[dir][1] * 100 / bckW;
            if (nTop >= -100 && nTop <= 100 && nRight >= -100 && nRight <= 100) {
                coordObj.top = nTop;
                coordObj.right = nRight;
            }
        })
        this.props.componentSetState({ listData });
    }
    remainMove(dir) { }
    setCoor(str, v) {
        // console.log(str, v);
        //XY不能用小数？
        let activeXY = this.state.activeXY;
        if (activeXY[str].onFocus) {
            activeXY[str].value = v;
            this.setState({ activeXY })
            return;
        }
        let spd = this.props.data, listData = spd.listData, NS = listData[spd.nowSelected], coordObj = NS.details[0];
        NS.type === 1 && (coordObj = NS.details[NS.detailItemNowSelected]);
        coordObj[str] = (isNaN(parseFloat(v)) ? 0 : parseFloat(v));
        this.props.componentSetState({ listData });
    }
    coorOnFocus = (str, v) => {
        let activeXY = this.state.activeXY;
        activeXY[str].onFocus = true;
        activeXY[str].value = v;
        this.setState({ activeXY })
    }
    coorOnBlur(str) {
        let activeXY = this.state.activeXY, self = this;
        activeXY[str].onFocus = false;
        this.setState({ activeXY }, _ => {
            self.setCoor(str, this.state.activeXY[str].value);
        });
    }
    fontOnChange = v => {
        let spd = this.props.data, listData = spd.listData;
        listData[spd.nowSelected].details[0].size = v;
        this.props.componentSetState({ listData });
    }
    imgSizeOnChange = v => {
        let spd = this.props.data, listData = spd.listData, NS = listData[spd.nowSelected];
        NS.details[NS.detailItemNowSelected].size = v;
        this.props.componentSetState({ listData });
    }
    getContent = source => {
        let retStr, keyList = ['listData', 'bckPic', 'bckSize'];
        try {
            let genData = {};
            keyList.forEach(key => {
                genData[key] = source[key];
            })
            genData.bckSize = source.boardBckSize;
            retStr = JSON.stringify(genData);
        } catch (e) {
            retStr = null;
        }
        return retStr;
    }
    generateData = () => {
        let source = this.props.data, retStr = this.getContent(source);
        retStr === null && (retStr = 'errData');
        this.setState({ sourceValue: retStr });
    }
    readData = () => {
        let source = this.props.data, keyList = ['listData', 'bckPic', 'bckSize'], dataPointMergeCount = 0, self = this;
        //为空时不读取
        if (this.state.sourceValue === "") return;
        try {
            let nState = JSON.parse(this.state.sourceValue);
            console.log(nState);
            if (typeof nState === 'object') {
                keyList.forEach(key => {
                    if (nState[key]) source[key] = nState[key];
                })
                source.boardBckSize = nState.bckSize;
            }
            source.nowSelected = null;
            source.listData.forEach(item => {
                item.detailItemNowSelected = null;
                item.dataPoint && (dataPointMergeCount += self.mergeDataPoint(item.dataPoint, source.dataPoint));
            })
            this.props.componentSetState(source);
        } catch (e) {
            alert("输入数据有误，请重新输入");
        }
        this.setState({ visible: false });
    }
    mergeDataPoint = (newDataPoint, dataPointList) => {
        let i;
        for (i = 0; i < dataPointList.length; i++) {
            if (dataPointList[i].value === newDataPoint) return 0;
        }
        dataPointList.unshift({ value: newDataPoint, name: '[merge]' + newDataPoint });
        return 1;
    }
    colorOnChange = e => {
        let spd = this.props.data, listData = spd.listData, NS = listData[spd.nowSelected];
        NS.details[0].color = e.target.value;
        this.props.componentSetState({ listData });
    }
    saveContent = _ => {
        let self = this, params = self.props.data.diagramConf, content = self.getContent(self.props.data);
        console.log('保存');
        console.log(self.props.data);
        if (content === null) return;
        patch(process.env.REACT_APP_SERVER_PATH + 'api/diagrams/' + params.id, {
            "content": content,
        }).then((retData) => {
            console.log(retData);
            if (retData.data) {
                const modal = Modal.success({
                    title: '提示',
                    content: '修改成功'
                });
                setTimeout(() => modal.destroy(), 1000);
            } else {
            }
        });
    }

    queryUnsetPoints = _ => {
        // let content = this.getContent(this.props.data);
        let listData = this.props.data.listData;
        get(Config.deviceApi.API_DATA_METAS, {
            device_id: this.props.data.diagramConf.device_id,
            page_size: 1000
        }).then(retData => {
            console.log(retData);
            if (retData && retData.data && retData.data.results) {
                let unsetPoints = retData.data.results.filter(item => {
                    let filteredPoints = listData.filter(point => point['dataPoint'] === item.tag_alias);
                    if (filteredPoints.length > 0 && parseInt(item.status) !== 1) {
                        return true;
                    } else {
                        return false;
                    }
                });
                console.log("unsetPoints: ", unsetPoints);
            }
        })
    }

    addDataPoint = _ => {
        let self = this, dataPoint = this.props.data.dataPoint;
        this.state.addDataPointSourceValue.split(",").forEach(v => {
            if (!self.checkIfExist(dataPoint, v)) {
                dataPoint.unshift({ value: v, name: "[add]" + v });
            }
        })
        this.props.componentSetState({ dataPoint });
    }
    checkIfExist = (dataPoint, value) => {
        let i;
        if (value === "") return true;
        for (i = 0; i < dataPoint.length; i++) {
            if (dataPoint[i].value === value) return true;
        }
        return false;
    }
    detailSizeOnBlur = v => {
        let num = parseFloat(v);
        if (isNaN(num) || num <= 0) return;
        this.imgSizeOnChange(num * 100 / this.props.data.bckSize.width);
    }
    boardBckSizeChange = (sign, v) => {
        let boardBckSize = this.props.data.boardBckSize;
        // boardBckSize[sign] = v;
        if (sign === 'width') {
            boardBckSize.height *= v / boardBckSize.width;
        } else {
            boardBckSize.width *= v / boardBckSize.height;
        }
        boardBckSize[sign] = v;
        this.props.componentSetState({ boardBckSize });
    }
    lineHeightOnChange = v => {
        let spd = this.props.data, listData = spd.listData;
        listData[spd.nowSelected].details[0].lineHeight = v;
        this.props.componentSetState({ listData });
    }
    precisionOnChange = v => {
        let spd = this.props.data, listData = spd.listData;
        listData[spd.nowSelected].details[0].precision = v;
        this.props.componentSetState({ listData });
    }
    render() {
        let self = this, spd = self.props.data,
            getCoord = (str) => {
                if (self.ifDetailSelected()) {
                    if (this.state.activeXY[str].onFocus) return this.state.activeXY[str].value;
                    let listData = spd.listData, NS = listData[spd.nowSelected], coordObj = NS.details[0];
                    NS.type === 1 && (coordObj = NS.details[NS.detailItemNowSelected])
                    return coordObj[str];
                }
                return "";
            },
            inputConf = {
                className: "inputWrapper",
                min: 0.1,
                max: 100
            },
            gImgSize = () => {
                let NS = spd.listData[spd.nowSelected];
                return NS.details[NS.detailItemNowSelected].size;
            }, gImgSizePx = sign => {
                if (!spd.bckSize) return "";
                let size = gImgSize();
                if (sign === 0) {
                    return size * spd.bckSize.width / 100;
                }
                return "";
            };
        return (
            <div className="LeftSettingPart">
                {/* alert改为message */}
                <Modal
                    title="生成或读取数据"
                    visible={this.state.visible}
                    closable={true}
                    onCancel={_ => self.setState({ visible: false })}
                    footer={(
                        <Button type="default" onClick={_ => self.setState({ visible: false })}>取消</Button>
                    )}
                >
                    <Space>
                        <Button type="primary" onClick={self.readData}>读取数据</Button>
                        <Button onClick={self.generateData}>生成数据</Button>
                    </Space>
                    <div style={{ height: 12 }} />
                    <TextArea placeholder="生成或读取数据" rows={6} value={self.state.sourceValue} onChange={e => self.setState({ sourceValue: e.target.value })} />
                </Modal>
                <Modal
                    title="添加额外数据点"
                    visible={this.state.addDataPointVisible}
                    closable={true}
                    onOk={_ => self.setState({ addDataPointVisible: false })}
                    onCancel={_ => self.setState({ addDataPointVisible: false })}
                    okText="确认"
                    cancelText="取消"
                >
                    <Button type="primary" onClick={self.addDataPoint}>添加</Button>
                    <TextArea placeholder="添加额外数据点" autosize={{ minRows: 2, maxRows: 5 }} value={self.state.addDataPointSourceValue} onChange={e => self.setState({ addDataPointSourceValue: e.target.value })} />
                </Modal>
                <div className="left">
                    <Space direction="vertical">
                        <Space>
                            {/* 操作改文案 */}
                            <Button type="primary" onClick={_ => self.setState({ visible: true })}>读取或生成图案数据</Button>
                            {/* DiagramGen设置保存按钮，按照DiagramId上报数据 */}
                            <Button type="primary" className="save" onClick={self.saveContent}>保存</Button>
                            <Button type="default" className="queryUnsetPoints" onClick={self.queryUnsetPoints}>查询未生效点</Button>
                        </Space>
                        <Space>
                            <div>底图：</div>
                            {/* api/img/download/123.png */}
                            <img src={spd.bckPic ? PIC_SERVER_PATH + spd.bckPic : undefined} alt="" style={{ width: 50, height: 50, display: 'inline-block', boxSizing: 'border-box', border: '1px solid grey' }} />
                            <div>
                                <label htmlFor="bckId">
                                    <div className="inputLabel">上传</div>
                                </label>
                                <input className="inputFile" type="file" id="bckId" accept="image/*" onChange={() => { self.props.onFilesChange("bckId"); }} ref="bckImg" />
                            </div>
                            <Button type="primary" onClick={_ => self.setState({ addDataPointVisible: true })}>添加额外数据点</Button>
                        </Space>
                        <div className="thirdSetting">
                            {
                                spd.bckPic !== null &&
                                <div className="bckResize">
                                    <div className="tip">背景比例：宽</div>
                                    <NumInput
                                        className="inputWrapper"
                                        min={1}
                                        max={20000}
                                        value={spd.boardBckSize === null ? undefined : spd.boardBckSize.width}
                                        onChange={v => { self.boardBckSizeChange('width', v) }}
                                    />
                                    {/* <InputNumber
                                /> */}
                                    <div>高</div>
                                    <NumInput
                                        className="inputWrapper"
                                        min={1}
                                        max={20000}
                                        value={spd.boardBckSize === null ? undefined : spd.boardBckSize.height}
                                        onChange={v => { self.boardBckSizeChange('height', v) }}
                                    />
                                    {/* <InputNumber
                                /> */}
                                </div>
                            }
                            <Button onClick={_ => self.props.componentSetState({ showAll: !spd.showAll })} title={spd.showAll ? "显示所有图标" : "仅显示选择图标"}>{spd.showAll ? <EyeOutlined /> : <EyeInvisibleOutlined />}</Button>
                        </div>
                    </Space>
                </div>
                <div className="right">
                    {
                        self.ifDetailSelected() && (
                            [0, 3, 4].includes(spd.listData[spd.nowSelected].type) ?
                                <div className="resizeWrapper">
                                    <div>字号：</div>
                                    <NumInput
                                        className="inputWrapper"
                                        min={3}
                                        max={60}
                                        value={spd.listData[spd.nowSelected].details[0].size}
                                        onChange={self.fontOnChange}
                                    />
                                    <div>px</div>
                                    <div>颜色：</div>
                                    <Input
                                        className="inputWrapper colorWrapper"
                                        placeholder="#000000"
                                        value={spd.listData[spd.nowSelected].details[0].color ? spd.listData[spd.nowSelected].details[0].color : ""}
                                        onChange={self.colorOnChange}
                                    />
                                    <div>行高：</div>
                                    <NumInput
                                        className="inputWrapper"
                                        min={80}
                                        max={300}
                                        value={spd.listData[spd.nowSelected].details[0].lineHeight || 100}
                                        onChange={self.lineHeightOnChange}
                                    />
                                    <div>精度：</div>
                                    <InputNumber
                                        value={(() => {
                                            let precision = spd.listData[spd.nowSelected].details[0].precision;
                                            if (typeof precision === 'number') {
                                                return precision;
                                            } else {
                                                return 1;
                                            };
                                        })()}
                                        onChange={(v) => {
                                            this.precisionOnChange(v);
                                        }}
                                        precision={0}
                                    />
                                </div>
                                :
                                [1, 2].includes(spd.listData[spd.nowSelected].type) ?
                                    <div className="resizeWrapper">
                                        <div>尺寸：</div>
                                        <div className="sliderWrapper">
                                            <Slider min={0.1} max={100} step={0.1} onChange={self.imgSizeOnChange} value={gImgSize()} tipFormatter={v => `${v}%`} />
                                        </div>
                                        <InputNumber
                                            {...inputConf}
                                            value={gImgSize()}
                                            onChange={self.imgSizeOnChange}
                                        />
                                        <div>%</div>
                                        <div>宽：</div>
                                        <InputNumber
                                            className="inputWrapper"
                                            defaultValue={gImgSizePx(0)}
                                            disabled={spd.bckSize ? false : true}
                                            onBlur={e => { console.log(e); self.detailSizeOnBlur(e.target.value) }}
                                        />
                                        <div>高：</div>
                                        <InputNumber
                                            className="inputWrapper"
                                            value={gImgSizePx(1)}
                                            disabled={spd.bckSize ? false : true}
                                            onBlur={e => { console.log(e.target.value) }}
                                        />
                                    </div>
                                    : ""
                        )
                    }
                    {/* 上下左右和操作换位置 */}
                    <div className="coordWrapper">
                        <div className="xyWrapper">
                            {/* <div>X：<input type="number" placeholder="未选中目标" value={getCoord('right')} onChange={e => self.setCoor('right', e.target.value)} disabled={!self.ifDetailSelected()} /></div> */}
                            <div>X：
                                <InputNumber
                                    className="inputWrapper"
                                    min={-100}
                                    max={100}
                                    value={getCoord('right')}
                                    onChange={v => self.setCoor('right', v)}
                                    disabled={!self.ifDetailSelected()}
                                    onBlur={_ => self.coorOnBlur('right')}
                                    onFocus={_ => self.coorOnFocus('right', getCoord('right'))}
                                />
                            </div>
                            {/* <div>Y：<input type="number" placeholder="未选中目标" value={getCoord('top')} onChange={e => self.setCoor('top', e.target.value)} disabled={!self.ifDetailSelected()} /></div> */}
                            <div>Y：
                                <InputNumber
                                    className="inputWrapper"
                                    min={-100}
                                    max={100}
                                    value={getCoord('top')}
                                    onChange={v => self.setCoor('top', v)}
                                    disabled={!self.ifDetailSelected()}
                                    onBlur={_ => self.coorOnBlur('top')}
                                    onFocus={_ => self.coorOnFocus('top', getCoord('top'))}
                                />
                            </div>
                        </div>
                        <div className="directionWrapper">
                            <div className="direction" onClick={() => self.dirMove(0)}>←</div>
                            <div className="dirMid">
                                <div className="direction" onClick={() => self.dirMove(1)}>↑</div>
                                <div className="direction" onClick={() => self.dirMove(2)}>↓</div>
                            </div>
                            <div className="direction" onClick={() => self.dirMove(3)}>→</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}