import React from 'react'
import TopBreadcrumb from 'components/TopBreadCrumb'
import AbnormalPage from '../../Cockpit/AlarmPage/AlarmPage'

const Page = () => {
  const breadcrumbList = [{ name: '看板' }, { name: '操作看板' }, { name: '报警' }]
  return (
    <div
      style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 24px 24px' }}
    >
      <TopBreadcrumb breadcrumbList={breadcrumbList} />
      <AbnormalPage customStyle={{ padding: 0 }} defaultSwitch={false} hideHeader={true} />
    </div>
  )
}

export default Page
