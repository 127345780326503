import React from 'react'
import { Button, Select, Input, Spin, message, Modal} from 'antd';
import { get, post, patch } from 'common/utils.js';
import style from './RuleEditor.module.scss';
import RuleBar from './RuleBar';

const Option = Select.Option;

export default class RuleEditor extends React.Component {
    constructor(props) {
        super(props);
        let d = props.data;
        let items = d.intervals;
        this.state = {
            loading: false,
            src_type: d.src_type,
            sys_id: d.sys_id,
            weight: d.weight,
            tag_name: d.tag_name,
            tagList: [],
            tagItem: null,
            loadingSearch: false,
            saving: false,

            score_1: items[0].score,
            color_1: items[0].color,
            score_2: items[1].score,
            color_2: items[1].color,
            score_3: items[2].score,
            color_3: items[2].color,
            limit_1: items[0].lower_limit,
            limit_2: items[0].upper_limit,
            limit_3: items[2].lower_limit,
            limit_4: items[2].upper_limit,
        }
    }

    componentDidMount() {
        // this.get_datas();
    }

    get_datas = ()=>{
        let self = this;
        self.setState({loading: true});
        get(process.env.REACT_APP_SERVER_PATH + 'api/exam/exam_rules',{
            page_size: 999,
            page: 1,
        }).then(retData => {
            self.setState({loading: false});
            if (!!retData.data && retData.data.results) {
                self.setState({dataList: retData.data.results, total: retData.data.count})
            };
        });
    }

    handleSearch = value => {
        let self = this;
        self.setState({loadingSearch: true});
        post(process.env.REACT_APP_SERVER_PATH + 'api/meta_data/list',{
            keyword: value,
            module_name: "COMMON_METAS",
            page_size: 10,
            page: 1,
        }).then(retData => {
            self.setState({loadingSearch: false});
            if (!!retData.data && retData.data.results) {
                self.setState({tagList: retData.data.results})
            };
        });
    }

    saveRule = _=>{
        let url;
        let req;
        let { editorType } = this.props;
        let { src_type, sys_id, tag_name, weight,
              score_1, color_1,
              score_2, color_2,
              score_3, color_3,
              limit_1, limit_2, limit_3, limit_4 } = this.state;

        if(!tag_name){
            message.error('请选择指标/参数');
            return;
        }
        if(editorType == 'EDIT'){
            url = process.env.REACT_APP_SERVER_PATH + 'api/exam/exam_rules/'+this.props.data.id;
            req = patch;
        }else if(editorType == 'CREATE'){
            url = process.env.REACT_APP_SERVER_PATH + 'api/exam/exam_rules';
            req = post;
        }
        let intervals = [
            {color: color_1, score: score_1, lower_limit: limit_1, upper_limit: limit_2},
            {color: color_2, score: score_2, lower_limit: limit_2, upper_limit: limit_3},
            {color: color_3, score: score_3, lower_limit: limit_3, upper_limit: limit_4},
        ]
        intervals.forEach(item=>{
            if(item.lower_limit === '' || item.lower_limit === undefined){
                item.lower_limit = null;
            }else{
                item.lower_limit = parseFloat(item.lower_limit);
            }
            if(item.upper_limit === '' || item.upper_limit === undefined){
                item.upper_limit = null;
            }else{
                item.upper_limit = parseFloat(item.upper_limit);
            }
        });
        let self = this;
        this.setState({saving: true});
        req(url, {
            src_type: src_type,
            sys_id: sys_id,
            weight: weight,
            intervals: intervals,
            tag_name: tag_name,
            status: 1,
        }).then(res=>{
            self.setState({saving: false});
            if (!!res.data) {
                message.success('保存成功');
                self.props.changeVisible(false);
                self.props.refreshList();
            }else{
                message.error('保存失败');
            }
        })
    }

    render() {
        let self = this;
        const {visible} = this.props;
        const { options, tagList, tagItem, loadingSearch, saving,
                tag_name, weight, sys_id,
                score_1, color_1,
                score_2, color_2,
                score_3, color_3,
                limit_1, limit_2, limit_3, limit_4 } = this.state;

        return (
            <Modal
                visible={visible}
                className={style["wrapper"]}
                // style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '0 24px 24px' }}
                style={{}}
                width={800}
                title="编辑规则"
                confirmLoading={saving}
                onOk={this.saveRule}
                onCancel={_=>this.props.changeVisible(false)}
            >
                <div className="info_line">
                    <div className="label">指标类型：</div>
                    <div className="content">
                        <Select
                            // className="search-select"
                            value={this.state.src_type+''}
                            style={{ width: 200 }}
                            defaultValue={this.state.src_type+''}
                            onChange={v=>this.setState({src_type: v})}
                        >
                            <Option value='1' key='1'>运行参数</Option>
                            <Option value='2' key='2'>指标</Option>
                        </Select>
                    </div>
                </div>
                <div className="info_line">
                    <div className="label">系统名称：</div>
                    <div className="content">
                        <Select
                            // className="search-select"
                            value={sys_id+''}
                            style={{ width: 200 }}
                            defaultValue={sys_id+''}
                            onChange={v=>this.setState({sys_id: v})}
                        >
                            <Option value='1' key='1'>熟料烧成</Option>
                            <Option value='2' key='2'>水泥粉磨</Option>
                            <Option value='3' key='3'>原料粉磨</Option>
                            <Option value='4' key='4'>煤粉制备</Option>
                        </Select>
                    </div>
                </div>
                <div className="info_line">
                    <div className="label">参数名称：</div>
                    <div className="content">
                        <Select
                            showSearch
                            value={tag_name}
                            placeholder="输入名称搜索参数"
                            style={{width: 350}}
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={this.handleSearch}
                            onChange={v=>{
                                console.log(v);
                                self.setState({tag_name: v});
                            }}
                            loading={loadingSearch}
                            notFoundContent={null}
                        >
                        {
                            tagList.map(d=>
                                <Option key={d.tag_name} value={d.tag_name}>{(d.name == d.tag_alias ? d.name : (d.name + '　' + d.tag_alias))}</Option>
                            )
                        }
                        </Select>
                    </div>
                </div>
                <div className="info_line">
                    <div className="label">重&nbsp;&nbsp;要&nbsp;&nbsp;性：</div>
                    <div className="content">
                        <Select
                            // className="search-select"
                            value={weight+''}
                            style={{ width: 200 }}
                            defaultValue={weight+''}
                            onChange={v=>this.setState({weight: v})}
                        >
                            <Option value='1' key='1'>一般 / 权重 1</Option>
                            <Option value='1.5' key='1.5'>重要 / 权重 1.5</Option>
                        </Select>
                    </div>
                </div>
                <div className="info_line" style={{marginTop: 20}}>
                    <div className="label"></div>
                    <div className="content sep_col">
                        <div className="col_1">区间</div>
                        <div className="col_2">得分</div>
                        <div className="col_3" style={{display: 'flex', flexDirection: 'column'}}>
                            参数下限
                            <span style={{color: 'grey', fontSize: '12px', lineHeight:'12px', marginTop: '-12px'}}>（留空表示无限制）</span>
                        </div>
                        <div className="col_4" style={{display: 'flex', flexDirection: 'column'}}>
                            参数上限
                            <span style={{color: 'grey', fontSize: '12px', lineHeight:'12px', marginTop: '-12px'}}>（留空表示无限制）</span>
                        </div>
                    </div>
                </div>

                <div className="info_line">
                    <div className="label">区&nbsp;&nbsp;&nbsp;间&nbsp;&nbsp;&nbsp;1：</div>
                    <div className="content sep_col">
                        <div className="col_1">
                            <Select
                                // className="search-select"
                                value={color_1+''}
                                style={{ width: 80 }}
                                defaultValue={color_1+''}
                                onChange={v=>this.setState({color_1: v})}
                            >
                                <Option value='#5BAE8D' key='#5BAE8D'>绿</Option>
                                <Option value='#FFDA77' key='#FFDA77'>黄</Option>
                                <Option value='#FB6060' key='#FB6060'>红</Option>
                            </Select>
                        </div>
                        <div className="col_2">
                            <Select
                                // className="search-select"
                                value={score_1+''}
                                style={{ width: 80 }}
                                defaultValue={score_1+''}
                                onChange={v=>this.setState({score_1: v})}
                            >
                                <Option value='100' key='100'>+100</Option>
                                <Option value='50' key='50'>+50</Option>
                                <Option value='-100' key='-100'>-100</Option>
                            </Select>
                        </div>
                        <div className="col_3">
                            <Input style={{width: '140px'}} value={limit_1} onChange={e=>this.setState({limit_1: e.target.value})} />
                        </div>
                        <div className="col_4">
                            <Input style={{width: '140px'}} value={limit_2} onChange={e=>this.setState({limit_2: e.target.value})} />
                        </div>
                    </div>
                </div>

                <div className="info_line">
                    <div className="label">区&nbsp;&nbsp;&nbsp;间&nbsp;&nbsp;&nbsp;2：</div>
                    <div className="content sep_col">
                        <div className="col_1">
                            <Select
                                // className="search-select"
                                value={color_2+''}
                                style={{ width: 80 }}
                                defaultValue={color_2+''}
                                onChange={v=>this.setState({color_2: v})}
                            >
                                <Option value='#5BAE8D' key='#5BAE8D'>绿</Option>
                                <Option value='#FFDA77' key='#FFDA77'>黄</Option>
                                <Option value='#FB6060' key='#FB6060'>红</Option>
                            </Select>
                        </div>
                        <div className="col_2">
                            <Select
                                // className="search-select"
                                value={score_2+''}
                                style={{ width: 80 }}
                                defaultValue={score_2+''}
                                onChange={v=>this.setState({score_2: v})}
                            >
                                <Option value='100' key='100'>+100</Option>
                                <Option value='50' key='50'>+50</Option>
                                <Option value='-100' key='-100'>-100</Option>
                            </Select>
                        </div>
                        <div className="col_3">
                            <Input style={{width: '140px'}} value={limit_2} onChange={e=>this.setState({limit_2: e.target.value})} />
                        </div>
                        <div className="col_4">
                            <Input style={{width: '140px'}} value={limit_3} onChange={e=>this.setState({limit_3: e.target.value})} />
                        </div>
                    </div>
                </div>

                <div className="info_line">
                    <div className="label">区&nbsp;&nbsp;&nbsp;间&nbsp;&nbsp;&nbsp;3：</div>
                    <div className="content sep_col">
                        <div className="col_1">
                            <Select
                                // className="search-select"
                                value={color_3+''}
                                style={{ width: 80 }}
                                defaultValue={color_3+''}
                                onChange={v=>this.setState({color_3: v})}
                            >
                                <Option value='#5BAE8D' key='#5BAE8D'>绿</Option>
                                <Option value='#FFDA77' key='#FFDA77'>黄</Option>
                                <Option value='#FB6060' key='#FB6060'>红</Option>
                            </Select>
                        </div>
                        <div className="col_2">
                            <Select
                                // className="search-select"
                                value={score_3+''}
                                style={{ width: 80 }}
                                defaultValue={score_3+''}
                                onChange={v=>this.setState({score_3: v})}
                            >
                                <Option value='100' key='100'>+100</Option>
                                <Option value='50' key='50'>+50</Option>
                                <Option value='-100' key='-100'>-100</Option>
                            </Select>
                        </div>
                        <div className="col_3">
                            <Input style={{width: '140px'}} value={limit_3} onChange={e=>this.setState({limit_3: e.target.value})} />
                        </div>
                        <div className="col_4">
                            <Input style={{width: '140px'}} value={limit_4} onChange={e=>this.setState({limit_4: e.target.value})} />
                        </div>
                    </div>
                </div>

                <div className="info_line" style={{marginTop: '30px'}}>
                    <div className="label">图&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;例：</div>
                    <div className="content">
                        <RuleBar
                            style={{width: '342px'}}
                            data={{
                                color_1: color_1,
                                color_2: color_2,
                                color_3: color_3,
                                limit_1: limit_1,
                                limit_2: limit_2,
                                limit_3: limit_3,
                                limit_4: limit_4,
                                score_1: score_1,
                                score_2: score_2,
                                score_3: score_3,
                            }}
                        />
                    </div>
                </div>
            </Modal>
        )
    }
}
