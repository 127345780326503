
const group_C_tags = {
    tag_1: '_HD',
    tag_2: '_HW',
    tag_3: '_HM',
    tag_4: '_HDQOQ',
    tag_5: '_HWQOQ',
    tag_6: '_HWQOQ',
};

const group_C = {
    name: '能耗指标',
    children: [
        {
            name: '耗电量',
            unit: '万kWh',
            basic_tag: 'PLANT_ALL_ELEC_CUM',
            ...group_C_tags,
            precision_1: 2,
            precision_2: 2,
            precision_3: 1,
        },
        {
            name: '自耗气',
            unit: '万Nm3',
            basic_tag: 'PLANT_FUEL_FG_CUM',
            ...group_C_tags,
            precision_1: 3,
            precision_2: 2,
            precision_3: 2,
        },
        {
            name: '耗水',
            unit: 'ton',
            basic_tag: 'PLANT_COOL_H2O_CUM',
            ...group_C_tags,
            precision_1: 3,
            precision_2: 2,
            precision_3: 2,
        },
        {
            name: '氮气消耗',
            unit: '万Nm3',
            basic_tag: 'PLANT_N2GEN_N2_CUM',
            ...group_C_tags,
            precision_1: 3,
            precision_2: 2,
            precision_3: 2,
        },
    ],
};

const group_D_tags = {
    tag_1: '_HD',
    tag_2: '_HW',
    tag_3: '_HM',
    tag_4: '_HDQOQ',
    tag_5: '_HWQOQ',
    tag_6: '_HWQOQ',
};

const group_D = {
    name: '能效指标',
    children: [
        {
            name: '原料处理电单耗',
            unit: 'kWh/万标',
            basic_tag: 'PLANT_ALL_ELEC_CONSUM',
            ...group_D_tags,
            precision_1: 0,
            precision_2: 0,
            precision_3: 0,
        },
        {
            name: '原料处理自耗气单耗',
            unit: 'Nm3/万标',
            basic_tag: 'PLANT_FUEL_FG_CONSUM',
            ...group_D_tags,
            precision_1: 0,
            precision_2: 0,
            precision_3: 0,
        },
        {
            name: '原料处理水单耗',
            unit: 't/万标',
            basic_tag: 'PLANT_COOL_H2O_CONSUM',
            ...group_D_tags,
            precision_1: 0,
            precision_2: 0,
            precision_3: 0,
        },
        {
            name: '原料处理氮气单耗',
            unit: 'Nm3/万标',
            basic_tag: 'PLANT_N2GEN_N2_CONSUM',
            ...group_D_tags,
            precision_1: 0,
            precision_2: 0,
            precision_3: 0,
        },
    ],
};

export {
    group_C,
    group_D,
};
