import React, { useRef, useEffect, useMemo } from 'react';
import style from './index.module.scss';
import Wrapper from 'components/Wrapper';
import * as echarts from 'echarts~5';
import { useData } from './hooks';
import { Spin } from 'antd';
import DomResize from 'components/DomResize';
import ICON from './res/icon.svg';

const renderChart = (refNode, data) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        if (!data) {
            instance.clear();
            return;
        };
        const xData = data
            .map(({ time_name }) => time_name);
        const data_1 = data
            .map(({ level_1_num: value }) => ({ value }));
        const data_2 = data
            .map(({ level_2_num: value }) => ({ value }));
        const data_3 = data
            .map(({ level_3_num: value }) => ({ value }));
        instance.setOption({
            grid: {
                left: 30,
                top: 10,
                right: 10,
                bottom: 40,
            },
            tooltip: {
                trigger: 'axis',
            },
            xAxis: {
                type: 'category',
                data: xData,
                axisLabel: {
                    color: '#AAAAAA',
                },
                axisTick: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: '#EFEFEF',
                    },
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: '#AAAAAA',
                },
            },
            series: [
                {
                    data: data_1,
                    type: 'bar',
                    barWidth: 6,
                    stack: '1',
                    itemStyle: {
                        color: '#1890FF',
                        borderRadius: 4,
                    },
                },
                {
                    data: data_2,
                    type: 'bar',
                    barWidth: 6,
                    stack: '1',
                    itemStyle: {
                        color: '#FFBE0D',
                        borderRadius: 4,
                    },
                },
                {
                    data: data_3,
                    type: 'bar',
                    barWidth: 6,
                    stack: '1',
                    itemStyle: {
                        color: '#FB6060',
                        borderRadius: 4,
                    },
                },
            ]
        });
    };
};

const Comp = (props) => {
    const { timeInfo, sysInfo } = props;

    const [data, setData, loading] = useData();
    const setDataFunc = useRef(null);
    useEffect(() => {
        let nextFunc = () => { };
        if (typeof setData === 'function') nextFunc = setData;
        setDataFunc.current = nextFunc;
    }, [setData]);
    useEffect(() => {
        setDataFunc.current(Object.assign({}, timeInfo ?? {}, sysInfo ?? {}));
    }, [timeInfo, sysInfo]);

    const refNode = useRef(null);
    useEffect(() => {
        const alarm_history = data?.alarm_history;
        if (!!refNode.current) {
            renderChart(refNode.current, alarm_history);
        };
    }, [data]);

    const [lv_1, lv_2, lv_3, total] = useMemo(() => {
        let level_1 = data?.alarm_num?.level_1;
        let level_2 = data?.alarm_num?.level_2;
        let level_3 = data?.alarm_num?.level_3;
        if (typeof level_1 === 'number' && typeof level_2 === 'number' && typeof level_3 === 'number') {
            const total = level_1 + level_2 + level_3;
            if (level_1 === 0 && level_2 === 0 && level_3 === 0) {
                level_1 = level_2 = level_3 = 1;
            };
            return [level_1, level_2, level_3, total];
        };
        return [null, null, null, null];
    }, [data]);

    const onResize = () => {
        if (!!refNode.current) {
            const instance = echarts.getInstanceByDom(refNode.current);
            if (!!instance) instance.resize();
        };
    };

    return (
        <div className={style['wrapper']}>
            <Wrapper
                icon={ICON}
                title="报警统计"
                watchDetail={false}
            >
                <div className={style['content']}>
                    <div className="header">
                        {
                            typeof total === 'number' && (
                                <div className="content">
                                    <div className="title">报警次数</div>
                                    <div className="bars_wrapper">
                                        <div className="bar bar_3" style={{ flexGrow: lv_3 }}>
                                            <div className="text">三级报警</div>
                                        </div>
                                        <div className="bar bar_2" style={{ flexGrow: lv_2 }}>
                                            <div className="text">二级报警</div>
                                        </div>
                                        <div className="bar bar_1" style={{ flexGrow: lv_1 }}>
                                            <div className="text" style={{ transform: "translate(-20%, 100%)" }}>一级报警</div>
                                        </div>
                                    </div>
                                    <div className="value">{total}次</div>
                                </div>
                            )
                        }
                    </div>
                    <div className="content">
                        <div className="left">
                            <div className="title">各班组比较</div>
                            <div className="chart_wrapper">
                                <div className="resize">
                                    <DomResize onResize={onResize} debounce_wait={20} />
                                </div>
                                <div className="chart" ref={refNode}></div>
                            </div>
                        </div>
                        <div className="right">
                            <div className="title">发生次数最多的报警</div>
                            <div className="rank_wrapper">
                                {
                                    (data?.top_n_alarms ?? [])
                                        .filter((item, index) => index < 5)
                                        .map((item, index) => {
                                            const { name, num } = item;
                                            return (
                                                <div className={"item" + (index % 2 === 1 ? "" : " odd")} key={index}>
                                                    <div className="text">{index + 1}.{name ?? ""}</div>
                                                    <div className="rate">{num ?? "--"}次</div>
                                                </div>
                                            );
                                        })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
