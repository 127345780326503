// import queryString from 'query-string';
// import _ from 'lodash';
import { notification } from 'antd';
import axios from 'axios';
import moment from 'moment';

/*import Mock from 'mockjs';*/
import Config,{ TIMEOUT,SERVER_PATH } from './config';
//import { hashHistory } from 'react-router';

// function get(url, params) {
//     if (params) {
//         url += '?' + queryString.stringify(params)
//     }
//     return fetch(url)
//         .then((res) => res.json())
//         /*.then((res) => Mock.mock(res))*/
// }
function get(url,params){
    return axiosFn(url,params,'get');
}

function post(url, data) {
    return axiosFn(url,data,'post');
}

function put(url, data) {
    return axiosFn(url,data,'put');
}

function patch(url, data) {
    return axiosFn(url,data,'patch');
}

function reqDelete(url, data) {
    return axiosFn(url,data,'delete');
}

function axiosFn(url,paramsData,method){
    let config ={
        url: url,
        method: method,
        timeout: TIMEOUT,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    if(isOldApi(url) && process.env.REACT_APP_OLD_API_TOKEN){
        config.headers['Authorization'] = process.env.REACT_APP_OLD_API_TOKEN
    }else{
        if(getToken() != null){
            config.headers['Authorization'] = getToken();
        }
    }

    if(method === "get"){
        config.params = paramsData;
    }else{
        config.data = paramsData;
    }

    return axios(config).then(res=>{
        return res;
    }).catch(e=>{
        let res = e.response;
        if(res && res.status === 401 && res.data && res.data.errcode == 401){
            window.sessionStorage.clear();
            //此处先处理SupOS的情况
            if(!window.reauthing){
                window.reauthing = true;
                notification.info({ message: '刷新凭证，请稍等...', duration: 1.5});
                setTimeout(()=>{
                    window.location.hash = '/AppAuth';
                },800);
            }
        }
        return {error:true}
        // if(e.response){

        // }else{
        //     //在设置触发错误的请求时发生了错误
        //     console.log('Error',e)
        // }
        // console.log(e.config);
        // return {error:true}
    });
}

//获取URL中参数值
function getQueryString(name) {
  /*var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return (r[2]);
  return null;*/

  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.hash.substr(window.location.hash.indexOf('?')+1).match(reg);
  if (r != null) return (r[2]);
  return null;
}

window.clickElem=function(elem){
  document.querySelector("#"+elem).click();
}

function roundNum(number,fractionDigits){
    return Math.round(number*Math.pow(10,fractionDigits))/Math.pow(10,fractionDigits);
}

function isOldApi(url){
    if(url.indexOf(process.env.REACT_APP_CEMENT_SERVER_PATH) >= 0){
        return true;
    }else{
        return false;
    }
}

//获取token
function getToken() {
    let userInfo = getCachedUserInfo();
    if (
        window.location.hash.indexOf('/Login') !== -1
        || window.location.hash.indexOf('/ExcelDemo') !== -1
    ) return null;
    if (!!userInfo) {
        const { expire_time } = userInfo;
        try {
            if (moment.unix(expire_time).isAfter(moment())) {
                return userInfo.token;
            };
        } catch (err) { };
    };
    if(['liaohe'].includes(process.env.REACT_APP_PROJECT_TAG)){
        if (window.token_expired_notification !== true){
            notification.info({ message: '刷新凭证，请稍等...', duration: 1.5});
            window.token_expired_notification = true;
        };
        setTimeout(() => {
            window.location.hash = '/AppAuth';
        }, 800);
    }else if(['wuhu'].includes(process.env.REACT_APP_PROJECT_TAG) || ['null'].includes(process.env.REACT_APP_AUTH_TYPE)){
        return null;
    }else{
        if (window.token_expired_notification !== true){
            notification['error']({ message: '凭证已过期，请重新登录...', duration: 1.5});
            window.token_expired_notification = true;
        };
        const currHash = (window.location.hash ?? "").replace("#", "");
        setTimeout(() => {
            window.location.hash = `#/Login${currHash !== "" ? `?redirect=${window.encodeURIComponent(currHash)}` : ""}`;
        }, 800);
    }

    return null;
}

function getCachedUserInfo() {
    let userInfo = null;
    if(window.localStorage.getItem('PERSIST_SESSION') == 'true'){
        userInfo = window.localStorage.getItem('USER_INFO');
    }else{
        userInfo = window.sessionStorage.getItem('USER_INFO');
    }
    if(userInfo){
        userInfo = JSON.parse(userInfo);
    }
    if(!userInfo || new Date().getTime()/1000 > userInfo.expire_time){//token超过6小时即失效
        return null;
    }
    return userInfo;
}

const getCurrentTime = () => {
    const sys_default_time = process.env.REACT_APP_PROJECT_SYSTEM_DEFAULT_TIME;
    let currTime = moment();
    if (typeof sys_default_time === 'string' && sys_default_time !== '') {
        currTime = moment(sys_default_time);
    };
    return currTime;
}

function postDownloadFile(url, params){
    let form = document.createElement("form");
    form.setAttribute("style", "display:none;");
    form.setAttribute("method", "post");
    form.setAttribute("action", url);

    Object.keys(params).forEach(key=>{
        if(Array.isArray(params[key])){
            // value_list = []
            params[key].forEach(value=>{
                let input_item = document.createElement("input");
                input_item.name = key;
                input_item.value = value;
                form.appendChild(input_item);
            })
        }else{
            let input_item = document.createElement("input");
            input_item.name = key;
            input_item.value = params[key];
            form.appendChild(input_item);
        }
    })

    document.body.appendChild(form);
    let windowName = 'Download('+(new Date().getTime())+')';
    let w = window.open('', windowName);
    form.target = windowName;
    form.submit();
    form.remove();
}

const significantNum = (num, precision = 4, toFix = 3) => {
    // num, precision required
    const max = Math.pow(10, precision);
    if (num >= max) {
        return roundNum(num, 0);
    } else {
        // toPrecision会四舍五入
        let tmp = num.toPrecision(precision);
        // 这里还需考虑到小数位在0.000000之后会变得很长，需要定义最长小数位
        tmp = parseFloat(tmp);
        const multi = Math.pow(10, toFix);
        const tmp_2 = Math.floor(tmp * multi) / multi;
        if (tmp !== tmp_2) {
            // 发生了小数位截断,直接取四舍五入
            tmp = roundNum(num, toFix);
        };
        return tmp;
    };
};

export {
  get,
  post,
  put,
  reqDelete,
  patch,
  roundNum,
  significantNum,
  getQueryString,
  getToken,
  getCachedUserInfo,
  getCurrentTime,
  postDownloadFile
}
